import * as React from 'react'
import {
    Filter,
    List,
    TextField,
    FunctionField,
    Button,
    useGetOne,
    TextInput,
    usePermissions
} from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'

import MuiTextField from '@mui/material/TextField'
import authProvider from '../../config/authProvider'
import { useSnackbar } from 'notistack'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props) => {
    const { isLoading, permissions } = usePermissions();
    return (
        <List
            {...props}
            empty={<EmptyList title="partnerów" add={false} />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        'Partners',
                        'Create'
                    )}
                />
            }
            filters={[
                <TextInput
                    variant="outlined"
                    resettable
                    alwaysOn
                    label="Nazwa"
                    source="name"
                />,
            ]}
            bulkActionButtons={false}
            title={`Partnerzy`}
            pagination={<CustomPagination />}
        >
            <CustomDataGrid>
                <FunctionField
                    label="ID"
                    source="id"
                    render={(record) => `${record.id.split('/').pop()}`}
                />
                <TextField
                    source="name"
                    label="Nazwa"
                    //sortable={false}
                />
                {PermissionHandler(permissions, 'Partners', 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDescriptionAvailable={PermissionHandler(
                            permissions,
                            'Partners',
                            'Read'
                        )}
                        isDeleteAvailable={false}
                    />
                )}
                <FunctionField
                    label="Rabat na produkty TZMO (%)"
                    render={(record) => (
                        <DiscountField
                            partnerConstDiscountId={
                                record?.partnerConstDiscount
                            }
                        />
                    )}
                />
            </CustomDataGrid>
        </List>
    )
}

const DiscountField = ({ partnerConstDiscountId }) => {
    const { data, loading, error } = useGetOne('partner_dicsounts', {
        id: partnerConstDiscountId,
    })

    const [value, setValue] = React.useState(null)
    const [errorI, setErrorI] = React.useState(false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    if (loading && !error) {
        return <>Ladowanie...</>
    }

    const onSave = () => {
        if (value > 100 || value < 0) {
            setErrorI(true)
            return
        } else {
            setErrorI(false)
        }
        let id = partnerConstDiscountId?.replace('/', '')

        authProvider
            .updatePartnerConstDiscount({
                partnerConstDiscountId: id,
                value: parseFloat(value),
            })
            .then((response) => {
                enqueueSnackbar(response.message, {
                    variant: 'info',
                    autoHideDuration: 10000,
                })
            })
            .catch((response) => {
                enqueueSnackbar(response.message, {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            })
    }

    return data ? (
        <>
            <MuiTextField
                type="number"
                defaultValue={data?.value}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                inputProps={{ min: 0, max: 100 }}
                error={errorI}
                helperText={errorI && 'Podano nieprawidłową wartość'}
                variant="outlined"
                resettable
            />
            <Button
                label="Zapisz"
                onClick={onSave}
                disabled={data?.value == value || value == null}
            />
        </>
    ) : null
}
