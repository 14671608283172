import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import React, { useEffect } from 'react'
import { Title } from 'react-admin'
import useState from 'react-usestateref'

import Product from './Product'

const Calculator = (props) => {
    const [productList, setProductList, productListRef] = useState([])
    const [ProductsInfo, setProductsInfo, ProductsInfoRef] = useState([])

    const UprawnieniaSelect = [
        { key: 'IW', value: 1 },
        { key: 'IB', value: 2 },
        { key: 'OR', value: 3 },
        { key: '47.ZN', value: 4 },
        { key: '47.DN', value: 5 },
        { key: '47.ZDN', value: 6 },
    ]
    const IloscMiesRefNaZlecSelect = [
        { key: '1', value: 1 },
        { key: '2', value: 2 },
        { key: '3', value: 3 },
        { key: '4', value: 4 },
        { key: '5', value: 5 },
        { key: '6', value: 6 },
    ]

    const ageSelect = [
        { key: 'Osoba pełnoletnia', value: 1 },
        { key: 'Osoba niepełnoletnia', value: 2 },
    ]

    const [productKey, setProductKey] = useState(0)
    const [sumaSzt, setSumaSzt, sumaSztRef] = useState(0)
    const [sumaWartosc, setSumaWartosc] = useState(0)
    const [LimitRef, setLimitRef, LimitRefRef] = useState(0)
    const [Kod, setKod] = useState(0)
    const [kodTmp, setKodTmp] = useState(0)
    const [IloscMiesWyk, setIloscMiesWyk, IloscMiesWykRef] = useState(0)
    const [wykLimZaCzas, setWykLimZaCzas] = useState(0)
    const [IloscSztukNaMies, setIloscSztukNaMies, IloscSztukNaMiesRef] =
        useState(0)
    const [pozostalo, setPozostalo] = useState(0)
    const [KwotaRef, setKwotaRef] = useState(0)
    const [IloscMiesRefNaZlec, setIloscMiesRefNaZlec, IloscMiesRefNaZlecRef] =
        useState(0)
    const [Uprawenia, setUprawienia, UprawieniaRef] = useState(0)

    const [sztLimit, setSztLimit, sztLimitRef] = useState(90)

    const [quantityLimit, setQuantityLimit, quantityLimitRef] = useState(0)

    const [age, setAge, ageRef] = useState(0)

    const getProducts = async () => {
        const fetchHeaders = () => ({
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        })

        const data = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}/product_refunds?page=1&itemsPerPage=400`,
            {
                method: 'GET',
                headers: new Headers(fetchHeaders()),
            }
        ).then((data) => {
            //console.log(data);
            try {
                data = data.json()
                return data
            } catch (error) {
                return []
            }
        })
        setProductsInfo(
            data ? data['hydra:member'] && data['hydra:member'] : []
        )
    }

    const AddProduct = () => {
        const tmpArr = Object.assign([], productListRef.current)
        tmpArr.push({ keyProduct: productKey, count: 0, value: 0, valueOne: 0 })
        setProductList(tmpArr)

        setProductKey(productKey + 1)
    }

    const RemoveProduct = (key) => {
        if (
            productListRef.current?.length > 1 &&
            productListRef.current?.filter(Boolean)?.length > 1
        ) {
            let tmp = productListRef.current
            delete tmp[key]
            setProductList(tmp)
            return true
        }
        return false
    }

    const updateSum = (Key, Count, Value, RefundLimit, ValueOne) => {
        let tmp = productListRef.current.map((el) =>
            el.keyProduct == Key
                ? {
                      ...el,
                      count: Count,
                      value: Value,
                      refundLimit: RefundLimit,
                      valueOne: ValueOne,
                  }
                : el
        )

        setSumaSzt(
            tmp.length > 0
                ? tmp
                      .map((e) => {
                          return isNaN(e.count) ||
                              e.count == null ||
                              e.count == ''
                              ? 0
                              : parseInt(e.count)
                      })
                      .reduce((a, b) => {
                          return parseInt(a + b)
                      })
                : null
        )

        setSumaWartosc(
            tmp.length > 0
                ? tmp
                      .map((e) => {
                          return isNaN(e.value) || e.value == null
                              ? 0
                              : parseFloat(e.value)
                      })
                      .reduce((a, b) => {
                          return parseFloat(a + b)
                      })
                : null
        )

        setProductList(tmp)

        updateValues()
    }

    const updateValues = () => {
        let sumaRefundacji = 0
        let sumaRazem = 0
        let iloscProduktow = 0
        //let sumaDoplata = 0;

        let iloscDostepnaNaMiesiac =
            IloscSztukNaMiesRef.current * IloscMiesWykRef.current

        let limit = 0.8

        if (ageRef.current == 2) {
            limit = 0.9
        }

        if ([1, 2, 3].includes(parseInt(UprawieniaRef.current))) limit = 1

        productListRef.current.map((prod, index) => {
            iloscProduktow += parseInt(prod?.count)
            //console.log(prod);
            if (
                iloscProduktow <= iloscDostepnaNaMiesiac &&
                iloscDostepnaNaMiesiac != 0
            ) {
                if (prod?.valueOne >= prod?.refundLimit) {
                    sumaRefundacji += prod?.count * (prod?.refundLimit * limit)
                } else {
                    sumaRefundacji += prod?.count * (prod?.valueOne * limit) //prod?.count * (prod?.value * limit);
                }
            } else {
                if (prod?.valueOne >= prod?.refundLimit)
                    sumaRefundacji =
                        iloscDostepnaNaMiesiac * (prod?.refundLimit * limit)
                else
                    sumaRefundacji +=
                        iloscDostepnaNaMiesiac * (prod?.valueOne * limit)
            }
            sumaRazem += prod?.count * prod?.value
        })
        //sumaDoplata = sumaRazem - sumaRefundacji;
        setKwotaRef(sumaRefundacji)

        let limitQuantity = getProductQuantityLimit()
        setQuantityLimit(limitQuantity)
    }

    useEffect(() => {
        getProducts()
        AddProduct()
    }, [])

    const getProductQuantityLimit = () => {
        return (
            IloscSztukNaMiesRef.current * IloscMiesWykRef.current -
            sumaSztRef.current
        )
    }

    const isQuantityLimit = () => {
        return sumaSztRef.current > 0 && getProductQuantityLimit() <= 0
            ? true
            : false
    }

    return (
        <Card>
            <Title title="Kalkulator refundacji" />
            <CardContent>
                <div className="container-fluid">
                    <div>
                        <div className="form-group row align-items-center mb-4">
                            <div className="col-sm-3">Wiek</div>
                            <div className="col-sm-5">
                                <Select
                                    defaultValue={0}
                                    onChange={(e) => {
                                        setAge(e.target.value)
                                        updateValues()
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Wybierz opcję</MenuItem>
                                    {ageSelect?.map((x) => (
                                        <MenuItem value={x.value}>
                                            {x.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group row align-items-center mb-4">
                            <div className="col-sm-3">Uprawnienia</div>
                            <div className="col-sm-5">
                                <Select
                                    defaultValue={0}
                                    onChange={(e) => {
                                        setUprawienia(e.target.value)
                                        if (
                                            !UprawieniaRef.current ||
                                            [0, 1, 2, 3].includes(
                                                parseInt(UprawieniaRef.current)
                                            )
                                        )
                                            setSztLimit(90)
                                        else if (
                                            [4, 5, 6].includes(
                                                parseInt(UprawieniaRef.current)
                                            )
                                        )
                                            setSztLimit(999999999)
                                        updateValues()
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={0}>
                                        Wybierz, jeśli dotyczy
                                    </MenuItem>
                                    {UprawnieniaSelect?.map((x) => (
                                        <MenuItem value={x.value}>
                                            {x.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group row align-items-center mb-4">
                            <div className="col-sm-3">
                                Ilość sztuk na miesiąc
                            </div>
                            <div className="col-sm-5">
                                <TextField
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                    }}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => {
                                        if (
                                            e.target.value <=
                                                sztLimitRef.current &&
                                            e.target.value >= 0
                                        )
                                            setIloscSztukNaMies(e.target.value)
                                        else if (
                                            e.target.value > sztLimitRef.current
                                        )
                                            setIloscSztukNaMies(
                                                sztLimitRef.current
                                            )
                                        else if (e.target.value < 0)
                                            setIloscSztukNaMies(0)

                                        updateValues()
                                    }}
                                    value={IloscSztukNaMies}
                                    min={0}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="form-group row align-items-center mb-4">
                            <div className="col-sm-3">
                                Ile miesięcy chcesz wykorzystać?
                            </div>
                            <div className="col-sm-5">
                                <Select
                                    defaultValue={0}
                                    className="browser-default custom-select"
                                    onChange={(e) => {
                                        setIloscMiesWyk(e.target.value)
                                        updateValues()
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Wybierz opcję</MenuItem>
                                    {IloscMiesRefNaZlecSelect?.map((x) => (
                                        <MenuItem value={x.value}>
                                            {x.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center my-5">
                        <div className="col-md-3 offset-md-3">
                            Sztuk w miesiącach wykorzystanych:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {IloscSztukNaMies * IloscMiesWyk}
                            </span>
                        </div>
                    </div>

                    <div className="h-25 d-inline-block"></div>

                    {productListRef.current?.map((x, index) => {
                        return (
                            <Product
                                keyProduct={index}
                                productId={x?.keyProduct}
                                ProductsInfo={ProductsInfo}
                                RemoveFunc={RemoveProduct}
                                UpdateSum={updateSum}
                                quantityLimit={quantityLimitRef.current}
                                age={ageRef.current}
                            />
                        )
                    })}
                    <div className="row">
                        <div className="col-sm mt-2">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={AddProduct}
                            >
                                Dodaj produkt
                            </button>
                        </div>
                        {isQuantityLimit() && (
                            <div className="col-sm mt-4">
                                <div class="alert alert-warning" role="alert">
                                    Osiągnięto limit ilościowy
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="h-25 d-inline-block"></div>
                    <hr></hr>
                    <div className="row">
                        <div className="offset-lg-6 col-lg-6">
                            <div className="row">
                                <div className="big-text col-12">Suma</div>
                                <div className="col-6">
                                    <span className="ml-1 d-inline">szt.</span>
                                    <input
                                        type="text"
                                        className="form-control-plaintext d-inline text-center auto-w"
                                        readonly
                                        value={sumaSzt}
                                    />
                                </div>
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control-plaintext"
                                        readonly
                                        value={
                                            parseFloat(sumaWartosc).toFixed(2) +
                                            ' zł'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div
                        className="sumup jumbotron col-12  mt-4 offset-lg-6 col-lg-6"
                        style={{
                            padding: '4em 2em',
                            marginBottom: '2em',
                            borderRadius: '4.8px',
                        }}
                    >
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                Kwota dopłaty pacjenta
                            </div>
                            <div className="col-12 col-lg-6">
                                <input
                                    type="text"
                                    className="form-control-plaintext text-lg-right"
                                    readonly
                                    value={
                                        isFinite(KwotaRef)
                                            ? parseFloat(
                                                  sumaWartosc - KwotaRef < 0
                                                      ? 0
                                                      : sumaWartosc - KwotaRef
                                              ).toFixed(2) + ' zł'
                                            : (0).toFixed(2) + ' zł'
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                Kwota refundacji
                            </div>
                            <div className="col-12 col-lg-6">
                                <input
                                    type="text"
                                    className="form-control-plaintext text-lg-right"
                                    readonly
                                    value={
                                        isFinite(KwotaRef)
                                            ? parseFloat(KwotaRef).toFixed(2) +
                                              ' zł'
                                            : (0).toFixed(2) + ' zł'
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">Razem</div>
                            <div className="col-12 col-lg-6">
                                <input
                                    type="text"
                                    className="form-control-plaintext text-lg-right"
                                    readonly
                                    value={
                                        parseFloat(sumaWartosc).toFixed(2) +
                                        ' zł'
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default Calculator
