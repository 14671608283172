import * as React from "react";
import { List, TextField, usePermissions, FunctionField,TextInput, ReferenceInput, AutocompleteInput } from "react-admin";
import { CustomActionsButtons } from "../CustomFields/CustomActionButtonsField";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import  PermissionHandler from "../../config/PermissionHandler";
import { CustomPagination } from "../CustomElements/CustomPagination";

import { Link, Tooltip } from "@mui/material";

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      empty={
        <EmptyList
          title="użytkowników partnerów"
          add={PermissionHandler(permissions, "PartnerUsers", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(
            permissions,
            "PartnerUsers",
            "Create"
          )}
          isFiltersVisible={false}
        />
        //null
      }
      bulkActionButtons={false}
      title={`Partnerzy użytkownicy`}
      filters={[
        <TextInput
            variant="outlined"
            resettable
            alwaysOn
            label="Nazwisko"
            source="user.surname"
        />,
        <TextInput
            variant="outlined"
            resettable
            alwaysOn
            label="Email"
            source="user.email"
        />,
        
        <ReferenceInput
            source="partner"
            reference='partners'
            link={false}
            className="autocompleteinput"
            alwaysOn
            allowEmpty
        >
            <AutocompleteInput
                label="Partner"
                optionText= "name"
                variant="outlined"
                resettable
                allowEmpty
                filterToQuery={(searchText)=>({
                    name: searchText
                })}
            />
        </ReferenceInput>,
    ]}
    >
      <CustomDataGrid>
        <FunctionField source="id" label='id'render={(record)=>record.id.split('/')[2]} />
        <TextField source="name" label="Imię" />
        <TextField source="surname" label="Nazwisko" />
        <TextField source="email" label="E-mail" />
        <TextField source="phone" label="Numer Telefonu" />
        <TextField source="partnerName" label="Partner" />
        {PermissionHandler(permissions, "Other", "CanLogin") && (
          <FunctionField
            label="Link logowania"
            render={(record) => {
              const [title , setTitle] = React.useState('Kliknij żeby skopiować')
              return <Tooltip title={title}>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    navigator.clipboard.writeText(
                      record?.loginUrl
                    )
                    setTitle('Skopiowano')
                    setTimeout(()=>{
                      setTitle('Kliknij żeby skopiować')
                    },2000)
                  }}
                  fontSize={10}
              >
                  {record?.loginUrl}
                </Link>
              </Tooltip>
            }}
          />
        )}

        {PermissionHandler(permissions, "PartnerUsers", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(
              permissions,
              "PartnerUsers",
              "Update"
            )}
            isDeleteAvailable={PermissionHandler(
              permissions,
              "PartnerUsers",
              "Delete"
            )}
          />
        )}
      </CustomDataGrid>
    </List>
  );
};
