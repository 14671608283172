import * as React from 'react'
import { List, TextField, usePermissions, BooleanField } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'

const ModuleName = 'Regulations'

export default () => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        ModuleName,
                        'Create'
                    )}
                />
            }
            bulkActionButtons={false}
            empty={
                <EmptyList
                    title="regulaminów"
                    add={PermissionHandler(permissions, ModuleName, 'Create')}
                />
            }
            pagination={<CustomPagination />}
            title={`Zgody`}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa" />
                <TextField source="url" label="Link do regulaminu" />
                <TextField source="partnerName" label="Partner" />
                <BooleanField source="status" label="Status" valueLabelTrue="aktywny" valueLabelFalse='nieaktywny' />

                {PermissionHandler(permissions, ModuleName, 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isEditAvailable={PermissionHandler(
                            permissions,
                            ModuleName,
                            'Update'
                        )}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
