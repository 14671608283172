import * as React from 'react'
import { List, TextField, BooleanField, usePermissions } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import CustomBooleanField from '../CustomFields/CustomBooleanField'
import { CustomPagination } from '../CustomElements/CustomPagination'

const ModuleName = 'PriceLists'

export default (props) => {
    const {permissions, isLoading} = usePermissions();

    return (
        <List
            {...props}
            empty={<EmptyList title="cenników" add={false} />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        ModuleName,
                        'Create'
                    )}
                />
            }
            bulkActionButtons={false}
            title={`Zgody`}
            pagination={<CustomPagination />}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa" />
                <TextField source="partnerName" label="Partner" />
                <BooleanField source="main" label="Główny cennik" />

                {PermissionHandler(permissions, ModuleName, 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDescriptionAvailable={PermissionHandler(
                            permissions,
                            ModuleName,
                            'Read'
                        )}
                        isDeleteAvailable={false}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
