import {
    Create,
    SimpleForm,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    required,
} from 'react-admin'
import Box from '@mui/material/Box'
import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
const requiredField = required('Pole wymagane')
const imgSize = { width: 100, height: 100 }

export default (props) => {
    return (
        <Create {...props} actions={null} title={'Dodaj baner'}>
            <SimpleForm
                toolbar={<PostBottomToolbarAdd saveRedirectPath="/banners" />}
            >
                <ReferenceArrayInput
                    source="relation"
                    reference="media_objects"
                    fullWidth
                    {...props}
                >
                    <AutocompleteArrayInput
                        variant="outlined"
                        fullWidth
                        label="Zdjecie"
                        sx={{
                            '& .MuiChip-root': {
                                height: '100px',
                            },
                        }}
                        optionText={(media) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: imgSize.width,
                                    height: imgSize.height,
                                }}
                            >
                                <img
                                    src={media.contentUrl}
                                    key={media.id}
                                    alt="media"
                                    style={{
                                        width: imgSize.width,
                                        height: imgSize.height,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        )}
                    />
                </ReferenceArrayInput>

                <SelectInput
                    label="Typ"
                    source="type"
                    variant="outlined"
                    resettable
                    fullWidth
                    choices={[
                        { id: 1, name: ' Banner na stronie głównej' },
                        // { id: 2, name: 'Next' },
                    ]}
                    alwaysOn
                />

                <ReferenceInput
                    source="partner"
                    reference="partners"
                    className="autocompleteinput"
                    label=""
                    fullWidth
                    optionValue="id"
                >
                    <AutocompleteInput
                        validate={requiredField}
                        label="Partner"
                        variant="outlined"
                        fullWidth
                        allowEmpty={true}
                        optionText={(record) =>
                            `(ID:${record.id.split('/').pop()}) ${record.name} `
                        }
                        filterToQuery={(searchText) => ({
                            name: searchText,
                            id: searchText,
                        })}
                    />
                </ReferenceInput>

                <tr>
                    <BooleanInput label="aktywny" source="active" fullWidth />
                    <td colSpan={2}>Czy dany banner będzie aktywny</td>
                </tr>
            </SimpleForm>
        </Create>
    )
}
