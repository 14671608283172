import * as React from 'react'
import {
    Filter,
    List,
    TextField,
    FunctionField,
    TextInput,
    usePermissions,
} from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'

import { CustomPagination } from "../CustomElements/CustomPagination";

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        'PayerNetworks',
                        'Create'
                    )}
                />
            }
            bulkActionButtons={false}
            title={`Płatnicy sieciowi`}
            empty={
                <EmptyList
                    title="płatników sieciowych"
                    add={PermissionHandler(
                        permissions,
                        'PayerNetworks',
                        'Create'
                    )}
                />
            }
            filters={[
                <TextInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="Nazwa"
                    source="name"
                />,

                <TextInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="Partner"
                    source="partner.name"
                />,
            ]}
        >
            <CustomDataGrid>
                <FunctionField
                    label="ID"
                    source="id"
                    render={(record) => `${record.id.split('/').pop()}`}
                />
                ;
                <TextField
                    source="name"
                    label="Nazwa"
                    //sortable={false}
                />
                <TextField
                    source="partnerName"
                    label="Partner"
                    //sortable={false}
                />
                {PermissionHandler(permissions, 'PayerNetworks', 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDescriptionAvailable={PermissionHandler(
                            permissions,
                            'PayerNetworks',
                            'Read'
                        )}
                        isDeleteAvailable={false}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
