import * as React from 'react'

export default class extends React.Component {
    handleClose = (e) => {
        this.props.onClose()
        e.stopPropagation()
    }

    render() {
        return (
            <div
                className="modal"
                style={{ display: this.props.isOpen ? 'block' : 'none' }}
            >
                <div
                    className="bg-secondary"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0.7,
                    }}
                    onClick={this.handleClose}
                />
                <div className="modal-dialog" style={{ top: '25%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {this.props.title && (
                                <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                >
                                    {this.props.title}
                                </h5>
                            )}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {this.props.content && (
                            <div className="modal-body">
                                {this.props.content}
                            </div>
                        )}
                        <div className="modal-footer">
                            {this.props.cancel && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.handleClose}
                                >
                                    {this.props.cancel}
                                </button>
                            )}
                            {this.props.confirm && (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={this.props.onConfirm}
                                >
                                    {this.props.confirm}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
