import React from 'react'
import {
    useGetList,
    ListContextProvider,
    useRecordContext,
    TextField,
    DateField,
    FunctionField,
    Title,
    NumberField,
    useRefresh,
    useNotify,
    useStore,
    useListController,
    useDelete,
    useRedirect,
} from 'react-admin'
import { DataGrid, GridColDef, plPL } from '@mui/x-data-grid'
import Card from '@mui/material/Card'
import CustomDataGrid from '../../CustomElements/CustomDataGrid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import { CustomPagination } from '../../CustomElements/CustomPagination'
import { useConfirm } from 'material-ui-confirm'

const ProductsList = () => {
    const record = useRecordContext()

    const columns: GridColDef[] = [
        { field: 'bloz', headerName: 'BLOZ7', flex: 1 },
        { field: 'ean', headerName: 'EAN', flex: 1 },
        {
            field: 'name',
            headerName: 'Nazwa',
            valueGetter: (params) => {
                return `${params.value || ''} ${
                    params?.row?.options
                        ?.map((opt) => `${opt.name} - ${opt.value}`)
                        ?.join(', ') || ''
                }`
            },
            flex: 3,
        },
        { field: 'quantity', headerName: 'Ilość', flex: 1 },
    ]

    return (
        <Box
            style={{
                width: '100%',
                minHeight: '100px',
                display: 'grid',
            }}
        >
            <DataGrid
                rows={record.products}
                columns={columns}
                getRowId={(row) => row['id']}
                disableColumnMenu
                localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                hideFooter
                rowHeight={25}
                columnHeaderHeight={30}
            />
        </Box>
    )
}

const DeleteBtn = () => {
    const record = useRecordContext()
    const notify = useNotify()
    const refresh = useRefresh()

    const [remove, { isLoading }] = useDelete(
        'order_templates',
        {
            id: record && record.id,
        },
        {
            onSuccess: (data) => {
                notify('Szablon został usunięty', { type: 'info' })
                refresh()
            },
            onError: (data) => {
                notify('Wystąpił błąd', { type: 'error' })
                refresh()
            },
        }
    )
    const handleDelete = (e) => {
        remove()
    }

    return (
        <IconButton disabled={isLoading} color="error" onClick={handleDelete}>
            <DeleteIcon />
        </IconButton>
    )
}

const OpenTemplateBtn = () => {
    const confirm = useConfirm()
    const record = useRecordContext()
    const redirect = useRedirect()
    const [orderItems, setOrderItems] = useStore('fastOrderCart', [])

    const handleTemplateOpen = () => {
        confirm({
            title: 'Uwaga!',
            description:
                'Czy chcesz aby produkty z szablonu zostały dodane do Twojego obecnego koszyka?',
            confirmationText: 'Przejdź dalej',
            confirmationButtonProps: {
                variant: 'outlined',
                color: 'success',
            },
            cancellationText: 'Anuluj',
            cancellationButtonProps: {
                variant: 'contained',
                color: 'primary',
            },
        })
            .then(() => {
                const newProductLines = orderItems
                let index
                record.products?.map((product) => {
                    if (
                        (index = newProductLines.findIndex(
                            (_product) =>
                                _product.ean == product.ean &&
                                _product.reference == product.reference
                        )) != -1
                    ) {
                        newProductLines[index].quantity += product.quantity
                    } else {
                        const newProduct = {
                            bloz: product.bloz,
                            box: product.box,
                            ean: product.ean,
                            id: product.id,
                            minimalQuantity: product.minimalQuantity,
                            name: product.name,
                            options: product.options,
                            quantity: product.quantity,
                            reference: product.reference,
                        }
                        newProductLines.push(newProduct)
                    }
                })
                setOrderItems(newProductLines)

                setTimeout(() => {
                    redirect('/fast-order/new')
                }, 100)
            })
            .catch(() => {
                return;
            })
    }

    return (
        <Button onClick={handleTemplateOpen} variant="outlined" color="primary">
            Otwórz
        </Button>
    )
}

export default () => {
    const { data, isLoading } = useGetList('order_templates', {
        pagination: { page: 1, perPage: 10 },
    })
    const listContext = useListController({
        data,
        isLoading,
        resource: 'order_templates',
    })

    return (
        <Card>
            <Title title="Lista szablonów" />

            <ListContextProvider value={listContext}>
                <CustomDataGrid
                    empty={
                        <Typography p={2} variant="subtitle1">
                            Jeszcze nie dodano szablonów. Stwórz swój szablon
                            zamówienia
                        </Typography>
                    }
                    expand={<ProductsList />}
                    expandSingle
                >
                    <FunctionField
                        source="id"
                        render={(record) => record?.id?.split('/')?.[2]}
                        label="Id"
                    />
                    <DateField source="createdAt" label="Data utworzenia" />
                    <TextField source="name" label="Nazwa" />
                    <NumberField source="numberOfLines" label="Ilość linii" />
                    <Box className="d-flex w-100 justify-content-evenly">
                        <DeleteBtn />
                        <OpenTemplateBtn />
                    </Box>
                </CustomDataGrid>
                <CustomPagination />
            </ListContextProvider>
        </Card>
    )
}
