import { useRecordContext } from 'react-admin'
const CustomTypeField = ({ source, customTypes }) => {
    const record = useRecordContext()
    if (customTypes == undefined) {
        switch (record[source]) {
            case 10:
                return 'pracownik'
            case 20:
                return 'administrator'
            case 21:
                return 'opiekun'
            case 25:
                return 'admin DPS'
            default:
                return 'brak'
        }
    } else {
        let result = customTypes.find((x) => x.id === record[source])
        return result != undefined ? result.name : 'brak'
    }
}

export default CustomTypeField
