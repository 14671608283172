import * as React from 'react'
import {
    BooleanInput,
    Create,
    email,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    usePermissions,
    PasswordInput
} from 'react-admin'
import authProvider from '../../config/authProvider'


import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

const transform = (data) => ({
    //for CustomSelectInput - transform type from string to int
    ...data,
    type: parseInt(data.type),
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create
            {...props}
            actions={null}
            title={`Dodaj użytkownika`}
            transform={transform}
        >
            <SimpleForm toolbar={<PostBottomToolbarAdd />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="email"
                            validate={isEmail}
                            fullWidth
                        />

                        <PasswordInput
                            variant="outlined"
                            source="password"
                            validate={requiredField}
                            fullWidth
                        />

                        <SelectInput
                            variant="outlined"
                            source="type"
                            validate={requiredField}
                            choices={
                                authProvider.hasRole(['ROLE_SUPER_ADMIN'])
                                    ? [
                                          {
                                              id: 999,
                                              name: 'Super admin',
                                          },
                                          {
                                              id: 500,
                                              name: 'Admin',
                                          },
                                          {
                                              id: 405,
                                              name: 'Szkoleniowiec',
                                          },
                                          //{ id: 400, name: "Windykacja" },
                                          // { id: 110, name: "Płatnik Wirtualny" },
                                          // { id: 100, name: "Płatnik" },
                                          // { id: 20, name: "Menadżer" },
                                          // { id: 10, name: "Pierwszy stół" },
                                      ]
                                    : authProvider.hasRole(['ROLE_ADMIN'])
                                    ? [
                                          {
                                              id: 500,
                                              name: 'Admin',
                                          },
                                          {
                                              id: 405,
                                              name: 'Szkoleniowiec',
                                          },
                                          //{ id: 400, name: "Windykacja" },
                                          // { id: 110, name: "Płatnik Wirtualny" },
                                          // { id: 100, name: "Płatnik" },
                                          // { id: 20, name: "Menadżer" },
                                          // { id: 10, name: "Pierwszy stół" },
                                      ]
                                    : // : authProvider.hasRole(["ROLE_DEBT_COLLECTION"])
                                      // ? [
                                      //     { id: 110, name: "Płatnik Wirtualny" },
                                      //     { id: 100, name: "Płatnik" },
                                      //     { id: 20, name: "Menadżer" },
                                      //     { id: 10, name: "Pierwszy stół" },
                                      //   ]
                                      []
                            }
                            fullWidth
                        />

                        <BooleanInput
                            variant="outlined"
                            source="status"
                            label=" "
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
