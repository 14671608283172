import * as React from 'react'
import { Button } from '@mui/material'
import { useListContext } from 'react-admin'

const ResetFiltersButton = (props) => {
    const { filterValues, setFilters } = useListContext()

    return (
        Object.keys(filterValues).length > 0 && (
            <Button
                sx={{ height: '27.5px' }}
                size="small"
                type="submit"
                onClick={() => setFilters({})}
                variant="outlined"
                color="warning"
            >
                Wyczyść filtry
            </Button>
        )
    )
}

export default ResetFiltersButton
