import * as React from 'react'
import {
    Button,
    DeleteButton,
    EditButton,
    Link,
    ShowButton,
    useRecordContext,
    useRefresh,
} from 'react-admin'
import Tooltip from '@mui/material/Tooltip'
import authProvider from '../../config/authProvider'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
} from '@mui/material'

const CustomEditButton = () => {
    return <EditButton style={{minWidth:'80px'}} variant="outlined" color="info" label={'Edytuj'} />
}

export const CustomActionButtonEdit = () => {
    return <CustomEditButton />
}

const CustomDeleteButton = () => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                variant="outlined"
                color="error"
                label={'Usuń'}
                onClick={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {' '}
                    {'Czy na pewno?'}{' '}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Czy napewno chcesz usunąć? Ta operacja jest
                        nieodwracalna.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DeleteButton
                        variant="outlined"
                        color="error"
                        label={'Usuń'}
                    />
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color="warning"
                        label={'Anuluj'}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}

export const CustomActionButtonDelete = () => {
    return <CustomDeleteButton />
}

const CustomOrdersDescriptionButton = () => {
    const record = useRecordContext()
    return (
        <Button
            className={'btn btn-outline-secondary btn-sm'}
            label={'Podgląd zamówienia'}
            component={Link}
            to={{
                pathname: `/orders/${encodeURIComponent(
                    `/orders/${record.orderId}`
                )}/show`,
            }}
        />
    )
}

const CustomTemporaryUsersButton = () => {
    const record = useRecordContext()
    return (
        <Button
            className={'btn btn-outline-secondary btn-sm'}
            label={'Generuj tymczasowego'}
            component="a"
            target="_blank"
            href={`${
                process.env.REACT_APP_API_ENTRYPOINT
            }/temporary_users/generate/${record?.id?.split('/')?.[2]}`}
        />
    )
}

const AddToBanner = () => {
    const record = useRecordContext()
    return (
        <Button
            className={'btn btn-outline-secondary btn-sm'}
            label={'AddToBanner'}
            component="a"
            target="_blank"
            href={`${process.env.REACT_APP_API_ENTRYPOINT}/media_objects/post/${
                record?.id?.split('/')?.[2]
            }`}
        />
    )
}
const CustomInvoiceDownloadButton = () => {
    const record = useRecordContext()

    let btn = (
        <Button
            label={'Pobierz fakturę XML'}
            color="primary"
            variant="outlined"
            component={Link}
            onClick={() => {
                window.open(record.downloadUrl, '_blank').focus()
            }}
        />
    )

    if (record?.createdAt && dayjs().diff(dayjs(record?.createdAt), 'm') < 60) {
        btn = (
            <Tooltip title="Trwa generowanie faktury, zapraszamy za chwilę">
                <div>{btn}</div>
            </Tooltip>
        )
    }

    return record?.downloadUrl && record?.invoiceType == 'Faktura' && btn
}

const CustomAddToCartButton = () => {
    const record = useRecordContext()
    return (
        <Button
            className={'btn btn-outline-secondary btn-sm'}
            label={'Dodaj do koszyka'}
            onClick={() => {
                console.log(record.id)
            }}
        />
    )
}

export const CustomShowButton = () => {
    return (
        <ShowButton
            className={'btn btn-outline-info btn-sm'}
            label={'Szczegóły'}
        />
    )
}

export const UpdatePickupPoints = () => {
    const record = useRecordContext()
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    return (
        <Button
            color="warning"
            variant="outlined"
            style={{ margin: '0 1em' }}
            size="small"
            label={'Odśwież punkty odbioru'}
            disabled={loading}
            onClick={() => {
                setLoading(true)
                authProvider
                    .updatePickupPoints(
                        record.id.split('/')[2],
                        record?.integrationCodeName
                    )
                    .then((resp) =>
                        enqueueSnackbar(
                            resp == 200
                                ? 'Odświeżenie przebiegło pomyślnie'
                                : 'Wystąpił błąd',
                            {
                                variant: resp == 200 ? 'info' : 'error',
                                autoHideDuration: 5000,
                            }
                        )
                    )
                    .catch((resp) =>
                        enqueueSnackbar('Wystąpił wewnętrzny błąd', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        })
                    )
                    .finally(() => setLoading(false))
            }}
        />
    )
}

export const UpdateInvoice = () => {
    const record = useRecordContext()
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const refresh = useRefresh()

    return (
        <Button
            color="primary"
            label="Odśwież"
            disabled={loading}
            onClick={() => {
                setLoading(true)
                authProvider
                    .updateInvoice(record)
                    .then((resp) => {
                        enqueueSnackbar(
                            `[${record.nrZam}] ` +
                                (resp == 200
                                    ? 'Odświeżenie przebiegło pomyślnie'
                                    : 'Wystąpił błąd'),
                            {
                                variant: resp == 200 ? 'info' : 'error',
                                autoHideDuration: 5000,
                            }
                        )
                        refresh()
                    })
                    .catch((resp) =>
                        enqueueSnackbar('Wystąpił wewnętrzny błąd', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        })
                    )
                    .finally(() => setLoading(false))
            }}
        />
    )
}

export const UpdatePayersInvoices = () => {
    const record = useRecordContext()
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const refresh = useRefresh()

    return (
        <Button
            color="warning"
            variant="contained"
            label={'Odśwież faktury'}
            disabled={loading}
            onClick={() => {
                setLoading(true)
                authProvider
                    .updatePayersInvoices(
                        record.id.split('/')[2],
                        record?.integrationCodeName
                    )
                    .then((resp) =>
                        enqueueSnackbar(
                            resp == 200
                                ? 'Odświeżenie przebiegło pomyślnie'
                                : 'Wystąpił błąd',
                            {
                                variant: resp == 200 ? 'info' : 'error',
                                autoHideDuration: 5000,
                            }
                        )
                    )
                    .catch((resp) =>
                        enqueueSnackbar('Wystąpił wewnętrzny błąd', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        })
                    )
                    .finally(() =>{
                        setLoading(false)
                        refresh()
                    })
            }}
        />
    )
}

export const UpdateLimit = () => {
    const record = useRecordContext()
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const refresh = useRefresh()
    const debtWarning = 2000
    const info = `${record.debt ?? 0} / ${record.debtLimit}`
    let btnValue = info
    
    if (record.debtLimit < 0) {
        btnValue = '-'
    }
    else if (record.debtLimit - record.debt < 0) {
        btnValue = <span className='text-danger'>{info}</span>
    }
    else if (record.debtLimit - record.debt <= debtWarning) {
        btnValue = <span style={{color:"rgb(237, 108, 2)"}} >{info}</span>
    }
    if(record?.partnerName ? false : true){
        return btnValue
    }

    return (
        <Button
            variant="text"
            label={<Tooltip title="kliknij aby odświeżyć" >{btnValue}</Tooltip> }
            disabled={loading}
            onClick={() => {
                setLoading(true)
                authProvider
                    .updatePayersDebt(
                        record.id.split('/')[2],
                        record?.integrationCodeName
                    )
                    .then((resp) =>
                        enqueueSnackbar(
                            resp == 200
                                ? 'Odświeżenie przebiegło pomyślnie'
                                : 'Wystąpił błąd',
                            {
                                variant: resp == 200 ? 'info' : 'error',
                                autoHideDuration: 5000,
                            }
                        )
                    )
                    .catch((resp) =>
                        enqueueSnackbar('Wystąpił wewnętrzny błąd', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        })
                    )
                    .finally(() => {
                        setLoading(false)
                        refresh()
                    })
            }}
        />
    )
}

const RedirectButton = ({ label, href }) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            size="small"
            label={label}
            href={href}
        />
    )
}

export const CustomActionsButtons = (props) => {
    const record = useRecordContext()
    const extraEdit =
        props?.extraEditAvailableFunctionDependsOnRecord &&
        props?.extraEditAvailableFunctionDependsOnRecord(props.record)

    const redirectBtn = props.redirectBtn instanceof Function ? props.redirectBtn() : props.redirectBtn;
    
    return (
        <div className={'btn-group'}>
            {props.isEditAvailable ? (
                <CustomEditButton />
            ) : extraEdit ? (
                <CustomEditButton />
            ) : null}
            {props.isDeleteAvailable && <CustomDeleteButton />}
            {props.isGenerateTemporaryUsersAvailable && (
                <CustomTemporaryUsersButton />
            )}
            {props.isDescriptionAvailable && <CustomShowButton />}
            {props.isAddToCartAvailable && <CustomAddToCartButton />}
            {props.isCustomOrdersDescriptionButton && (
                <CustomOrdersDescriptionButton />
            )}
            {props.isCustomInvoiceDownloadButton && (
                <CustomInvoiceDownloadButton />
            )}
            {redirectBtn && (
                <RedirectButton
                    label={redirectBtn.label}
                    href={redirectBtn.href}
                />
            )}
            {props.refreshPP && record?.partnerName && <UpdatePickupPoints />}
            {props.isRefreshInvoicesAvailable && <UpdateInvoice />}
            {props.refreshInvoicesPayer && record?.partnerName && <UpdatePayersInvoices />}
            {props.refreshDebt &&  <UpdateLimit />}
            {props.isAddToBanner && <AddToBanner />}
            {props.refreshPP && !extraEdit && <UpdatePickupPoints />}
        </div>
    )
}
