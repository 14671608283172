import * as React from "react";
import { useRecordContext } from "react-admin";

export const CustomTitle = (props) => {
  const { resourceName = "", fields = ["name"] } = props;
  const record = useRecordContext();
  return record
    ? `${resourceName} ${fields?.map((_field) => record?.[_field])?.join(" ")}`
    : null;
};
