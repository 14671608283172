import React from 'react'

import Card from '@mui/material/Card'

const fontStyle = {
    textTransform: 'normal',
    fontSize: '1rem',
}
const textBox = {
    padding: '2%',
    margin: '0%',
}

export default () => {
    return (
        <Card>
            <div style={textBox}>
                <h2>
                    Zmiany w zasadach dofinansowania NFZ od 1 stycznia 2024 r.
                </h2>
                <br />
                <p style={fontStyle}>
                    Od 1 stycznia 2024 r. weszło w życie nowe rozporządzenie
                    Ministra Zdrowia zmieniające rozporządzenie w sprawie wykazu
                    wyrobów medycznych wydawanych na zlecenie.
                </p>
                <p style={fontStyle}>Istotne zmiany w Dofinansowaniu NFZ:</p>
                <p>
                    <ul>
                        <li>
                            obniżenie <strong>minimalnej</strong> chłonność dla wyrobów chłonnych
                            z pierwszego limitu 1,00 zł - z 450 g na 350 g
                        </li>
                        <li>
                            obniżenie wysokości <strong>dopłaty pacjenta</strong> do limitu w
                            przypadku wniosków bez uprawnień dodatkowych: z 30%
                            na 20% dla dorosłych i 10% dla osób do 18. roku
                            życia
                        </li>
                        <li>
                            zastąpienie <strong>kodów wyrobu</strong> medycznego P.100 i P.101 -
                            jednolitym kodem Y.06.01 - przy czym ostatni człon
                            kodu będzie zależny od wieku pacjenta i przedstawia
                            się następująco:
                        </li>
                        <ul>
                            <li><u>Y.06.01.00</u> - w przypadku osób dorosłych</li>
                            <li>
                            <u>Y.06.01.01</u> - w przypadku osób do 18. roku życia
                            </li>
                        </ul>
                    </ul>
                    <p>
                        W związku z obniżeniem minimalnego progu chłonności dla
                        wyrobów w najniższym limicie (1,00 zł), na listę wyrobów
                        z dofinansowaniem NFZ od 1 stycznia 2024 powrócił
                        produkt: <strong>Seni Lady Slim Normal</strong> (występujący wcześniej
                        pod nazwą Seni Lady Normal).
                    </p>
                    <p style={fontStyle}>
                        Poniżej udostępniamy Państwu link do publikacji
                        rozporządzenia:
                    </p>
                    <p>
                        <a href="https://dziennikustaw.gov.pl/DU/2023/2461" target='_blank'>
                            https://dziennikustaw.gov.pl/DU/2023/2461
                        </a>
                    </p>
                </p>
            </div>
        </Card>
    )
}
