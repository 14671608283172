import React, { useState } from 'react'
import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    email,
    FormDataConsumer,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    usePermissions,
    FunctionField,
    Button,
    useRecordContext,
} from 'react-admin'
import { useWatch } from 'react-hook-form'

import PermissionHandler from '../../config/PermissionHandler'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const requiredField = required('Pole wymagane')
const isEmail = [required('Pole wymagane'), email('Podany jest nie prawidłowy e-mail')]

const transform = (data, previousData) => ({
    ...data,
    type: parseInt(data.type),
    email: previousData.email,
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <Edit
            {...props}
            title={<CustomTitle resourceName="pracownik" fields={['name', 'surname']} />}
            transform={(data, { previousData }) => transform(data, previousData)}
        >
            <SimpleForm
                toolbar={
                    <PostBottomToolbarEdit
                        isDeleteAvailable={PermissionHandler(permissions, 'Workers', 'Delete')}
                        confirmModal={{ fields: ['status'], message: "Czy na pewno chcesz zapisać ze statusem 'nieaktywny'?" }}
                    />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput variant="outlined" source="name" validate={requiredField} fullWidth label="Imię" />

                        <TextInput variant="outlined" source="surname" validate={requiredField} fullWidth label="Nazwisko" />

                        <SelectInput
                            source="type"
                            variant="outlined"
                            validate={requiredField}
                            choices={[
                                { id: 10, name: 'pracownik' },
                                { id: 20, name: 'kierownik' },
                            ]}
                            fullWidth
                            label="Typ"
                        />

                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.type && (
                                    <tr>
                                        <td colSpan={2}>
                                            {formData.type == 10
                                                ? 'Składanie zamówień do przypisanego punktu odbioru, a także historia składanych zamówień dla danego punktu'
                                                : 'Ma możliwość przeglądania zamówień dla wszystkich przypisanych do siebie punktów odbioru a także składać zamówienie do wybranego punktu. Odpowiada także za dodawanie i zarządzanie pracownikami pierwszego stołu'}
                                        </td>
                                    </tr>
                                )
                            }
                        </FormDataConsumer>

                        <PickupPointsInput />

                        <TextInput variant="outlined" source="email" validate={isEmail} disabled fullWidth label="Adres e-mail" />

                        <TextInput variant="outlined" source="phone" fullWidth label="Numer tel." />

                        <StatusInput />

                        <tr>
                            <td colSpan={2}>Czy dany użytkownik będzie miał możliwość logowania</td>
                        </tr>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}

const StatusInput = () => {
    const [openStatusDialog, setStatusDialog] = useState(false)
    const status = useWatch({ name: 'status' })

    const [statusChecked, setStatusChecked] = useState(status)

    const handleCloseStatusDialog = () => {
        setStatusDialog(false)
    }

    return (
        <>
            <BooleanInput
                variant="outlined"
                label="Status"
                source="status"
                onChange={() => setStatusDialog(true)}
                fullWidth
                checked={statusChecked}
            />

            <Dialog
                open={openStatusDialog}
                onClose={handleCloseStatusDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Na pewno?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Na pewno chcesz zmienić status tego użytkownika?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setStatusChecked(!statusChecked)
                            handleCloseStatusDialog()
                        }}
                        autoFocus
                    >
                        Tak
                    </Button>
                    <Button
                        onClick={() => {
                            handleCloseStatusDialog()
                        }}
                    >
                        Nie
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const PickupPointsInput = () => {
    const record = useRecordContext()
    const payerId = record?.payer.split('/')[2]

    return (
        <ReferenceArrayInput source="pickupPoints" reference="pickup_points" validate={requiredField} filter={{ payer: payerId }} fullWidth>
            <AutocompleteArrayInput
                variant="outlined"
                optionText="internalName"
                fullWidth
                label="Punkty odbioru"
                filterToQuery={(searchText) => ({
                    internalName: searchText,
                })}
            />
        </ReferenceArrayInput>
    )
}
