import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Title } from 'react-admin'

export default () => (
    <Card>
        <Title title="Strony nie znaleziono" />
        <CardContent>
            <h1>404: Strony nie znaleziono</h1>
        </CardContent>
    </Card>
)
