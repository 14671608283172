import React from 'react'
import {
    Create,
    BooleanInput,
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useRecordContext,
} from 'react-admin'
import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required()

export default (props) => {
    return (
        <Create {...props} title={`Dodaj regulamin`}>
            <SimpleForm toolbar={<PostBottomToolbarAdd />}>
                <div className="d-flex w-100 justify-content-around">
                    <div class="col-xs-6">
                        <TextInput
                            source="name"
                            label="Nazwa"
                            variant="outlined"
                            fullWidth
                            validate={requiredField}
                        />
                        <TextInput
                            source="url"
                            label="Url"
                            variant="outlined"
                            fullWidth
                            validate={requiredField}
                        />
                        <ReferenceInput source="partner" reference="partners">
                            <SelectInput
                                label="Partner"
                                variant="outlined"
                                optionText="name"
                                fullWidth
                            />
                        </ReferenceInput>
                        <BooleanInput
                            source="status"
                            label="Status"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
