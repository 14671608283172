import React, { useEffect, useState } from 'react'
import { Error, useGetList } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default (props) => {
    const { data, total, isLoading, error } = useGetList('consents', {
        pagination: { page: 0, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: { status: true },
    })

    const [consents, setConsents] = useState([])
    useEffect(() => {
        if (
            data &&
            Object.keys(data).length > 0 &&
            Object.keys(consents).length === 0
        ) {
            console.info('Set consents')
            setConsents(Object.values(data))
        }
    })

    if (
        isLoading ||
        Object.keys(consents).length === 0
    ) {
        return (
            <div>
                Trwa pobieranie zgód. Jeśli proces się przedłuża - odśwież tę
                stronę.
            </div>
        )
    }
    if (error) {
        return <Error />
    }
    return (
        <>
            <h4 className="text-center col-xs-12">
                <FontAwesomeIcon icon={faCheckSquare} />{' '}
                <span className="">Zgody mailingowe</span>
            </h4>
            <div className="col-xs-12">
                <ul className="list-group list-group-flush">
                    {Object.keys(consents).map((record) => {
                        {
                            /*{consents.map(consent => {*/
                        }
                        let consent = consents[record]
                        return (
                            <li className="list-group-item">
                                <label>
                                    <input
                                        checked={consent.checked}
                                        type="checkbox"
                                        name="consent[]"
                                        value={consent.id}
                                        onChange={() => {
                                            setConsents(
                                                [...consents].map((object) => {
                                                    if (
                                                        object.id === consent.id
                                                    ) {
                                                        return {
                                                            ...object,
                                                            checked:
                                                                !object.checked,
                                                        }
                                                    } else return object
                                                })
                                            )
                                        }}
                                    />
                                    {consent.content}
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}
