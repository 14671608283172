import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";
export const SwitchTextTrack = styled(Switch)(({trueLabel = 'Tak', falseLabel = 'Nie'})=>({
  width: 300,
  height: 60,
  padding: 8,
  [`& .${switchClasses.switchBase}`]: {
    padding: 11,
    color: "#ff6a00"
  },
  [`& .${switchClasses.thumb}`]: {
    width: 38,
    height: 38,
    backgroundColor: "#fff"
  },
  [`& .${switchClasses.track}`]: {
    background: "linear-gradient(to right, #240a51d6, #ffff)",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#240a51",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: 800
    },
    "&:before": {
      content: '"'+trueLabel+'"',
      left: 4,
      opacity: 0
    },
    "&:after": {
      content: '"'+falseLabel+'"',
      right: 4
    }
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#185a9d",
      transform: "translateX(240px)",
      "&:hover": {
        backgroundColor: "rgba(24,90,257,0.08)"
      }
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: "#fff"
    },
    [`& + .${switchClasses.track}`]: {
      background: "linear-gradient(to right, #ffffff, #240a51d6)",
      "&:before": {
        opacity: 1
      },
      "&:after": {
        opacity: 0
      }
    }
  }
}));