import { Button, Alert } from "@mui/material";
import { forwardRef } from "react";

export const AppUpdatedNotification = forwardRef((props, ref) => (
    <Alert
        ref={ref}
        severity="info"
        action={
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
                Odświeź
            </Button>
        }
    >
        Dostępna jest nowa wersja aplikacji. Serdecznie proszimy odświeżyć.
    </Alert>
));