import React, { useEffect } from 'react'

import Card from '@mui/material/Card'

const fontStyle = {
    textTransform: 'normal',
    fontSize: '1rem',
}
const textBox = {
    padding: '2%',
    margin: '0%',
}

export default () => {
    return (
        <Card>
            <div style={textBox}>
                {localStorage.getItem("partnerName") == "Farmpol" ?
                <>
                    <p style={fontStyle}>FARMPOL Dział opieki długoterminowej/Refundacja:</p>
                    <p style={fontStyle}>tel. <a href='tel:586226591'>58 622 65 91</a></p>
                    <p style={fontStyle}>tel. <a href='tel:586604530'>58 660 45 30</a></p>
                    <p style={fontStyle}>e-mail: <a href='mailto:refundacja@farmpol.pl'>refundacja@farmpol.pl</a></p>
                    <p style={fontStyle}>pon-pt: 9:00-17:00 </p>
                </>
                :
                <>
                    <p style={fontStyle}>Biuro Refundacji Seni</p>
                    <p style={fontStyle}>e-mail: <a href='mailto:refundacja.seni@tzmo-global.com'>refundacja.seni@tzmo-global.com</a></p>
                    <p style={fontStyle}>tel. <a href='tel:566123420'>(56) 612 34 20</a></p>
                </>}
            </div>
        </Card>
    )
}
