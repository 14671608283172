import * as React from 'react'
import { Edit, required, SimpleForm, usePermissions, SelectInput } from 'react-admin'
import { useParams } from 'react-router-dom'
import { PostBottomToolbarEdit } from '../../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')

const transform = (data) => ({
    ...data,
})

export const rewardTypes = [
    { id: 1, name: 'Oddział' },
    { id: 2, name: 'Instytucja' },
]

export const ezwmDomains = [
    { id: 1, name: 'Dolnośląski' },
    { id: 2, name: 'Kujawsko-Pomorski' },
    { id: 3, name: 'Lubelski' },
    { id: 4, name: 'Lubuski' },
    { id: 5, name: 'Łódzki' },
    { id: 6, name: 'Małopolski' },
    { id: 7, name: 'Mazowiecki' },
    { id: 8, name: 'Opolski' },
    { id: 9, name: 'Podkarpacki' },
    { id: 10, name: 'Podlaski' },
    { id: 11, name: 'Pomorski' },
    { id: 12, name: 'Śląski' },
    { id: 13, name: 'Świętokrzyski' },
    { id: 14, name: 'Warmińsko-Mazurski' },
    { id: 15, name: 'Wielkopolski' },
    { id: 16, name: 'Zachodniopomorski' },
]

export default (props) => {
    const { payerId } = useParams()
    const { isLoading, permissions } = usePermissions()

    return (
        <Edit resource="payers" id={payerId} actions={null} title={<CustomTitle resourceName="Instytucja" fields={['name']} />} transform={transform}>
            <SimpleForm toolbar={<PostBottomToolbarEdit isDeleteAvailable={false} />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <SelectInput variant="outlined" source="rewardType" label="Gromadzenie pkt." choices={rewardTypes} fullWidth />
                        <SelectInput variant="outlined" source="ezwmDomain" label="Oddział NFZ" choices={ezwmDomains} fullWidth />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
