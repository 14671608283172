import * as React from 'react'
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
import { Divider } from '@mui/material'

const requiredField = required('Pole wymagane')

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create {...props} actions={null} title={`Dodaj oddział`}>
            <SimpleForm
                toolbar={
                    <PostBottomToolbarAdd saveRedirectPath="/departments" />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            label={'Nazwa'}
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />
                        <ReferenceInput
                            source="pickupPoint"
                            reference="pickup_points"
                        >
                            <AutocompleteInput
                                optionText="internalName"
                                resettable
                                filterToQuery={(searchText) => ({
                                    internalName: searchText,
                                })}
                                label="Powiązany punkt odbioru"
                                fullWidth
                                variant="outlined"
                                validate={requiredField}
                            />
                        </ReferenceInput>
                        <TextInput
                            variant="outlined"
                            source="personPesel"
                            label="Pesel osoby odbierającej"
                            fullWidth
                            validate={requiredField}
                        />
                        <TextInput
                            variant="outlined"
                            source="personName"
                            label="Imię i nazwisko osoby odbierającej"
                            fullWidth
                            validate={requiredField}
                        />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <BooleanInput
                            source="refundation"
                            label="Dofinansowanie DPS"
                        />
                        <BooleanInput source="status" label="Status" />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
