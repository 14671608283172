import * as React from "react";
import { List, TextField, Datagrid, usePermissions, TextInput } from "react-admin";
import { CustomActionsButtons } from "../CustomFields/CustomActionButtonsField";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";
import { CustomPagination } from "../CustomElements/CustomPagination";

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(permissions, "Samples", "Create")}
        />
      }
      title={`Próbki`}
      empty={
        <EmptyList
          title="próbek"
          add={PermissionHandler(permissions, "Samples", "Create")}
        />
      }
      filters={[
        <TextInput
            alwaysOn
            variant="outlined"
            resettable
            label="Nazwa"
            source="name"
        />,
        <TextInput
            alwaysOn
            variant="outlined"
            resettable
            label="SKU"
            source="sku"
        />
    ]}
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <TextField source="sku" label="SKU" />
        <TextField source="maxOrder" label="Maksymanlna ilość do zamówienia" />

        <CustomActionsButtons
          isEditAvailable={PermissionHandler(permissions, "Samples", "Update")}
          isDeleteAvailable={PermissionHandler(
            permissions,
            "Samples",
            "Delete"
          )}
          label="Akcje"
        />
      </CustomDataGrid>
    </List>
  );
};
