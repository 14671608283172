import * as React from 'react'
import {
    Filter,
    List,
    NumberField,
    ReferenceField,
    TextField, TextInput,
} from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'



export default (props) => {
    return (
        <List
            {...props}
            empty={<EmptyList title="płatników" add={false} />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        props.permissions,
                        'Payers',
                        'Create'
                    )}
                />
            }
            filters={
                [
                    <TextInput alwaysOn label="Nazwa" source="name" resettable />,
                    <TextInput alwaysOn label="NIP" source="nip" resettable />
                ]
            }
            bulkActionButtons={false}
            title={`Płatnicy`}
            pagination={<CustomPagination />}
        >
            <CustomDataGrid>
                <NumberField
                    source="oracleId"
                    label="Oracle Id"
                //sortable={false}
                />
                <TextField
                    source="clientNumber"
                    label="Numer klienta"
                //sortable={false}
                />
                <TextField
                    source="name"
                    label="Nazwa"
                //sortable={false}
                />
                <TextField
                    source="nip"
                    label="NIP"
                //sortable={false}
                />
                <TextField
                    source="postCode"
                    label="Kod pocztowy"
                //sortable={false}
                />
                <TextField
                    source="street"
                    label="Ulica"
                //sortable={false}
                />
                <TextField
                    source="city"
                    label="Miejscowość"
                //sortable={false}
                />

                <ReferenceField
                    source="voivodeship"
                    reference="voivodeships"
                    label="Województwo"
                    link={false}
                //sortable={false}
                >
                    <TextField source="name" />
                </ReferenceField>

                {PermissionHandler(props.permissions, 'Payers', 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDescriptionAvailable={PermissionHandler(
                            props.permissions,
                            'Payers',
                            'Update'
                        )}
                        isDeleteAvailable={false}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
