import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    ReferenceArrayInput,
    required,
    SimpleForm,
    SelectInput,
    usePermissions,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin'

import PermissionHandler from '../../config/PermissionHandler'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../CustomElements/CustomTitle'
import Box from '@mui/material/Box'

const imgSize = { width: 100, height: 100 }

const requiredField = required('Pole wymagane')

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Edit
            {...props}
            title={<CustomTitle resourceName="Baner" fields={['type']} />}
        >
            <SimpleForm
                toolbar={
                    <PostBottomToolbarEdit
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'Banners',
                            'Delete'
                        )}
                    />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <SelectInput
                            label="Typ"
                            source="type"
                            variant="outlined"
                            resettable
                            fullWidth
                            choices={[
                                { id: 1, name: ' Banner na stronie głównej' },
                            ]}
                            alwaysOn
                        />
                        <ReferenceInput
                            source="partner"
                            reference="partners"
                            className="autocompleteinput"
                            label=""
                            fullWidth
                            optionValue="id"
                        >
                            <AutocompleteInput
                                validate={requiredField}
                                label="Partner"
                                variant="outlined"
                                fullWidth
                                allowEmpty={true}
                                optionText={(record) =>
                                    `(ID:${record.id.split('/').pop()}) ${
                                        record.name
                                    } `
                                }
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                    id: searchText,
                                })}
                            />
                        </ReferenceInput>
                        <tr>
                            <BooleanInput
                                label="Aktywny"
                                source="active"
                                fullWidth
                            />
                            <td colSpan={2}>Czy dany banner będzie aktywny</td>
                        </tr>
                        <ReferenceArrayInput
                            source="relation"
                            reference="media_objects"
                            fullWidth
                            {...props}
                        >
                            <AutocompleteArrayInput
                                variant="outlined"
                                fullWidth
                                label="Zdjecie"
                                sx={{
                                    '& .MuiChip-root': {
                                        height: '100px',
                                    },
                                }}
                                optionText={(media) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: imgSize.width,
                                            height: imgSize.height,
                                        }}
                                    >
                                        <img
                                            src={media.contentUrl}
                                            key={media.id}
                                            alt="media"
                                            style={{
                                                width: imgSize.width,
                                                height: imgSize.height,
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                )}
                            />
                        </ReferenceArrayInput>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
