import { Button, Card } from '@mui/material'
import { Title, useGetOne } from 'react-admin'

const BodyReclamation = ({ title, link, features = null }) => {
    return (
        <div className="col">
            <h4>{title?.toUpperCase()}</h4>
            {Array.isArray(features) && (
                <ul>
                    {features?.map((f) => (
                        <li>{f}</li>
                    ))}
                </ul>
            )}

            <a href={link} target="_blank">
                <Button color="warning" variant="contained">
                    Przejdź do formularza
                </Button>
            </a>
        </div>
    )
}

export default () => {
    const { data, loading, error } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })

    return (
        <Card className="p-3">
            <Title title="Reklamacje" />
            <div className="d-flex w-100 p-2">
                {data?.reclamations &&
                    JSON.parse(data?.reclamations)?.map((r) => <BodyReclamation title={r.label} link={r.link} features={r.features} />)}
            </div>
        </Card>
    )
}
