import React, { useEffect, useState } from 'react'
import {
    AutocompleteInput,
    DateField,
    Filter,
    List,
    ReferenceInput,
    TextField,
    useDataProvider,
    TextInput,
    DateInput,
    SelectInput,
    usePermissions,
    BooleanField,
    FunctionField,
} from 'react-admin'
import CustomBooleanField from '../CustomFields/CustomBooleanField'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { Card, CardContent } from '@mui/material'
import EmptyList from '../EmptyList'
import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default function (props) {
    const { isLoading, permissions } = usePermissions()
    const dataProvider = useDataProvider()
    const [data, setData] = useState()
    const [delayedInvoiceNum, setDelayedInvoiceNum] = useState()
    const [isFetched, setIsFetched] = useState(false)
    const [inputs, setInputs] = useState({
        maxDebit: null,
        currentDebit: null,
        remainingDebit: null,
        maxTime: null,
        delayedInvoiceNumber: null,
    })

    useEffect(() => {
        if (
            PermissionHandler(permissions, 'Invoices', 'GetBalance') ||
            PermissionHandler(
                permissions,
                'Invoices',
                'GetDelayedInvoiceNumber'
            )
        ) {
            dataProvider
                .getOne('', { id: 'payers/get_balance' })
                .then(({ data: dataGetBalance }) => {
                    setData(dataGetBalance)
                    if (dataGetBalance?.delayedInvoice) {
                        setDelayedInvoiceNum(
                            dataGetBalance?.delayedInvoice?.number ?? null
                        )
                    } else {
                        setDelayedInvoiceNum('brak')
                    }
                })
            if (data && delayedInvoiceNum) {
                setInputs({
                    ...inputs,
                    maxDebit: data.maxDebit,
                    currentDebit: data.currentDebit,
                    remainingDebit: data.remainingDebit,
                    maxTime: data.maxTime,
                    delayedInvoiceNumber: delayedInvoiceNum,
                })
            }
        }
    }, [isFetched || permissions])
    if (data != undefined && delayedInvoiceNum && isFetched == false)
        setIsFetched(true)

    return (
        <>
            {PermissionHandler(permissions, 'Invoices', 'BalanceView') && (
                <Card
                    className="invoices-list-main"
                    style={{ fontFamily: 'Lato,sans-serif' }}
                >
                    <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
                        {/* col1 */}
                        <div className="col-xs-12 col-md-6">
                            <table className="table table-borderless">
                                <tr>
                                    <th className="align-middle">
                                        Limit obrotowy brutto:
                                    </th>
                                    <td>
                                        <span className=" number badge bg-success">
                                            {inputs.maxDebit
                                                ? `${inputs.maxDebit
                                                      .toString()
                                                      .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ' '
                                                      )} zł`
                                                : ' - '}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle">
                                        Termin płatności dla dokumentów:
                                    </th>
                                    <td>
                                        <span className=" number badge bg-primary">
                                            {inputs.maxTime
                                                ? `${inputs.maxTime}`
                                                : ' - '}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/* col2 */}
                        <div className="col-xs-12 col-md-6">
                            <table className="table table-borderless">
                                <tr>
                                    <th className="align-middle">
                                        Dostępny limit obrotowy brutto:
                                    </th>
                                    <td>
                                        <span className=" number badge bg-danger">
                                            {inputs.remainingDebit
                                                ? `${inputs.remainingDebit
                                                      .toString()
                                                      .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ' '
                                                      )} zł`
                                                : ' - '}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle">
                                        Najbardziej opóźniony dokument:
                                    </th>
                                    <td>
                                        <span className=" number badge bg-warning">
                                            {inputs.delayedInvoiceNumber}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </CardContent>
                </Card>
            )}
            {PermissionHandler(
                permissions,
                'Invoices',
                'GetDelayedInvoiceNumber'
            ) && (
                <Card
                    className="invoices-list-main mb-3"
                    style={{ fontFamily: 'Lato,sans-serif' }}
                >
                    <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
                        <div className="col-xs-12 col-md-6">
                            <table className="table table-borderless">
                                <tr>
                                    <th className="align-middle">
                                        Najbardziej opóźniony dokument:
                                    </th>
                                    <td>
                                        <span className="number badge bg-warning">
                                            {inputs.delayedInvoiceNumber}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </CardContent>
                </Card>
            )}
            <List
                {...props}
                disableSyncWithLocation
                title={' '}
                empty={false}
                pagination={<CustomPagination />}
                actions={false}
                bulkActionButtons={false}
                filter={{'status' : 2}}
               
            >
                <h4 className='text-center m-2'>Nieopłacone dokumenty po terminie </h4>
                <CustomDataGrid rowStyle={postRowStyle}>
                    <TextField source="orderNumber" label="Numer zamówienia" />
                    <TextField
                        source="orderNrZam"
                        label="Numer zamówienia Oracle"
                    />
                    <TextField source="number" label="Numer dokumentu" />
                    <DateField
                        source="dateSet"
                        label="Data wystawienia"
                        sortBy="date"
                    />
                    <DateField source="dateToPaid" label="Termin płatności" />
                    <TextField source="pickupPointName" label="Odbiorca" />
                    <BooleanField
                        source="status"
                        label="Status płatności"
                        valueLabelTrue="Opłacone"
                        valueLabelFalse="Nieopłacone"
                    />
                    <TextField source="net" label="Wartość netto" />
                    <TextField source="gross" label="Wartość brutto" />
                    <TextField source="orderSource" label="Rodzaj zamówienia" />
                    <TextField source="invoiceType" label="Typ dokumentu" />

                    <CustomActionsButtons
                        label="Szczegóły zamówienia"
                        isEditAvailable={PermissionHandler(
                            permissions,
                            'Invoices',
                            'Update'
                        )}
                        isDescriptionAvailable={false}
                        isCustomOrdersDescriptionButton={isDescr}
                        isCustomInvoiceDownloadButton={isDescr}
                    />
                </CustomDataGrid>
            </List>
            <List
                {...props}
                empty={<EmptyList title="płatności" add={false} />}
                pagination={<CustomPagination />}
                actions={false}
                bulkActionButtons={false}
                title={'Płatności'}
                filters={[
                    <DateInput
                        label="Data po"
                        source="date[after]"
                        alwaysOn
                        variant="outlined"
                        resettable
                    />,
                    <DateInput
                        label="Data przed"
                        source="date[before]"
                        alwaysOn
                        variant="outlined"
                        resettable
                    />,
                    <SelectInput
                        label="Status płatności"
                        source="status"
                        choices={[
                            { id: 1, name: 'Opłacone' },
                            { id: 0, name: 'Nieopłacone' },
                            { id: 2, name: 'Nieopłacone po terminie' },
                        ]}
                        alwaysOn
                        variant="outlined"
                        resettable
                    />,
                    <ReferenceInput
                        source="pickupPoint"
                        reference="pickup_points"
                        link={false}
                        alwaysOn
                        className="autocompleteinput"
                        allowEmpty
                    >
                        <AutocompleteInput
                            label="Punkt odbioru"
                            optionText="internalName"
                            variant="outlined"
                            resettable
                            filterToQuery={(searchText) => ({
                                internalName: searchText,
                            })}
                        />
                    </ReferenceInput>,
                    <TextInput
                        variant="outlined"
                        resettable
                        source="number"
                        label="Numer dokumentu"
                        alwaysOn
                    />,
                    <TextInput
                        variant="outlined"
                        resettable
                        source="order.nrZam"
                        label="Numer zamówienia Oracle"
                        alwaysOn
                    />,
                ]}
            >
                <CustomDataGrid rowStyle={postRowStyle}>
                    <TextField source="orderNumber" label="Numer zamówienia" />
                    <TextField
                        source="orderNrZam"
                        label="Numer zamówienia Oracle"
                    />
                    <TextField source="number" label="Numer dokumentu" />
                    <DateField
                        source="dateSet"
                        label="Data wystawienia"
                        sortBy="date"
                    />
                    <DateField source="dateToPaid" label="Termin płatności" />
                    <TextField source="pickupPointName" label="Odbiorca" />
                    <BooleanField
                        source="status"
                        label="Status płatności"
                        valueLabelTrue="Opłacone"
                        valueLabelFalse="Nieopłacone"
                    />
                    <TextField source="net" label="Wartość netto" />
                    <TextField source="gross" label="Wartość brutto" />
                    <TextField source="orderSource" label="Rodzaj zamówienia" />
                    <TextField source="invoiceType" label="Typ dokumentu" />

                    <CustomActionsButtons
                        label="Szczegóły zamówienia"
                        isEditAvailable={PermissionHandler(
                            permissions,
                            'Invoices',
                            'Update'
                        )}
                        isDescriptionAvailable={false}
                        isCustomOrdersDescriptionButton={isDescr}
                        isCustomInvoiceDownloadButton={isDescr}
                    />
                </CustomDataGrid>
            </List>
        </>
    )
}

const isDescr = (props) => {
    return props.record.order && true
}

const postRowStyle = (record, index) => {
    //console.log(new Date(record.dateToPaid), new Date());
    if (!record.dateToPaid && !record.status) {
        return {
            backgroundColor: '#ffeeba',
        }
    } else
        return {
            backgroundColor:
                !record.status && new Date(record.dateToPaid) < new Date()
                    ? '#f5c6cb'
                    : '',
        }
}
