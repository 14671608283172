import React from 'react'
import { useCreate, useDataProvider } from 'react-admin'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: 'background.paper',
    boxShadow: 24,
}

export default ({ isOpen, setIsOpen, products }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [name, setName] = React.useState('')
    const dataProvider = useDataProvider()

    const handleClosing = () => {
        setIsOpen(false)
    }

    const handleSubmit = () => {
        dataProvider
            .create('order_templates', { data: { name, products } })
            .then(() =>
                enqueueSnackbar('Szablon został zapisany', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            )
            .catch(() =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => handleClosing())
    }

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => handleClosing()}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        <Box sx={{ padding: '10px 10px 0 10px' }}>
                            <Typography variant="h5">Nowy szablon</Typography>
                            <Divider
                                sx={{ opacity: 1, marginBottom: '20px' }}
                            />
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label="Nazwa szablonu"
                            />
                        </Box>
                        <Box className="my-4">
                            <Divider sx={{ opacity: 1 }} />
                            <Box
                                sx={{
                                    marginTop: '5px',
                                    display: 'flex',
                                    padding: '5px 0 0 10px',
                                    width: '45%',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    disabled={name?.length == 0}
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Zapisz
                                </Button>
                                <Button
                                    onClick={() => handleClosing(false)}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Anuluj
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
