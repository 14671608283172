import * as React from "react";
import { List, TextField, usePermissions, FunctionField, TextInput, ReferenceInput, AutocompleteInput } from "react-admin";
import { CustomActionsButtons } from "../CustomFields/CustomActionButtonsField";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

import { CustomPagination } from "../CustomElements/CustomPagination";
import { Link, Tooltip } from "@mui/material";

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  const [actualNetworkPayer,setActualNetworkPayer] = React.useState('')
  React.useEffect(()=>{
    const url =  decodeURI( window.location.hash)
    const searchParams = new URLSearchParams(url)
    const filterObj = JSON.parse(searchParams.get('filter'))
    if (filterObj && filterObj.payer)  setActualNetworkPayer( filterObj.payer.payerNetwork);
})
  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      empty={
        <EmptyList
          title="płatników użytkowników"
          add={PermissionHandler(permissions, "PayerUsers", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(permissions, "PayerUsers", "Create")}
          isFiltersVisible={false}
        />
        //null
      }
      bulkActionButtons={false}
      title={`Płatnicy użytkownicy`}
      filters={[
        <TextInput
            variant="outlined"
            resettable
            alwaysOn
            label="Nazwisko"
            source="user.surname"
        />,
        <TextInput
            variant="outlined"
            resettable
            alwaysOn
            label="Email"
            source="user.email"
        />,
        <ReferenceInput
            source="payer.payerNetwork"
            reference='payer_networks'
            link={false}
            className="autocompleteinput"
            alwaysOn
            allowEmpty
        >
            <AutocompleteInput
                label="Płatnik sieciowy"
                optionText= "name"
                variant="outlined"
                resettable
                allowEmpty
                filterToQuery={(searchText)=>({
                    name: searchText
                })}
                onChange={(change)=>{
                  setActualNetworkPayer(change)
              }}
            />
        </ReferenceInput>,
        <ReferenceInput
            source='payer'
            reference='payers'
            className="autocompleteinput"
            link={false}
            alwaysOn
            allowEmpty
            filter={{payerNetwork: actualNetworkPayer}}

        >
        <AutocompleteInput
            label="Płatnik"
            optionText="name"
            variant="outlined"
            filterToQuery={(searchText)=>({
                name: searchText
            })}
            resettable
            alwaysOn
        />
        </ReferenceInput>
      ]}
      
    >
      <CustomDataGrid>
      <FunctionField source="id" label='id'render={(record)=>record.id.split('/')[2]} />
        <TextField source="name" label="Imię" />
        <TextField source="surname" label="Nazwisko" />
        <TextField source="email" label="E-mail" />
        <TextField source="phone" label="Numer Telefonu" />
        <TextField source="payerName" label="Płatnik" />
        <TextField source="payerNetworkName" label="Płatnik sieciowy" />
        {PermissionHandler(permissions, "Other", "CanLogin") && (
          <FunctionField
            label="Link logowania"
            render={(record) => {
              const [title , setTitle] = React.useState('Kliknij żeby skopiować')
              return <Tooltip title={title}>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    navigator.clipboard.writeText(
                      record?.loginUrl
                    )
                    setTitle('Skopiowano')
                    setTimeout(()=>{
                      setTitle('Kliknij żeby skopiować')
                    },2000)
                  }}
                  fontSize={10}
              >
                  {record?.loginUrl}
                </Link>
              </Tooltip>
            }}
          />
        )}

        {PermissionHandler(permissions, "PayerUsers", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(
              permissions,
              "PayerUsers",
              "Update"
            )}
            isDeleteAvailable={PermissionHandler(
              permissions,
              "PayerUsers",
              "Delete"
            )}
          />
        )}
      </CustomDataGrid>
    </List>
  );
};
