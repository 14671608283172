import { useSnackbar } from 'notistack'
import React, { Component, useEffect, useState } from 'react'
import { useRedirect, useDataProvider } from 'react-admin'

import authProvider from '../../config/authProvider'

const HashLogin = (props) => {
    const dataProvider = useDataProvider()
    const [shouldRedirect, setShouldRedirect] = useState(null)
    const [error, setError] = useState(null)
    const redirect = useRedirect()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const urlParams = new URL(window.location.href.replace('#', ''))
        .searchParams
    const user_id = urlParams.get('user_id')
    const hash = urlParams.get('hash')
    const mode = urlParams.get('mode')

    useEffect(() => {
        authProvider.loginByHash({ mode, user_id, hash }).then(
            (response) => {
                setShouldRedirect(true)
                setError(false)

                dataProvider
                    .getOne('pk_users', { id: `pk_users/me` })
                    .then((resp) => {
                        let data = resp?.data
                        localStorage.setItem('partnerId', data?.partnerId)
                        localStorage.setItem('shopUrl', data?.shopUrl)
                        localStorage.setItem('partnerName', data?.partnerName)
                        localStorage.setItem('shopId', data?.shopId);
                        localStorage.setItem('indOrds', data?.isIndividualOrderAvailable);
                        if(mode == 'reset'){
                            return window.location.replace('#/reset_password_form');
                        }
                        if(!data?.isActivated){
                            return window.location.replace('#/mydata')
                        }                        
                        window.location.replace('/');
                    })
            },
            (error) => {
                enqueueSnackbar(error?.message ?? 'Wystąpił błąd serweru', {
                    variant: 'warning',
                    autoHideDuration: 10000,
                    preventDuplicate: true,
                })
                setError(error)
                redirect('/login')
            }
        )
    }, [])

    return <div>Przekierowanie...</div>
}

export default HashLogin
