import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import { useState } from "react"
import {
    MenuItemLink,
} from 'react-admin'

const CustomMultiItemMenu = (props) => {
    const [open,setOpen] = useState(false)
    const childrenLinks = props.childrens.map((child)=> (<MenuItemLink style={{marginLeft:'5px'}} to = {child.link} primaryText = {child.text} leftIcon = {child.icon}/>))

    return <>   
                <MenuItem onClick={ () => { setOpen(!open) } }>
                    <ListItemIcon> {open ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/> }</ListItemIcon>
                    <ListItemText sx={{color: '#646464', marginLeft:'4px'}} primary={props.parentText}/>
                </MenuItem>
                {open && childrenLinks}
            </>
}
export default CustomMultiItemMenu