import React from 'react'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import PersonIcon from '@mui/icons-material/Person'
import EastIcon from '@mui/icons-material/East'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    boxShadow: 24,
}

export default ({ isOpen, setIsOpen, data = null }) => {
    const handleClosing = () => {
        setIsOpen(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => handleClosing()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen}>
                <Box sx={style}>
                    <Box
                        sx={{
                            padding: '80px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Stack
                            marginBottom="20px"
                            fontSize="60px"
                            color="#240a51"
                            direction="row"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <MarkEmailReadIcon fontSize="inherit" />
                            <EastIcon fontSize="large" />
                            <PersonIcon fontSize="inherit" />
                        </Stack>
                        <Typography variant="h5" color="#240a51" gutterBottom>
                            {data ? (
                                <>
                                    Witaj {data?.name} {data?.surname}, niewiele
                                    brakuje!
                                </>
                            ) : (
                                <>Już prawie!</>
                            )}
                        </Typography>
                        <Typography gutterBottom>
                            {data ? (
                                <>
                                    Wkrótce otrzymasz wiadomość e-mail
                                    zawierającą link z potwierdzeniem{' '}
                                    <b>{data?.email}</b>.
                                </>
                            ) : (
                                <>
                                    Nowe konto zostało utworzone, sprawdź
                                    jeszcze tylko skrzynkę mailową.
                                </>
                            )}
                        </Typography>
                        <Typography gutterBottom>
                            Aktywuj swoje konto,{' '}
                            <b>
                                klikając link znajdujący się w e-mailu z
                                potwierdzeniem
                            </b>
                            , aby ukończyć proces rejestracji.
                        </Typography>
                        <Typography gutterBottom>
                            <b>Nie wysłaliśmy Ci wiadomości e-mail?</b>
                            <br />
                            Sprawdź swój folder spam.
                            <br />
                            Jeśli w ciągu kolejnych pięciu minut nie otrzymasz
                            wiadomości e-mail, skontaktuj się z naszą obsługą
                            klienta.
                        </Typography>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
