import React from 'react'
import { useStore, Title,useTheme,usePermissions,useRedirect } from 'react-admin'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import Switch from '@mui/material/Switch'
import { Editor } from '@tinymce/tinymce-react'
import { useSnackbar } from 'notistack'

import authProvider from '../../config/authProvider'
import PermissionHandler from '../../config/PermissionHandler'

export default () => {
    const { isLoading, permissions } = usePermissions()
    const redirect = useRedirect();
    const [theme, setTheme] = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [ips, setIps] = React.useState('')
    const [status, setStatus] = React.useState(false)
    const [initialStatus, setInitialStatus] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const editorRef = React.useRef(null)

    React.useEffect(() => {
        if(!isLoading &&! PermissionHandler(permissions,'TechnicalPause','Read')){
            redirect("/");
        }
        authProvider.getMaintaince().then((result) => {
            if (result) {
                setIps(result?.ip_list?.join(','))
                setMessage(result?.body)
                setStatus(result?.status)
                setInitialStatus(result?.status)
            }
        })
    }, [isLoading])

    const handleMyIp = () => {
        authProvider
            .getMyIp()
            .then((resp) =>
                setIps(`${ips?.length > 0 ? ips + ',' : ''}${resp}`)
            )
    }

    const onSubmit = () => {
        let ok = true
        if (status == true && initialStatus == false) {
            ok = window.confirm(
                'Na pewno chcesz włączyć tryb przerwy technicznej? Zablokuje to dostęp do panelu klienta wszystkim użytkownikom (oprócz tych co operują adresami IP wskazanych do zarządzania)'
            )
        }
        if (ok) {
            authProvider
                .setMaintaince(ips, editorRef.current.getContent(), status)
                .then((result) =>
                    enqueueSnackbar(result, {
                        variant: 'success',
                        autoHideDuration: 5000,
                    })
                )
                .catch(() =>
                    enqueueSnackbar('Wystąpił błąd', {
                        variant: 'error',
                        autoHideDuration: 5000,
                    })
                )
        }
    }

    return (
        <Card>
            <Title title="Przerwa techniczna" />
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Przerwa techniczna dla użytkowników panelu klienta
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '75%',
                        justifyContent: 'center',
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={status}
                                    onChange={(e) =>
                                        setStatus(e.target.checked)
                                    }
                                    color="primary"
                                />
                            }
                            label="Włącz"
                        />
                    </FormGroup>
                    <Tooltip
                        placement="right"
                        title="Zalecane jest dodanie adresów IP użytkowników które będą mieli dostęp do panelu klienta podczas trybu przerwy technicznej. Proszimy wymienić je przez przecinek, bez spacji"
                    >
                        <TextField
                            sx={{marginTop:2}}
                            value={ips}
                            onChange={(e) => setIps(e.target.value)}
                            variant="outlined"
                            label="Adresy IP do zarządzania"
                            multiline
                            rows={4}
                            placeholder="Na przykład: 127.0.0.1,192.168.0.1 ..."
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            placement="right"
                                            title="Dodaj mój adres IP"
                                        >
                                            <IconButton onClick={handleMyIp}>
                                                <MyLocationIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Tooltip>
                    <Typography sx={{marginTop:2}} variant="subtitle1" gutterBottom>
                        Treść zaślepki
                    </Typography>
                    <Editor
                        apiKey="3jzsfnqxc196b4jxuqdedt9ujwxujnpnau3joozxpam04bux"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            language:'pl',
                            skin: 'material-outline',
                            content_css: 'material-outline',
                            height: 500,
                            menubar: true,
                            relative_urls: false,
                            remove_script_host: true,
                            plugins: [
                                'advlist',
                                'autolink',
                                'lists',
                                'link',
                                'image',
                                'charmap',
                                'preview',
                                'anchor',
                                'searchreplace',
                                'visualblocks',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'media',
                                'table',
                                'help',
                                'wordcount',
                                'template',
                            ],
                            toolbar:
                                'undo redo | formatselect | casechange blocks | bold italic backcolor | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help' +
                                'template',
                        }}
                        initialValue={message}
                    />
                    <Button
                        sx={{marginTop:5}}
                        onClick={onSubmit}
                        variant="contained"
                        color="warning"
                    >
                        Zapisz
                    </Button>
                </Box>
            </CardContent>
        </Card>
    )
}
