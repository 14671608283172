import * as React from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import PermissionHandler from '../../config/PermissionHandler'
import { CustomTitle } from '../CustomElements/CustomTitle'
import TinyMCEInput from '../CustomInputs/TinyMCEInput'

const requiredField = required('Pole wymagane')

export default (props) => {
    return (
        <Edit
            {...props}
            actions={null}
            title={<CustomTitle resourceName="Szablon" fields={['name']} />}
        >
            <SimpleForm
                toolbar={<PostBottomToolbarEdit isDeleteAvailable={false} />}
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            source="subject"
                            validate={requiredField}
                            multiline
                            fullWidth
                            variant="outlined"
                            label="Temat"
                        />

                        <TinyMCEInput source="body" />

                        <ReferenceInput
                            source="partner"
                            reference="partners"
                            fullWidth
                            allowEmpty
                        >
                            <AutocompleteInput
                                label="Partner"
                                fullWidth
                                variant="outlined"
                                optionText={'name'}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                            />
                        </ReferenceInput>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
