import * as React from 'react'
import {
    Edit,
    email,
    ReferenceInput,
    required,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextInput,
    SelectInput,
    usePermissions,
    useRecordContext,
    useShowContext,
} from 'react-admin'

import PermissionHandler from '../../config/PermissionHandler'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'

import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

const transform = (data) => ({
    //for CustomSelectInput - transform type from string to int
    ...data,
    accountManager: parseInt(data.accountManager),
    accepted: parseInt(data.accepted),
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    function renderSwitch(param) {
        switch (param) {
            case 2:
                return (
                    <div className="alert alert-warning m-0">
                        <span className="m-0 p-1">
                            Ten punkt został zgłoszony do akceptacji.
                            <br />
                            Poinformujemy Cię gdy będziesz mógł składać
                            zamówenia
                        </span>
                    </div>
                )
            case 3:
                return (
                    <div className="alert alert-danger m-0">
                        <span className="m-0 p-1">
                            Ten punkt został odrzucony przez TZMO.
                            <br />
                            Możesz edytować jego dane i zgłosić go ponownie
                        </span>
                    </div>
                )
            default:
                return null
        }
    }

    const Header = ({ _source }) => {
        const show = useShowContext()
        const record = useRecordContext()

        return renderSwitch(record?.[_source]) ? (
            <SimpleShowLayout
                {...show}
                className="m-0 p-0"
                sx={{ '& .MuiStack-root': { margin: 0 } }}
            >
                <div>
                    <span className="text-center">
                        {renderSwitch(record?.[_source])}
                    </span>
                </div>
            </SimpleShowLayout>
        ) : null
    }

    return (
        <>
            <Show {...props} actions={null} title={` `}>
                <Header _source="accepted" />
            </Show>
            <Edit
                {...props}
                actions={null}
                title={
                    <CustomTitle
                        resourceName="Punkt odbioru"
                        fields={['name']}
                    />
                }
                transform={transform}
            >
                <SimpleForm
                    toolbar={
                        <PostBottomToolbarEdit
                            // saveRedirectPath="/pickup_points-info"
                            isDeleteAvailable={PermissionHandler(
                                permissions,
                                'PickupPoints',
                                'Delete'
                            )}
                        />
                    }
                >
                    <div className="d-flex w-100 justify-content-around">
                        <div className="col-sm-5">
                            <TextInput
                                label="Nazwa wewnętrzna dla PK"
                                variant="outlined"
                                source="internalName"
                                validate={requiredField}
                                fullWidth
                            />

                            <TextInput
                                label="Nazwa"
                                variant="outlined"
                                source="name"
                                validate={requiredField}
                                fullWidth
                            />

                            <TextInput
                                label="Miejscowość"
                                variant="outlined"
                                source="city"
                                validate={requiredField}
                                fullWidth
                            />

                            <TextInput
                                label="Kod pocztowy"
                                variant="outlined"
                                source="postCode"
                                validate={requiredField}
                                fullWidth
                            />

                            <TextInput
                                label="Ulica"
                                variant="outlined"
                                source="street"
                                validate={requiredField}
                                fullWidth
                            />

                            <ReferenceInput
                                source="voivodeship"
                                reference="voivodeships"
                                validate={requiredField}
                            >
                                <SelectInput
                                    variant="outlined"
                                    label="Województwo"
                                    optionText="name"
                                    fullWidth
                                />
                            </ReferenceInput>

                            {PermissionHandler(
                                permissions,
                                'PickupPoints',
                                'NumberStatusEdit'
                            ) && (
                                <tr>
                                    <th className="col-md">
                                        <TextInput
                                            variant="outlined"
                                            source="tzmoNumber"
                                            label="Numer TZMO"
                                            fullWidth
                                        />
                                    </th>
                                </tr>
                            )}
                            {PermissionHandler(
                                permissions,
                                'PickupPoints',
                                'NumberStatusEdit'
                            ) && (
                                <tr>
                                    <th className="col-md">
                                        <SelectInput
                                            label="Status"
                                            variant="outlined"
                                            source="accepted"
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: 'zaakceptowany',
                                                },
                                                // { id: 2, name: "oczekuje na akceptację" },
                                                { id: 3, name: 'odrzucony' },
                                                {
                                                    id: 4,
                                                    name: 'adres płatnika - do wykluczenia',
                                                },
                                            ]}
                                            fullWidth
                                        />
                                    </th>
                                </tr>
                            )}
                        </div>
                    </div>
                </SimpleForm>
            </Edit>
        </>
    )
}
