import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, required, TextInput } from "react-admin";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslate, useSafeSetState } from "ra-core";
import { useSnackbar } from "notistack";
import { Avatar, Card, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import LockIcon from "@mui/icons-material/Lock";

const ResetPassword = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submit = (values) => {
    values.username = values.username.trim()
    if (!validateEmail(values.username)) {
      enqueueSnackbar("Podano nieprawidłowy e-mail", {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return false;
    }
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/reset_password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: values.username }),
    }).then((response) =>
      response
        .json()
        .then((responseText) => {
          setLoading(false);
          if (responseText.status === "ok") {
            enqueueSnackbar(
              "Link do resetu hasła został wysłany na podany email",
              {
                variant: "info",
                autoHideDuration: 5000,
              }
            );
          } else if (responseText.status === "error") {
            enqueueSnackbar("Błędny adres e-mail bądź konto nieaktywne", {
              variant: "warning",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar("Niewiadomy błąd", {
              variant: "warning",
              autoHideDuration: 5000,
            });
          }
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar("Błąd serwera", {
            variant: "warning",
            autoHideDuration: 3000,
          });
        })
    );
  };

  return (
    <Form
      onSubmit={submit}
      defaultValues={{
        username: "",
        password: "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Card
          sx={{
            padding: "1em",
            maxWidth: 450,
            borderRadius: "25px",
            boxShadow:
              "0 1.4px 10px rgba(0,0,0,.045), 0 11px 80px rgba(0,0,0,.09)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a href="#/login">
              <img src="logo.svg" alt="logo" style={{ height: 100 }} />
            </a>
          </Box>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#240a51",
            }}
          >
            Platforma zakupowa produktów
            <span
              style={{
                color: "rgb(225, 138, 61)",
              }}
            >
              &nbsp;Grupy&nbsp;
            </span>
            TZMO
          </span>
          <Box sx={{ padding: "0 1em 0 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                autoFocus
                source="username"
                label={'Login - adres e-mail'}
                disabled={loading}
                validate={required()}
                fullWidth={true}
                variant="outlined"
                placeholder="user@example.com"
              />
            </Box>
          </Box>
          <div style={{ textAlign: "center" }}>
            <p className="lost-paswd">
              Wróć do logowania <a href="#/login">tutaj</a>
            </p>
          </div>
          <CardActions sx={{ padding: "0 1em 1em 1em" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ backgroundColor: "#240a51", color: "#ffffff" }}
              disabled={loading}
              fullWidth={true}
              className="login-btn"
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {"Zresetuj hasło"}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

ResetPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default ResetPassword;
