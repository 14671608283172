import * as React from 'react'
import { Labeled } from 'react-admin'
import { Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'

export function formatDate(date, showTime = true) {
    if (!date) return '-'
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (String(hours).length < 2) hours = '0' + hours
    if (String(minutes).length < 2) minutes = '0' + minutes
    if (String(seconds).length < 2) seconds = '0' + seconds

    let result = [day, month, year].join('.')
    result = showTime ? `${result}, ${hours}:${minutes}:${seconds}` : result

    return result
}

export const TextFieldShowView = ({
    label,
    text,
    emptyText = '-',
    isBool = false,
}) => {
    return (
        <Labeled label={label}>
            {isBool ? (
                text ? (
                    <DoneIcon />
                ) : (
                    <CloseIcon />
                )
            ) : (
                <Typography variant="body1">{text ?? emptyText}</Typography>
            )}
        </Labeled>
    )
}
