import * as React from 'react'
import { useStore } from 'react-admin'
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput'
import { styled } from '@mui/system'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'

const blue = {
    100: '#240a51',
    900: '#b49be0',
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
}

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `
)

const StyledInput = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'};
    border-radius: 8px;
    margin: 0 8px;
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 4rem;
    text-align: center;
  
    &:hover {
      border-color: ${blue[900]};
    }
  
    &:focus {
      border-color: ${blue[900]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  `
)

const StyledButton = styled('button')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      border-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `
)

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
        <BaseNumberInput
            slots={{
                root: StyledInputRoot,
                input: StyledInput,
                incrementButton: StyledButton,
                decrementButton: StyledButton,
            }}
            slotProps={{
                incrementButton: {
                    children: <AddIcon fontSize="small" />,
                    className: 'increment',
                },
                decrementButton: {
                    children: <RemoveIcon fontSize="small" />,
                },
            }}
            {...props}
            ref={ref}
        />
    )
})

const NumberInputBasic = ({ setValue, value, min, max, placeholder = '' }) => {
    return <NumberInput placeholder={placeholder} value={value} onChange={(event, val) => setValue(val)} min={min} />
}

const AddButton = ({ row, quantity, addProductCallback }) => {
    return (
        <Button
            variant="outlined"
            className="d-block w-100"
            color="success"
            onClick={() => addProductCallback(row, quantity)}
            disabled={quantity <= 0}
        >
            Dodaj
        </Button>
    )
}

export default function AddToOderSimple({ row, forceUpdate, setSearchedProducts }) {
    const [quantity, setQuantity] = React.useState(1)
    const [orderSimpleDetails, setOrderSimpleDetails] = useStore('order_simple_dps_details', {})
    let existIndex = -1

    const addProductCallback = (row, quantity) => {
        if (!orderSimpleDetails?.products) {
            orderSimpleDetails.products = []
        }

        if ((existIndex = orderSimpleDetails?.products?.findIndex((obj) => obj?.reference == row.reference)) >= 0) {
            orderSimpleDetails.products[existIndex].quantity += quantity
            setOrderSimpleDetails(orderSimpleDetails)
        } else {
            row.quantity = quantity
            orderSimpleDetails.products = [...orderSimpleDetails.products, row]
            console.log(orderSimpleDetails)
            setOrderSimpleDetails(orderSimpleDetails)
        }

        setSearchedProducts([])
        forceUpdate()
    }

    return (
        <>
            <NumberInputBasic setValue={setQuantity} value={quantity} min={row.minimalQuantity} />
            <AddButton row={row} quantity={quantity} addProductCallback={addProductCallback} />
        </>
    )
}
