import React, { useState } from 'react'
import {
    BooleanInput,
    Edit,
    email,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    usePermissions,
    PasswordInput,
    useRecordContext
} from 'react-admin'
import authProvider from '../../config/authProvider'

import PermissionHandler from '../../config/PermissionHandler'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'

import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

const transform = (data) => ({
    //for CustomSelectInput - transform type from string to int
    ...data,
    type: parseInt(data.type),
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const [pass, setPass] = useState(null)

    return (
        <Edit
            {...props}
            actions={null}
            title={<CustomTitle resourceName="Użytkownik" fields={['email']} />}
            transform={transform}
        >
            <SimpleForm
                toolbar={
                    <PostBottomToolbarEdit
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'PkUsers',
                            'Delete'
                        )}
                    />
                }
                defaultValues={{password:''}}
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="email"
                            validate={isEmail}
                            fullWidth
                        />

                        <PasswordInput
                            variant="outlined"
                            source="password"
                            inputOnChange={(e) => setPass(e.target.value)}
                            value={pass}
                            fullWidth
                            inputProps={{ autocomplete: 'current-password' }}
                        />

                        <SelectInput
                            variant="outlined"
                            source="type"
                            validate={requiredField}
                            choices={
                                authProvider.hasRole(['ROLE_SUPER_ADMIN'])
                                    ? [
                                          {
                                              id: 999,
                                              name: 'Super admin',
                                          },
                                          {
                                              id: 500,
                                              name: 'Admin',
                                          },
                                          {
                                              id: 405,
                                              name: 'Szkoleniowiec',
                                          },
                                          //{ id: 400, name: "Windykacja" },
                                          // { id: 110, name: "Płatnik Wirtualny" },
                                          // { id: 100, name: "Płatnik" },
                                          // { id: 20, name: "Menadżer" },
                                          // { id: 10, name: "Pierwszy stół" },
                                      ]
                                    : authProvider.hasRole(['ROLE_ADMIN'])
                                    ? [
                                          {
                                              id: 500,
                                              name: 'Admin',
                                          },
                                          {
                                              id: 405,
                                              name: 'Szkoleniowiec',
                                          },
                                          //{ id: 400, name: "Windykacja" },
                                          // { id: 110, name: "Płatnik Wirtualny" },
                                          // { id: 100, name: "Płatnik" },
                                          // { id: 20, name: "Menadżer" },
                                          // { id: 10, name: "Pierwszy stół" },
                                      ]
                                    : // : authProvider.hasRole(["ROLE_DEBT_COLLECTION"])
                                      // ? [
                                      //     { id: 110, name: "Płatnik Wirtualny" },
                                      //     { id: 100, name: "Płatnik" },
                                      //     { id: 20, name: "Menadżer" },
                                      //     { id: 10, name: "Pierwszy stół" },
                                      //   ]
                                      []
                            }
                            fullWidth
                        />

                        <BooleanInput
                            variant="outlined"
                            source="status"
                            label="Status"
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
