import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'

import { useGetOne, useCreate } from 'react-admin'

const TypingSkeleton = () => {
    return (
        <>
            <div
                class="spinner-grow text-dark ms-2"
                style={{ width: '5px', height: '5px' }}
            />
            <div
                class="spinner-grow text-dark ms-2"
                style={{ width: '5px', height: '5px' }}
            />
            <div
                class="spinner-grow text-dark ms-2"
                style={{ width: '5px', height: '5px' }}
            />
        </>
    )
}

const AvatarMsg = ({ text }) => {
    const [isTyping, setIsTyping] = React.useState(true)
    React.useEffect(() => {
        setTimeout(() => {
            setIsTyping(false)
        }, 2500)
    }, [])
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'start',
            }}
        >
            <Avatar src="/img/pani_cropped.png" />
            <Typography
                sx={{ fontSize: 14, marginLeft: '5px' }}
                color="text.secondary"
                gutterBottom
            >
                {isTyping ? <TypingSkeleton /> : text}
            </Typography>
        </Box>
    )
}

const WidgetBody = ({ setIsShow }) => {
    const [showMsg, setShowMsg] = React.useState(false)
    const [showSend, setShowSend] = React.useState(false)
    const [showAfter, setShowAfter] = React.useState(false)

    const [subject, setSubject] = React.useState()
    const [message, setMessage] = React.useState()

    const { data, isLoading } = useGetOne('pk_users', { id: `pk_users/me` })
    const [
        create,
        { error, data: dataResponse, isLoading: isLoadingResponse },
    ] = useCreate()

    const onSubmit = (e) => {
        e.preventDefault()
        if (!isLoading && data.email && data.firstName) {
            create('contact_form', {
                data: JSON.stringify({
                    subject: Number(subject),
                    message: message,
                    email: data.email,
                    name: data.firstName,
                }),
            })
            setShowAfter(true)
        }
    }

    return (
        <Box onSubmit={onSubmit} component="form">
            <Card sx={{ width: '300px' }}>
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h6" color="primary.main">
                            Zadaj pytanie do doradcy
                        </Typography>
                        <IconButton
                            type="button"
                            onClick={() => setIsShow(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Card variant="outlined" sx={{ padding: '10px' }}>
                        <AvatarMsg
                            text={
                                <>
                                    Dzień dobry, w czym mogę pomóc?
                                    <br />
                                    Proszę wybrać temat zgłoszenia poniżej:
                                </>
                            }
                        />
                        <FormControl required variant="standard" fullWidth>
                            <InputLabel id="widget-subject-select">
                                Wybierz temat zgłoszenia
                            </InputLabel>
                            <Select
                                id="widget-subject"
                                labelId="widget-subject-select"
                                label="Wybierz temat zgłoszenia"
                                name="widget-subject"
                                value={subject}
                                onChange={(e) => {
                                    setShowMsg(true)
                                    setSubject(e.target.value)
                                }}
                            >
                                <MenuItem value="4">
                                    spytaj o realizację / status złożonego
                                    zamówienia lub poproś o fakturę
                                </MenuItem>
                                <MenuItem value="6">
                                    umowy z NFZ, realizacja i rozliczenie zleceń
                                    na zaopatrzenie w wyroby medyczne
                                </MenuItem>
                                <MenuItem value="5">
                                    w sprawach produktowych, pytania, sugestie
                                    oraz szkolenia
                                </MenuItem>
                                <MenuItem value="3">
                                    wezwania do zapłaty, uzgodnienia salda lub
                                    przeterminowanych płatności
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {showMsg && (
                            <>
                                <AvatarMsg
                                    text={
                                        <>
                                            Dziękuję. Na czym dokładnie polega
                                            problem?
                                            <br />
                                            Proszę opisać poniżej i wysłać
                                            wiadomość:
                                        </>
                                    }
                                />

                                <TextField
                                    variant="standard"
                                    id="widget-message"
                                    label="Treść wiadomości"
                                    name="widget-message"
                                    fullWidth
                                    required
                                    value={message}
                                    onChange={(e) => {
                                        setShowSend(true)
                                        setMessage(e.target.value)
                                    }}
                                />
                            </>
                        )}
                        {showAfter && !isLoadingResponse && (
                            <AvatarMsg
                                text={
                                    error?.response?.status == 200 ? (
                                        <>
                                            Dziękuję za przesłanie wiadomości.
                                            <br />
                                            Już analizujemy zgłoszenie.
                                            Skontaktujemy się drogą mailową.
                                        </>
                                    ) : (
                                        <>
                                            Niestety w dany moment występują
                                            problemy z połączeniem.
                                            <br />
                                            Proszę spróbować odświeżyć stronę i
                                            przesłać wiadomość póżniej.
                                        </>
                                    )
                                }
                            />
                        )}
                    </Card>
                </CardContent>
                <CardActions>
                    <Button type="submit" disabled={!showSend} size="small">
                        Wyślij
                    </Button>
                </CardActions>
            </Card>
        </Box>
    )
}

const IconBody = ({ setIsShow }) => {
    return (
        <Tooltip title="Potrzebujesz pomocy?" arrow>
            <IconButton
                onClick={() => setIsShow(true)}
                size="large"
                id="support-widget"
                sx={{
                    color: 'primary.main',
                    ':hover': {
                        bgcolor: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}
            >
                <SupportAgentIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    )
}

export const ContactWidget = () => {
    const [isShow, setIsShow] = React.useState(false)

    return (
        <Box
            sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                position: 'sticky',
                zIndex: 10,
                bottom: 10,
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '15px',
                paddingBottom: '5px',
            }}
        >
            {isShow ? (
                <WidgetBody setIsShow={setIsShow} />
            ) : (
                <IconBody setIsShow={setIsShow} />
            )}
        </Box>
    )
}
