import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { usePermissions } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faChartLine, faCommentDollar, faFileContract, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PermissionHandler from '../../config/PermissionHandler'

const menuElementContainer = {
    display: 'flex',
    width: '100%',
    minWidth: 250,
    minHeight: 200,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const fontStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

export default (props) => {
    const { permissions } = usePermissions()

    return (
        <Card>
            <CardContent>
                <div className="page-content m-2">
                    <div className="row">
                        {PermissionHandler(permissions, 'RefundUpload', 'Read') && (
                            <div className="col">
                                <Link to="/documents" style={menuElementContainer} className="dash-card">
                                    <FontAwesomeIcon icon={faFileContract} inverse />
                                    <span style={fontStyle}>Dokumenty rozliczeniowe</span>
                                </Link>
                            </div>
                        )}
                        {['TZMO', 'SMP', 'Citonet Szczecin', 'Citonet Łódź'].includes(localStorage.getItem('partnerName')) && (
                            <div className="col">
                                <Link to="/pickup-points-refund" style={menuElementContainer} className="dash-card">
                                    <ReactSVG src="/icon-refund.svg" />
                                    <span style={fontStyle}>Status Rozliczeń</span>
                                </Link>
                            </div>
                        )}
                        {localStorage.getItem('partnerName') != 'SMP' && (
                            <>
                                {PermissionHandler(permissions, 'RefundCalc', 'Read') && (
                                    <div className="col">
                                        <Link to="/calculator" style={menuElementContainer} className="dash-card">
                                            <FontAwesomeIcon icon={faCalculator} inverse />
                                            <span style={fontStyle}>Kalkulator dofinansowania</span>
                                        </Link>
                                    </div>
                                )}
                                {PermissionHandler(permissions, 'RefundPrices', 'Read') && (
                                    <div className="col">
                                        <Link
                                            //href={refundPriceListUrl}
                                            //target="_blank"
                                            to="/refundation-price/"
                                            //rel="noopener noreferrer"
                                            style={menuElementContainer}
                                            className="dash-card"
                                        >
                                            <FontAwesomeIcon icon={faCommentDollar} inverse />
                                            <span style={fontStyle}>Cenniki Seni do NFZ</span>
                                        </Link>
                                    </div>
                                )}
                                ,
                                {PermissionHandler(permissions, 'RefundOther', 'Read') && (
                                    <div className="col">
                                        <Link to="/refundation-other/" style={menuElementContainer} className="dash-card">
                                            <FontAwesomeIcon icon={faInfoCircle} inverse />
                                            <span style={fontStyle}>Pozostałe informacje</span>
                                        </Link>
                                    </div>
                                )}
                                ,
                                <div className="col">
                                    <Link to="/refundation-contact/" style={menuElementContainer} className="dash-card">
                                        <FontAwesomeIcon icon={faPhone} inverse />
                                        <span style={fontStyle}>Kontakt</span>
                                    </Link>
                                </div>
                            </>
                        )}
                        {localStorage.getItem('partnerName') == 'SMP' && (
                            <>
                                {PermissionHandler(permissions, 'RefundCalc', 'Read') && (
                                    <div className="col">
                                        <Link to="/calculator" style={menuElementContainer} className="dash-card">
                                            <FontAwesomeIcon icon={faCalculator} inverse />
                                            <span style={fontStyle}>Kalkulator dofinansowania</span>
                                        </Link>
                                    </div>
                                )}
                                <div className="col">
                                    <a
                                        href={'/files/CENNIK_SENI_PAZDZIERNIK_2024_SMP.pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon icon={faCommentDollar} inverse />
                                        <span style={fontStyle}>Refundacja Seni</span>
                                    </a>
                                </div>
                                <div className="col">
                                    <a
                                        href={'/files/CENNIK_NFZ_CZERWIEC_2024_ORTOPEDIA_SMP.pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon icon={faCommentDollar} inverse />
                                        <span style={fontStyle}>Refundacja Ortopedia</span>
                                    </a>
                                </div>
                                <div className="col">
                                    <a
                                        href={'/files/CENNIK_NFZ_CZERWIEC_2024_POMOCNICZE_SMP.pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon icon={faCommentDollar} inverse />
                                        <span style={fontStyle}>Refundacja Pomocnicze</span>
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
