import * as React from 'react'
import { List, TextField, usePermissions, FunctionField, useRecordContext } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'

import { DataGrid, plPL } from '@mui/x-data-grid'
import RefundScale from '../Patients/partials/RefundScale'
import ezwmProvider from '../../config/ezwmProvider'
import { Typography,Box } from '@mui/material'

const ModuleName = 'RefundDocumentRealization'

export default () => {
    const { isLoading, permissions } = usePermissions()

    const RefundScaleWrapper = ({ record }) => {
        const [refundStatuses, setRefundStatuses] = React.useState(null)

        React.useEffect(() => {
            if (record?.refundNumber && !refundStatuses) {
                ezwmProvider.getRefundDocStatuses(record?.refundNumber, record?.pesel).then((data) => setRefundStatuses(data))
            }
        }, [record?.refundDocument])

        return <RefundScale refundMonths={refundStatuses} />
    }

    const ExpandPanel = () => {
        const record = useRecordContext()
        return (
            <Box>
                <Typography gutterBottom variant='h6'>Linie zamówienia:</Typography>
                {record?.products?.length > 0 ? (
                    <DataGrid
                        rows={record.products}
                        columns={[
                            { field: 'reference', headerName: 'SKU', flex: 1 },
                            { field: 'name', headerName: 'Nazwa produktu', flex: 3 },
                            { field: 'ean', headerName: 'EAN', flex: 1 },
                            { field: 'bloz', headerName: 'Bloz', flex: 1 },
                            { field: 'quantity', headerName: 'Ilość szt', flex: 1 },
                            { field: 'box', headerName: 'Ilość szt w kartonie', flex: 1 },
                        ]}
                        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                    />
                ) : (
                    'Brak'
                )}
                <Typography gutterBottom variant='h6'>Status zlecenia:</Typography>
                <RefundScaleWrapper record={record} />
            </Box>
        )
    }

    return (
        <List
            actions={<CustomActionBar isAddVisible={false} />}
            bulkActionButtons={false}
            empty={<EmptyList title="realizacji refundowanych zleceń" add={false} />}
            pagination={<CustomPagination />}
            title={`Realizacje refundowanych zleceń`}
        >
            <CustomDataGrid expand={<ExpandPanel />}>
                <FunctionField render={(record) => `${record.patientName} (ID ${record.patientId})`} label="Pacjent" />
                <TextField source="refundNumber" label="Numer zlecenia" />
                <TextField source="pesel" label="Pesel" />
                <TextField source="requestRealizationDate" label="Data pobrania do realizacji" />
                <TextField source="monthCount" label="Ilość miesięcy" />
                <TextField source="confirmRealizationDate" label="Data potwierdzenia realizacji" emptyText="Brak" />
                <TextField source="message" label="Komunikat eZWM" emptyText="Brak" />
            </CustomDataGrid>
        </List>
    )
}
