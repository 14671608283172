import React from 'react'
import {
    useGetList,
    ListContextProvider,
    useRecordContext,
    TextField,
    DateField,
    FunctionField,
    Title,
    NumberField,
    useRefresh,
    useNotify,
    useStore,
    useListController,
    useDelete,
    useRedirect,
    useList,
} from 'react-admin'
import { DataGrid, GridColDef, plPL } from '@mui/x-data-grid'
import Card from '@mui/material/Card'
import CustomDataGrid from '../../CustomElements/CustomDataGrid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import { CustomPagination } from '../../CustomElements/CustomPagination'
import { useConfirm } from 'material-ui-confirm'

const ProductsList = () => {
    const record = useRecordContext()
    const listContext = useList({ data: record.patientProducts })

    return (
        <Box
            style={{
                width: '100%',
                minHeight: '100px',
                display: 'grid',
            }}
        >
            <ListContextProvider value={listContext}>
                <CustomDataGrid
                    sx={{
                        '& .RaDatagrid-rowEven': {
                            backgroundColor: '#ffff',
                        },
                        '& .RaDatagrid-headerCell': {
                            fontWeight: 'bold',
                            textAlign: 'left',
                        },
                        '& .RaDatagrid-rowCell': {
                            textAlign: 'left',
                        },
                    }}
                >
                    <TextField source="name" label="Nazwa produktu" />
                    <TextField source="bloz" label="BLOZ" />
                    <TextField source="reference" label="SKU" />
                    <TextField source="ean" label="EAN" />
                    <TextField source="quantity" label="Ilość" />
                </CustomDataGrid>
            </ListContextProvider>
        </Box>
    )
}

const PatientsList = () => {
    const record = useRecordContext()
    const listContext = useList({ data: record.products })

    return (
        <Box
            style={{
                width: '100%',
                minHeight: '100px',
                display: 'grid',
            }}
        >
            <ListContextProvider value={listContext}>
                <CustomDataGrid
                    expand={<ProductsList />}
                    sx={{
                        '& .RaDatagrid-headerCell': {
                            fontWeight: 'bold',
                            textAlign: 'left',
                        },
                        '& .RaDatagrid-rowCell': {
                            textAlign: 'left',
                        },
                    }}
                >
                    <FunctionField
                        render={(record) =>
                            `${record.patient.name} ${
                                record.patient.secondName ? `${record.patient.secondName} ${record.patient.surname}` : record.patient.surname
                            } (ID ${record.patient.id})`
                        }
                        label="Pacjenci"
                    />
                </CustomDataGrid>
            </ListContextProvider>
        </Box>
    )
}

const DeleteBtn = () => {
    const record = useRecordContext()
    const notify = useNotify()
    const refresh = useRefresh()

    const [remove, { isLoading }] = useDelete(
        'order_templates',
        {
            id: record && record.id,
        },
        {
            onSuccess: (data) => {
                notify('Szablon został usunięty', { type: 'info' })
                refresh()
            },
            onError: (data) => {
                notify('Wystąpił błąd', { type: 'error' })
                refresh()
            },
        }
    )
    const handleDelete = (e) => {
        remove()
    }

    return (
        <IconButton disabled={isLoading} color="error" onClick={handleDelete}>
            <DeleteIcon />
        </IconButton>
    )
}

const OpenTemplateBtn = () => {
    const confirm = useConfirm()
    const record = useRecordContext()
    const redirect = useRedirect()
    const [dpsDetails, setDpsDetails] = useStore('order_dps_details', [])
    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', [])

    const handleTemplateOpen = () => {
        confirm({
            title: 'Uwaga!',
            description: 'Czy chcesz aby ustawienia z szablonu zostały użyte do Twojego obecnego zamówienia?',
            confirmationText: 'Przejdź dalej',
            confirmationButtonProps: {
                variant: 'outlined',
                color: 'success',
            },
            cancellationText: 'Anuluj',
            cancellationButtonProps: {
                variant: 'contained',
                color: 'primary',
            },
        })
            .then(() => {
                const newProductLines = []
                record.products?.map((x) => {
                    const patientProducts = [...x?.patientProducts]
                    newProductLines.push({ patient_id: x.patient.id, products: patientProducts })
                })
                setDpsDetails(newProductLines)
                setDpsPreferences({ department: record.departments, invoice: record.invoice, orderType: record.orderType, payment: record.payment })

                setTimeout(() => {
                    redirect('/departments/new_order')
                }, 100)
            })
            .catch(() => {
                return
            })
    }

    return (
        <Button onClick={handleTemplateOpen} variant="outlined" color="primary">
            Otwórz
        </Button>
    )
}

export default () => {
    const { data, isLoading } = useGetList('order_templates', {
        pagination: { page: 1, perPage: 10 },
    })
    const listContext = useListController({
        data,
        isLoading,
        resource: 'order_templates',
    })

    return (
        <Card>
            <Title title="Lista szablonów" />

            <ListContextProvider value={listContext}>
                <CustomDataGrid
                    empty={
                        <Typography p={2} variant="subtitle1">
                            Jeszcze nie dodano szablonów. Stwórz swój szablon zamówienia
                        </Typography>
                    }
                    expand={<PatientsList />}
                    expandSingle
                    sx={{
                        '& .MuiTableCell-root': {
                            paddingRight: 0,
                        },
                        '& .RaDatagrid-rowEven': {
                            backgroundColor: '#ffff',
                        },
                    }}
                >
                    <FunctionField source="id" render={(record) => record?.id?.split('/')?.[2]} label="Id" />
                    <DateField source="createdAt" label="Data utworzenia" />
                    <TextField source="name" label="Nazwa" />
                    <Box className="d-flex w-100 justify-content-evenly">
                        <DeleteBtn />
                        <OpenTemplateBtn />
                    </Box>
                </CustomDataGrid>
                <CustomPagination />
            </ListContextProvider>
        </Card>
    )
}
