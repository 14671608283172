import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReferenceInput, AutocompleteInput, Form } from 'react-admin'
import Button from '@mui/material/Button'
import authProvider from '../../config/authProvider'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'

const boxWithButtons = {
    padding: '25px',
    margin: '5px',
    spaceBeetwenn: '5px',
}

const InputsRow = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    marginLeft: '40px',
}
const inputButton = {
    marginBottom: '20px',
    marginLeft: '40px',
}
const mainHeader = {
    margin: '25px',
}

export default (props) => {
    const [orderData, setOrderData] = useState(null) //orders for pickup points
    const [invoiceData, setInvoiceData] = useState(null) // invoice for order id
    const [payerData, setPayerData] = useState(null) //payerBalance

    const [invoiceResponse, setInvoiceResponse] = useState()
    const [ordersResponse, setOrdersResponse] = useState()
    const [payerResponse, setPayerResponse] = useState()

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        box-sizing: border-box;
        width: 2000px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: #1f2021;
        background:   #F9F9F9;
        border: 1px solid  #d4731c;
        box-shadow: 0px 2px 2px  #ccc;
      `
    )

    const handleOrderChange = (event, newValue) => {
        let orderId = newValue?.id.split('/').pop()
        setInvoiceData(orderId)
    }

    const handlePayerChange = (event, newValue) => {
        let payerId = newValue?.id.split('/').pop()
        setPayerData(payerId)
    }

    const handlePickupPointChange = (event, newValue) => {
        let pickupPointId = newValue?.id.split('/').pop()
        setOrderData(pickupPointId)
    }

    const getInvoicesForOrder = async (orderId, integration = 'oracle_tzmo') => {
        try {
            const requestResult = await authProvider.invoiceForOrder(orderId, integration)

            const response = JSON.stringify(requestResult)
            setInvoiceResponse(response)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const getOrdersForPickupPoint = async (pickupPointId, integration = 'oracle_tzmo') => {
        try {
            const requestResult = await authProvider.ordersForPickupPoints(pickupPointId, integration)
            const response = JSON.stringify(requestResult)
            setOrdersResponse(response)
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const getPayerTest = async (payerId, integration) => {
        try {
            const requestResult = await authProvider.singlePayerBalanceTest(payerId, integration)

            const response = JSON.stringify(requestResult)
            setPayerResponse(response)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleButtonClick = () => {
        const integration = 'oracle_tzmo'
        getPayerTest(payerData, integration)
    }

    const handleButtonClick2 = () => {
        getInvoicesForOrder(invoiceData)
    }
    const handleButtonClick3 = () => {
        getOrdersForPickupPoint(orderData)
    }

    return (
        <Card>
            <CardContent>
                <Form>
                    <h1 style={mainHeader}>Oracle</h1>
                    <div style={InputsRow}>
                        <ReferenceInput source="payer" resource="payers" reference="payers" style={inputButton}>
                            <AutocompleteInput
                                label="Platnik"
                                variant="outlined"
                                resettable
                                optionText={(payer) => `(${payer.id.split('/').pop()}) ${payer.name}`}
                                onChange={handlePayerChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>

                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick}>
                            Pokaż bilans
                        </Button>
                    </div>

                    <div style={InputsRow}>
                        <ReferenceInput source="id" resource="orders" reference="orders">
                            <AutocompleteInput
                                label="Zamówienia"
                                variant="outlined"
                                resettable
                                optionText={(order) => `${order.id.split('/').pop()}`}
                                onChange={handleOrderChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>
                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick2}>
                            Pokaż faktury
                        </Button>
                    </div>

                    <div style={InputsRow}>
                        <ReferenceInput source="pickupPoint" resource="pickup_points" reference="pickup_points">
                            <AutocompleteInput
                                label="Punkt odbioru"
                                variant="outlined"
                                resettable
                                optionText={(pp) => `${pp.id.split('/').pop()}`}
                                onChange={handlePickupPointChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>
                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick3}>
                            Pokaż zamówienia
                        </Button>
                    </div>

                    <div style={boxWithButtons}>
                        <div style={{ border: '5px' }}>
                            {payerResponse && (
                                <div>
                                    <h3>Bilans</h3>
                                    <Textarea>{JSON.stringify(payerResponse)}</Textarea>
                                </div>
                            )}
                            {invoiceResponse && (
                                <div>
                                    <h3>Faktura:</h3>
                                    <Textarea wrap="soft">{JSON.stringify(invoiceResponse)}</Textarea>
                                </div>
                            )}

                            {ordersResponse && (
                                <div>
                                    <h3>Zamówienia:</h3>
                                    <Textarea wrap="soft">{JSON.stringify(ordersResponse)}</Textarea>
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </CardContent>
        </Card>
    )
}