import React from 'react'
import { Create } from 'react-admin'

import InsideForm from './InsideForm'

export default (props) => {
    return (
        <Create {...props} title={`Dodaj zgodę`}>
            <InsideForm {...props} />
        </Create>
    )
}
