import * as React from 'react'
import useStateRef from 'react-usestateref'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
    Modal,
    LinearProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title, useGetList, useStore, useDataProvider, useRedirect } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import OrderLines from './OrderLines'
import dpsProvider from '../../../config/dpsProvider'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

export default ({
    setSummaryPage,
    dpsPreferences,
    dpsDetails,
    refundRange,
    refundRangeOptions,
    getTitle,
    patients,
    getPatientIndex,
    PatientSummary,
    refundStatuses,
    summaryAll,
    forceUpdate,
    handleChange,
    Summary,
    expandAll,
    setDpsPreferences,
    setDpsDetails,
    setSummaryAll,
    setShouldSelectAll,
}) => {
    const confirm = useConfirm()
    const redirect = useRedirect()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [sending, setSending, sendingRef] = useStateRef(false)
    const [progressPopupShow, setProgressPopupShow] = React.useState(false)
    const [streamRealizationError, setStreamRealizationError, streamRealizationErrorRef] = useStateRef(false)
    const [progress, setProgress, progressRef] = useStateRef(0)
    const [patientsCount, setPatientsCount, patientsCountRef] = useStateRef(0)
    const [streamResult, setStreamResult, streamResultRef] = useStateRef('')

    const [uuid, setUuid, uuidRef] = useStateRef(null)

    React.useEffect(() => {
        setUuid(uuidv4())
    }, [])

    const submitOrder = () => {
        setSending(true)
        const orderLines = dpsDetails
            .filter(
                (oL) => oL?.selected && (dpsPreferences.orderType == 1 ? refundStatuses?.[oL?.patient_id]?.[dayjs().format('YYYY-MM')] == 'B' : true)
            )
            .map((_oL) => ({
                patientId: _oL.patient_id,
                isCash: _oL.isCash ?? false,
                products: _oL?.products?.map(({ description, categories, attributes, features, ...other }) => other),
                summary: {
                    summaryTotal: _oL.summaryTotal,
                    summaryDps: _oL.summaryDps,
                    summaryNfz: _oL.summaryNfz,
                    summaryPatient: _oL.summaryPatient,
                },
            }))
        const formData = new FormData()
        formData.append('orderPreferences', JSON.stringify({ ...dpsPreferences, refundRange }))
        formData.append('orderLines', JSON.stringify(orderLines))
        formData.append('orderSummary', JSON.stringify(summaryAll))

        if (dpsPreferences.orderType == 1) {
            setProgressPopupShow(true)
            setPatientsCount(orderLines?.length)
        }

        dpsProvider
            .submitOrder(formData, uuid)
            .then(async (response) => {
                return await listenForStream(response)
            })
            .then(() => {
                if (!streamRealizationErrorRef.current) {
                    confirm({
                        title: 'Zamówienie zostało pomyślnie złożono',
                        description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                        confirmationText: 'Przejdź na stronę główną',
                        confirmationButtonProps: {
                            variant: 'contained',
                            color: 'success',
                        },
                        allowClose: false,
                        hideCancelButton: true,
                    }).then(() => {
                        setDpsPreferences(false)
                        setDpsDetails([])
                        setSummaryAll({})
                        setShouldSelectAll(true)
                        redirect('/')
                    })
                }
            })
            .catch((resp) => {
                enqueueSnackbar(`Wystąpił błąd: ${resp.message}`, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    const listenForStream = async (response) => {
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let done = false

        const progressPart = 100 / patientsCountRef.current

        while (!done) {
            const { value, done: doneReading } = await reader.read()
            done = doneReading

            const streamData = dpsProvider.parseStreamOutput(decoder.decode(value, { stream: true }))

            if (streamData.event == 'realization') {
                setStreamResult(
                    `${streamResultRef.current}\nZlecenie ${patients.find((p) => p._id == streamData.id)?.refundDocument?.refundNumber}: ${
                        streamData.data.message
                    }`
                )

                if (streamData.data.isError == false) {
                    let newProgress = progressRef.current + progressPart
                    setProgress(newProgress < 100 ? newProgress : 100)
                } else {
                    setStreamRealizationError(true)
                    return
                }
            }

            if (streamData.event == 'cancel_realization') {
                setStreamResult(
                    `${streamResultRef.current}\nZlecenie ${patients.find((p) => p._id == streamData.id)?.refundDocument?.refundNumber}: ${
                        streamData.data.message
                    }`
                )
            }

            if (streamData.event == 'error') {
                throw new Error(streamData.data.errors)
            }
        }
    }

    const handleErrorDecision = (decision) => {
        setStreamRealizationError(false)
        dpsProvider
            .submitOrderDecision(decision, uuid)
            .then(async (response) => {
                return await listenForStream(response)
            })
            .then(() => {
                if (!streamRealizationErrorRef.current) {
                    confirm({
                        title: 'Zamówienie zostało pomyślnie złożono',
                        description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                        confirmationText: 'Przejdź na stronę główną',
                        confirmationButtonProps: {
                            variant: 'contained',
                            color: 'success',
                        },
                        allowClose: false,
                        hideCancelButton: true,
                    }).then(() => {
                        setDpsPreferences(false)
                        setDpsDetails([])
                        setSummaryAll({})
                        setShouldSelectAll(true)
                        redirect('/')
                    })
                }
            })
            .catch((resp) => {
                enqueueSnackbar(`Wystąpił błąd: ${resp.message}`, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    return (
        <>
            <Modal open={progressPopupShow}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 720,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Pobranie zleceń do realizacji
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Typography sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>{streamResultRef.current}</Typography>
                    {streamRealizationError && (
                        <Box>
                            <Typography color="#f44336" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}></Typography>
                            <Button sx={{ mb: 2 }} color="error" variant="outlined" onClick={() => handleErrorDecision('cancel')}>
                                Anuluj zamówienie i wycofaj zlecenia które już zostały pobrane do realizacji
                            </Button>
                            <Button color="info" variant="outlined" onClick={() => handleErrorDecision('continue')}>
                                Pomiń zlecenie z błędem i kontynuuj składanie zamówienia
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>

            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                            setSummaryPage(false)
                        }}
                    >
                        Wróć do edycji zamówienia
                    </Button>
                    <Typography variant="subtitle1" maxWidth={400}>
                        {dpsPreferences && getTitle(dpsPreferences)}
                    </Typography>
                    {dpsPreferences.orderType == 1 && (
                        <TextField
                            select
                            variant="outlined"
                            sx={{ minWidth: 200 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Okres:</InputAdornment>,
                            }}
                            value={refundRange}
                            disabled
                        >
                            {refundRangeOptions.map((o) => (
                                <MenuItem value={o.value}>{o.label}</MenuItem>
                            ))}
                        </TextField>
                    )}
                </Box>
                {patients && (
                    <Box sx={{ marginTop: 3 }}>
                        {patients?.map(
                            (patient) =>
                                dpsDetails.find((p) => p.patient_id == patient._id && p?.products?.length > 0) && (
                                    <Box sx={{ display: 'flex' }}>
                                        <Accordion
                                            key={patient.id}
                                            expanded={expandAll}
                                            onChange={handleChange(patient.id)}
                                            sx={{
                                                flex: 1,
                                                filter:
                                                    dpsPreferences.orderType == 1 &&
                                                    patient?.refundDocument &&
                                                    refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] != 'B'
                                                        ? 'brightness(95%)'
                                                        : 'unset',
                                            }}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <PatientSummary
                                                    patient={patient}
                                                    active={expandAll}
                                                    dpsDetails={dpsDetails}
                                                    _refundStatuses={refundStatuses}
                                                    dpsPreferences={dpsPreferences}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    <OrderLines
                                                        patient={patient}
                                                        forceUpdate={forceUpdate}
                                                        refundRange={refundRange}
                                                        editable={false}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '4px' }}>
                                            <Checkbox
                                                sx={{ alignSelf: 'center' }}
                                                disabled
                                                checked={dpsDetails[getPatientIndex(patient._id)]?.selected}
                                            />
                                        </Box>
                                    </Box>
                                )
                        )}
                    </Box>
                )}
                <Box sx={{ display: 'flex', margin: '30px 0' }}>
                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ flex: 1 }}>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Summary />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                            <Button disabled={sendingRef.current} onClick={() => submitOrder()} variant="contained" color="success">
                                Zrealizuj zamówienie
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
