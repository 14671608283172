import * as React from 'react'
import {
    Filter,
    List,
    TextField,
    FunctionField,
    TextInput,
    SelectInput,
    usePermissions,
    useRecordContext,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin'

import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'

import { CustomPagination } from '../CustomElements/CustomPagination'

const types = [
    { id: 1, name: 'aktywny' },
    { id: 0, name: 'nieaktywny' },
]

const payerTypes = {
    0: 'Brak',
    1: 'Płatnik',
    2: 'Instytucja',
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            empty={<EmptyList title="płatników" add={false} />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        'Payers',
                        'Create'
                    )}
                />
            }
            filters={[
                <TextInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="Nazwa"
                    source="name"
                />,
                <TextInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="NIP"
                    source="nip"
                />,
                PermissionHandler(permissions, 'Payers', 'Update') ? (
                    <SelectInput
                        variant="outlined"
                        resettable
                        label="Dostępność"
                        source="synchronize"
                        choices={types}
                        alwaysOn
                    />
                ) : (
                    <></>
                ),
                PermissionHandler(permissions, 'Payers', 'ReadByNetwork') ? (
                    <ReferenceInput
                        source='payerNetwork'
                        reference='payer_networks'
                        link={false}
                        className="autocompleteinput"
                        alwaysOn
                        allowEmpty
                    >
                        <AutocompleteInput
                            label="Płatnik sieciowy"
                            optionText= "name"
                            variant="outlined"
                            resettable
                            filterToQuery={(searchText)=>({
                                name: searchText
                            })}
                        />
                    </ReferenceInput>
                        
                ) : (
                    <></>
                ),
                <SelectInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="Typ"
                    source="payerType"
                    choices={Object.entries(payerTypes).map(
                        ([label, value]) => ({
                            id: label,
                            name: value,
                        })
                    )}
                />,
            ]}
            bulkActionButtons={false}
            title={`Płatnicy`}
        >
            <CustomDataGrid>
                <TextField source="clientNumber" label="Numer klienta" />
                <TextField source="name" label="Nazwa" />
                <TextField source="nip" label="NIP" />
                <TextField source="postCode" label="Kod pocztowy" />
                <TextField source="street" label="Ulica" />
                <TextField source="city" label="Miejscowość" />
                <TextField source="_voivodeship" label="Województwo" />

                <FunctionField
                    label="Siec"
                    render={(record) => {
                        if (!record.payerNetworkName) {
                            return 'nieaktywny'
                        } else {
                            return record.payerNetworkName
                        }
                    }}
                />
                <FunctionField
                    label="Partner"
                    render={(record) => {
                        if (!record.partnerName) {
                            return 'nieaktywny'
                        } else {
                            return record.partnerName
                        }
                    }}
                />

                <TextField
                    source="partnerNameOracle"
                    label="Nazwa partnera (Oracle)"
                />
                {PermissionHandler(permissions, 'Payers', 'Refresh') && (
                    <CustomActionsButtons
                    label="Limit"
                        refreshDebt={PermissionHandler(
                            permissions,
                            'Payers',
                            'Refresh'
                        )}
                    />
                )}

                <TextField source="type" label="Typ płatnika" />
                {PermissionHandler(permissions, 'Payers', 'Actions') && (
                    <CustomActionsButtons
                        extraEditAvailableFunctionDependsOnRecord={
                            PermissionHandler(
                                permissions,
                                'Payers',
                                'Update'
                            ) &&
                            (() => {
                                //const record = useRecordContext()
                                return true;//record?.partnerName ? false : true
                            })
                        }
                        refreshPP={PermissionHandler(
                            permissions,
                            'Payers',
                            'Refresh'
                        )}
                        refreshInvoicesPayer={PermissionHandler(
                            permissions,
                            'Payers',
                            'Refresh'
                        )}
                        redirectBtn={
                            PermissionHandler(
                                permissions,
                                'Institution',
                                'Read'
                            ) &&
                            (() => {
                                const record = useRecordContext()
                                return record?.type == payerTypes[2]
                                    ? {
                                          label: 'Szczegóły instytucji',
                                          href: `#/payers/${encodeURIComponent(
                                              record.id
                                          )}/institution`,
                                      }
                                    : false
                            })
                        }
                        label="Akcje"
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
