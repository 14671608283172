import * as React from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    email,
    TextInput,
    usePermissions,
} from 'react-admin'

import { CustomTitle } from '../CustomElements/CustomTitle'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import PermissionHandler from '../../config/PermissionHandler'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Edit
            {...props}
            actions={null}
            title={
                <CustomTitle
                    resourceName="Płatnik użytkonwik"
                    fields={['name', 'surname']}
                />
            }
        >
            <SimpleForm
                toolbar={
                    <PostBottomToolbarEdit
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'PayerUsers',
                            'Delete'
                        )}
                    />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            label="Imię"
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            label="Nazwisko"
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            label="E-mail"
                            variant="outlined"
                            source="email"
                            validate={isEmail}
                            fullWidth
                        />

                        <TextInput
                            label="Numer Telefonu"
                            variant="outlined"
                            source="phone"
                            fullWidth
                        />

                        <ReferenceInput
                            source="payer"
                            reference="payers"
                            validate={requiredField}
                            fullWidth
                        >
                            <AutocompleteInput
                                variant="outlined"
                                label="Płatnik"
                                optionText={'name'}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                    synchronize: true,
                                })}
                                validate={requiredField}
                            />
                        </ReferenceInput>

                        <BooleanInput
                            variant="outlined"
                            source="status"
                            label="Status"
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
