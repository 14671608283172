import * as React from 'react'
import {
    Create,
    required,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
    FormDataConsumer,
    useGetList,
    TextInput,
    usePermissions,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')
export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const {
        data: PriceData,
        loading: PriceDataLoading,
        error: PriceError,
    } = useGetList('price_lists', {
        pagination: { page: 1, perPage: 50 },
        sort: { field: 'id', order: 'ASC' },
    })

    const {
        data: RegulationData,
        loading: RegulationDataLoading,
        error: RegulationError,
    } = useGetList('regulations', {
        pagination: { page: 1, perPage: 50 },
        sort: { field: 'id', order: 'ASC' },
    })

    function forPartner(formData) {
        const options = PriceData.filter(
            (opt) => opt?.partnerId == formData?.partner.split('/').pop()
        )
        return options
    }

    function regulationForPartner(formData) {
        const options = RegulationData.filter(
            (opt) => opt?.partnerId == formData?.partner.split('/').pop()
        )
        return options
    }

    return (
        <Create {...props} actions={null} title={`Dodaj siec`}>
            <SimpleForm
                toolbar={
                    <PostBottomToolbarAdd saveRedirectPath="/payer_networks" />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            label="Nazwa sieci"
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />

                        <ReferenceInput
                            source="partner"
                            reference="partners"
                            className="autocompleteinput"
                            label=""
                            fullWidth
                            optionValue="id"
                        >
                            <AutocompleteInput
                                validate={requiredField}
                                label="Partner"
                                variant="outlined"
                                fullWidth
                                allowEmpty={true}
                                optionText={(record) =>
                                    `(ID:${record.id.split('/').pop()}) ${
                                        record.name
                                    } `
                                }
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                    id: searchText,
                                })}
                            />
                        </ReferenceInput>

                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.partner ? (
                                    <AutocompleteInput
                                        label="Cennik"
                                        variant="outlined"
                                        choices={forPartner(formData)}
                                        validate={requiredField}
                                        source="priceList"
                                        reference="priceLists"
                                        fullWidth
                                        optionText={(record) =>
                                            `(ID:${record?.id
                                                .split('/')
                                                .pop()}) ${record?.name}`
                                        }
                                    />
                                ) : (
                                    <AutocompleteInput
                                        label="Cennik"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        source="priceList1"
                                    />
                                )
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.partner && (
                                    <tr>
                                        <th className="col-md">
                                            <AutocompleteInput
                                                choices={regulationForPartner(
                                                    formData
                                                )}
                                                source="regulation"
                                                reference="regulations"
                                                variant="outlined"
                                                fullWidth
                                                label="Regulamin"
                                                allowEmpty
                                            />
                                        </th>
                                    </tr>
                                )
                            }
                        </FormDataConsumer>
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
