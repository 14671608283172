import React from 'react'

import Card from '@mui/material/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons'
const menuElementContainer = {
    display: 'flex',
    width: '90%',
    minWidth: 100,
    minHeight: 100,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}
const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-beetween',
    padding: '10px 10px',
    margin: '10px 10px',
    width: '30em',
}

const fontsStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}
const fontStyle = {
    textTransform: 'normal',
    fontSize: '1rem',
}
const textBox = {
    padding: '2%',
    margin: '0%',
}

function PriceRefundation() {
    return (
        <Card>
            <div style={textBox}>
                <p>Szanowni Państwo,</p>
                <p>
                    informujemy, że z dniem <strong>1 października 2024 r.</strong> zmienia się <strong>cennik</strong> refundacyjny Seni.
                </p>
                <p>Wprowadziliśmy następujące zmiany.</p>
                <p>
                    <strong>1. Dodano nowe produkty</strong>
                </p>
                <ul>
                    <li>
                        wkładki urologiczne <strong>Seni Lady Normal</strong> w klasycznej konstrukcji - cena refundacyjna wynosi 1 zł brutto za
                        sztukę, co stanowi równowartość limitu;
                    </li>
                    <li>
                        wkładki urologiczne <strong>Seni Lady Super Night</strong> przeznaczone na noc - cena refundacyjna wynosi 2,20 zł brutto za
                        sztukę;
                    </li>
                    <li>
                        <strong>Seni Super</strong> – pieluchomajtki na średnie i ciężkie nietrzymanie moczu. Zastąpią one obecnie funkcjonujące
                        pieluchomajtki Super Seni po wyczerpaniu zapasów (do tego czasu w cenniku obecne pozostają oba warianty). Pieluchomajtki Seni
                        Super mają tę samą chłonność oraz cenę jak Super Seni, natomiast mają nowe kody EAN i bloz;
                    </li>
                    <li>
                        majtki chłonne <strong>Seni Lady Pants</strong> i <strong>Seni Man Pants</strong> - dodano wariant 30 sztuk w opakowaniu.
                    </li>
                </ul>
                <p>
                    <strong>2. Obniżono ceny Seni Man Pants</strong> w rozmiarze M i L. Cena refundacyjna wyniesie brutto za sztukę:
                </p>
                <ul>
                    <li>2,70 zł dla rozmiaru M</li>
                    <li>3,05 zł dla rozmiaru L</li>
                </ul>

                <p>Ceny pozostałych wyrobów pozostają bez zmian.</p>
            </div>
            <div>
                <div class="row">
                    <div style={boxStyle}>
                        <a href={'/files/CENNIK_10.2024.pdf'} target="_blank" style={menuElementContainer} class="dash-card " download>
                            <FontAwesomeIcon icon={faFilePdf} size="lg" /> <span style={fontsStyle}>CENNIK SENI PAŹDZIERNIK 2024 PDF</span>
                        </a>
                    </div>
                </div>
            </div>
            <div style={textBox}>
                <p style={fontStyle}>
                    Udostępniamy Państwu również <strong>pliki CSV</strong> - wykaz wyrobów chłonnych marki Seni wraz z kodem środka pomocniczego,
                    który ułatwi kontraktację umowy z OW NFZ z zakresu zaopatrzenia w środki pomocnicze. Zamieszczony wykaz zawiera pełne nazwy
                    handlowe produktów, zgodne z dokumentami rejestracyjnymi. Wszystkie zawarte produkty spełniają wymogi aktualnie obowiązującego
                    Rozporządzenia MZ pod względem chłonności, aby móc je realizować w ramach zlecenia na zaopatrzenie w wyroby medyczne z
                    dofinansowaniem NFZ. Udostępnione pliki dedykowane są osobno <u>na SZOI</u> oraz <u>na Portal Świadczeniodawcy</u>.
                </p>
            </div>
            <div class="row">
                <div style={boxStyle}>
                    <a
                        href={'/files/CSV_PAZDIERNIK_2024_DLA_APT_I_SM_SZOI.csv'}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card "
                        download="CSV_PAZDZIERNIK_2024_DLA_APT_I_SM_SZOI.csv"
                    >
                        <FontAwesomeIcon icon={faFileCsv} size="lg" /> <span style={fontsStyle}>CSV PAŹDZIERNIK 2024 DLA APTEK I SM SZOI</span>
                    </a>
                </div>
                <div style={boxStyle}>
                    <a
                        href={'/files/CSV_PAZDZIERNIK_2024_DLA_APT_I_SM_PORTAL.csv'}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card "
                        download="CSV_PAZDZIERNIK_2024_DLA_APT_I_SM_PORTAL.csv"
                    >
                        <FontAwesomeIcon icon={faFileCsv} size="lg" /> <span style={fontsStyle}>CSV PAŹDZIERNIK 2024 DLA APTEK I SM PORTAL</span>
                    </a>
                </div>
            </div>
        </Card>
    )
}
export default PriceRefundation
