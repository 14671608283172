import * as React from 'react'
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    NumberInput,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create {...props} actions={null} title={`Dodaj próbkę`}>
            <SimpleForm
                toolbar={<PostBottomToolbarAdd saveRedirectPath="/samples" />}
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            label={'Nazwa'}
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            label={'SKU'}
                            source="sku"
                            validate={requiredField}
                            fullWidth
                        />

                        <NumberInput
                            variant="outlined"
                            label={'Maksymanlna ilość do zamówienia'}
                            source="maxOrder"
                            validate={requiredField}
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
