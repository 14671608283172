import * as React from 'react'
import {
    Datagrid,
    DateField,
    EditButton,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    usePermissions,
    useRecordContext,
    useRedirect,
    WithRecord
} from 'react-admin'

import { PostShowActions } from '../CustomElements/PostBottomToolbars'
import PermissionHandler from '../../config/PermissionHandler'
import Tooltip from '@mui/material/Tooltip'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { CustomActionButtonEdit as EditBtn } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { Button, Link } from '@mui/material'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const redirect = useRedirect()

    const IsEditable = (props) => {
        const record = useRecordContext()
        return (
            record?.category !== 'seni-quarter' &&
            record?.category !== 'seni-edu' && (
                <EditButton {...props} variant="outlined" color="info" />
            )
        )
    }

    return (
        <Show
            {...props}
            actions={<PostShowActions />}
            title={
                <CustomTitle
                    resourceName="Płatnik sieciowy"
                    fields={['name']}
                />
            }
        >
            <SimpleShowLayout>
                <TextField
                    variant="outlined"
                    source="name"
                    label="Nazwa sieci"
                />
                <TextField
                    variant="outlined"
                    source="partnerName"
                    label="Partner"
                />
                {PermissionHandler(
                    permissions,
                    'PayerNetworks',
                    'UpdatePriceList'
                ) && (
                    <ReferenceField
                        label="Cennik"
                        source="priceList[@id]"
                        reference="price_lists"
                        link={false}
                    >
                        <TextField variant="outlined" source="name" />
                    </ReferenceField>
                )}
                <ReferenceField
                    label="Regulamin"
                    source="regulation[@id]"
                    reference="regulations"
                    link={false}
                    emptyText='-'
                >
                    <TextField source="name"  />
                </ReferenceField>
                <EditBtn />
                <WithRecord
                    render={record =>
                        <>
                            <Button
                                color="primary"
                                component={Link}
                                onClick={() => {
                                    redirect(`/pickup_points?displayedFilters={}&filter={"payer"%3A{"payerNetwork"%3A"%2Fpayer_networks%2F${record.id.split('/')[2]}"}}&order=ASC&page=1&perPage=10&sort=id`)
                                }}
                            >
                                Punkty odbioru
                            </Button>
                            <Button
                                color="primary"
                                component={Link}
                                onClick={() => {
                                    console.log(record);
                                    redirect(`/payers?displayedFilters={}&filter={"payerNetwork"%3A"%2Fpayer_networks%2F${record.id.split('/')[2]}"}&order=ASC&page=1&perPage=10&sort=id`)
                                }}
                            >
                                Płatnicy
                            </Button>
                        </>
                    }
                />
                {PermissionHandler(permissions, 'PayerDiscounts', 'Update') && (
                    <ReferenceManyField
                        label="Rabaty"
                        target="payer_network"
                        reference="payer_discounts"
                        filter={() => {
                            const record = useRecordContext()
                            return { payerNetwork: record?.id?.split('/')?.[2] }
                        }}
                        // filter={{payer_network: props.id}}
                    >
                        <CustomDataGrid>
                            <TextField source="category" label="Marka" />
                            <TextField source="value" label="Wartość [%]" />
                            <DateField
                                source="updatedAt"
                                label="Aktualizowany"
                                emptyText="brak danych"
                            />
                            <IsEditable />
                        </CustomDataGrid>
                    </ReferenceManyField>
                )}
            </SimpleShowLayout>
        </Show>
    )
}
