import * as React from 'react'
import { Create, required, SimpleForm, TextInput, usePermissions, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
import { Divider, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useQueryClient } from 'react-query'

const requiredField = required('Pole wymagane')
const requiredIdentity = (value, allValues) => {
    if (!allValues.pesel && !allValues.otherIdentity) {
        return 'Brak numeru dokumentu'
    }

    if (allValues.pesel && !Number(allValues.pesel)) {
        return 'Numer PESEL powinien być liczbą'
    }

    return null
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <Create {...props} actions={null} title={`Dodaj pacjenta`}>
            <SimpleForm
                toolbar={
                    <PostBottomToolbarAdd
                        toEdit={true}
                        confirmModal={{ fields: ['status'], message: "Czy na pewno chcesz zapisać ze statusem 'nieaktywny'?" }}
                    />
                }
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput variant="outlined" label={'Imię'} source="name" validate={requiredField} fullWidth />
                        <TextInput variant="outlined" label={'Drugie imię'} source="secondName" fullWidth />
                        <TextInput variant="outlined" label={'Nazwisko'} source="surname" validate={requiredField} fullWidth />
                        <TextInput variant="outlined" source="pesel" label="Numer Pesel" fullWidth validate={requiredIdentity} />
                        <TextInput variant="outlined" source="otherIdentity" label="Inny dokument" fullWidth validate={requiredIdentity} />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <Typography variant="subtitle1" gutterBottom>
                            Dane dodatkowe jeśli adres inny niż placówki
                        </Typography>
                        <TextInput variant="outlined" label={'Ulica'} source="street" fullWidth />
                        <TextInput variant="outlined" label={'Numer'} source="number" fullWidth />
                        <TextInput variant="outlined" label={'Kod pocztowy'} source="postCode" fullWidth />
                        <TextInput variant="outlined" label={'Miejscowość'} source="city" fullWidth />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <ReferenceInput source="department" reference="departments">
                            <AutocompleteInput
                                optionText="name"
                                resettable
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                label="Oddział"
                                fullWidth
                                variant="outlined"
                                validate={requiredField}
                            />
                        </ReferenceInput>
                        <TextInput variant="outlined" label={'Numer pokoju'} source="room" fullWidth />
                        <BooleanInput source="status" label="Status" />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
