import * as React from 'react'
import {
    List,
    TextField,
    FunctionField,
    usePermissions,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanField,
    ReferenceArrayField,
} from 'react-admin'
import { Tooltip, Link } from '@mui/material'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import CustomTypeField from '../CustomFields/CustomTypeField'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        'Departments',
                        'Create'
                    )}
                />
            }
            title={`Pracownicy oddziałów`}
            empty={
                <EmptyList
                    title="pracowników oddziałów"
                    add={PermissionHandler(
                        permissions,
                        'Departments',
                        'Create'
                    )}
                />
            }
            filters={[
                <TextInput
                    alwaysOn
                    variant="outlined"
                    resettable
                    label="Adres e-mail"
                    source="user.email"
                />,
                <ReferenceInput
                    source="department"
                    reference="departments"
                    link={false}
                    alwaysOn
                >
                    <AutocompleteInput
                        label="Oddział"
                        optionText="name"
                        variant="outlined"
                        filterToQuery={(searchText) => ({
                            name: searchText,
                        })}
                        sx={{ minWidth: 300 }}
                        resettable
                        validate={null}
                    />
                </ReferenceInput>,
            ]}
        >
            <CustomDataGrid>
                <TextField source="name" label="Imię" />
                <TextField source="surname" label="Nazwisko" />
                <TextField source="email" label="Adres e-mail" />
                <ReferenceArrayField
                    reference="departments"
                    source="departments"
                    label="Oddziały"
                />
                <BooleanField
                    source="status"
                    label="Status"
                    valueLabelTrue="Aktywny"
                    valueLabelFalse="Nieaktywny"
                />
                <BooleanField
                    source="rewardAccess"
                    label="Odbiór nagród"
                    valueLabelTrue="Tak"
                    valueLabelFalse="Nie"
                />
                <CustomTypeField source="type" label="Typ" />
                {PermissionHandler(permissions, 'Other', 'CanLogin') && (
                    <FunctionField
                        label="Link logowania"
                        render={(record) => (
                            <Tooltip title="Kliknij żeby skopiować">
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        navigator.clipboard.writeText(
                                            record?.loginUrl
                                        )
                                    }}
                                    fontSize={10}
                                >
                                    {record?.loginUrl}
                                </Link>
                            </Tooltip>
                        )}
                    />
                )}
                {PermissionHandler(
                    permissions,
                    'DepartmentUsers',
                    'Update'
                ) && (
                    <CustomActionsButtons
                        label="Akcje"
                        isEditAvailable={PermissionHandler(
                            permissions,
                            'DepartmentUsers',
                            'Update'
                        )}
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'DepartmentUsers',
                            'Delete'
                        )}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
