import * as React from 'react'
import {
    Edit,
    SelectInput,
    required,
    SimpleForm,
    ReferenceInput,
    FormDataConsumer,
    AutocompleteInput,
    useGetList,
    TextInput,
    usePermissions,
    useRecordContext,
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')

const EditRenderer = (props) => {
    const record = useRecordContext()

    const {
        data: RegulationData,
        loading: RegulationDataLoading,
        error: RegulationError,
    } = useGetList('regulations', {
        pagination: { page: 1, perPage: 50 },
        sort: { field: 'id', order: 'ASC' },
    })

    function regulationForPartner(formData) {
        const options = RegulationData?.filter(
            (opt) => opt?.partner?.id == formData?.partnerId
        )
        return options
    }

    return (
        <SimpleForm
            {...props}
            toolbar={
                <PostBottomToolbarEdit
                    saveRedirectPath={`/payer_networks/${encodeURIComponent(
                        record?.id
                    )}/show`}
                    cancelRedirectPath={`/payer_networks/${encodeURIComponent(
                        record?.id
                    )}/show`}
                    isDeleteAvailable={false}
                />
            }
        >
            <ReferenceInput
                source="priceList[@id]"
                reference="price_lists"
                variant="outlined"
            >
                <SelectInput
                    label="Cennik"
                    optionText={(props) =>
                        `${props.name} (${props.partnerName})`
                    }
                    required
                    variant="outlined"
                />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.partner && (
                        <AutocompleteInput
                            choices={regulationForPartner(formData)}
                            source="regulation[@id]"
                            reference="regulations"
                            label="Regulamin"
                            variant="outlined"
                            allowEmpty
                        />
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    )
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const CustomTitle = (props) => {
        const record = useRecordContext()
        return record ? `Płatnik sieciowy ${record?.name}` : null
    }

    const transform = (data) => ({
        ...data,
        priceList: data?.priceList?.['@id'],
        partner: data?.partner?.['@id'],
        regulation: data?.regulation?.['@id']
      });

    return (
        <Edit {...props} actions={null} title={<CustomTitle />} transform={transform}>
            <EditRenderer />
        </Edit>
    )
}
