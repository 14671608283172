
import React, { useState } from "react";
import { Form, required, TextInput, usePermissions, PasswordInput, useLogout } from "react-admin";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslate, useSafeSetState } from "ra-core";
import { useSnackbar } from "notistack";
import { Card, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import authProvider from '../../config/authProvider'




const ResetPasswordForm = () => {
  const { permissions } = usePermissions()


  const [loading, setLoading] = useSafeSetState(false);
  const [passwdError, setPasswdError] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const logout = useLogout()


  const submit = (values) => {
    if (
      values.password == values.password2 &&
      values.password &&
      values.password.length > 3
    ) {
      setLoading(true);
      setPasswdError('')
      const password = values.password
      const password2 = values.password2
      authProvider.changePassword({ password, password2 }).then(
        (response) => {
          setLoading(false);
          enqueueSnackbar(response.message, {
            variant: 'info',
            autoHideDuration: 10000,
          })
          logout('', '/login', false).then(() => {
            enqueueSnackbar('Proszę zalogować się ponownie', {
              variant: 'warning',
              autoHideDuration: 10000,
            })
          })
        },
        (error) => {
          const resMessage =
            (error.response && error.response.message) ||
            error.message ||
            error.toString()
          enqueueSnackbar(resMessage, {
            variant: 'warning',
            autoHideDuration: 10000,
          })
        }
      )
      
    } else if (values.password !== values.password2) {
      setPasswdError('Hasła różnią się')
    } else if (values.password && values.password.length < 3) {
      setPasswdError('Długość hasła jest zbyt mała')
    } else if (!values.password && !values.password2) {
      setPasswdError('')
    }
  }

  return (
    <Form
      onSubmit={submit}
      defaultValues={{
        password: "",
        password2: "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Card
          sx={{
            padding: "1em",
            maxWidth: 450,
            borderRadius: "25px",
            boxShadow:
              "0 1.4px 10px rgba(0,0,0,.045), 0 11px 80px rgba(0,0,0,.09)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a href="#/login">
              <img src="logo.svg" alt="logo" style={{ height: 100 }} />
            </a>
          </Box>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#240a51",
            }}
          >
            Zresetuj hasło
          </span>
          <Box sx={{ padding: "0 1em 0 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <PasswordInput
                autoFocus
                source="password"
                label={'Nowe hasło'}
                disabled={loading}
                validate={required()}
                fullWidth={true}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box sx={{ padding: "0 1em 0 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <PasswordInput
                autoFocus
                source="password2"
                label={'Powtórz hasło'}
                disabled={loading}
                validate={required()}
                fullWidth={true}
                variant="outlined"
              />
            </Box>
          </Box>
          {passwdError != '' && (

            <span style={{ 'text-align': 'center' }} className="text-danger">
              {passwdError}
            </span>

          )}

          <CardActions sx={{ padding: "1em 1em 1em 1em" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ backgroundColor: "#240a51", color: "#ffffff" }}
              disabled={loading}
              fullWidth={true}
              className="login-btn"
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {"Zresetuj hasło"}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  )
}

export default ResetPasswordForm