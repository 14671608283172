import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { usePermissions } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import PermissionHandler from '../../config/PermissionHandler'

const fontStyle = {
    textTransform: 'normal',
    fontSize: '1.1rem',
}
const textBox = {
    padding: '2%',
    margin: '0%',
}
const menuElementContainer = {
    display: 'flex',
    width: '30%',
    minWidth: 100,
    minHeight: 100,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const styleFont = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

export default () => {
    const { permissions } = usePermissions()
    const pharmTrainings =
        'https://seni.pl/pl_PL/kategoria/szkolenia-online-dla-aptek-1'

    const medshopTrainings =
        'https://seni.pl/pl_PL/kategoria/szkolenia-online-dla-sklepow-medycznych-1'

    return (
        <Card>
            <CardContent>
                <div style={textBox}>
                    <h1>Szkolenia online dla aptek i sklepów medycznych</h1>
                    <p style={fontStyle}>
                        W ramach Akademii Seni proponujemy Państwu cykle
                        bezpłatnych szkoleń online, które umożliwią w sposób
                        profesjonalny poszerzenie wiedzy na temat nietrzymania
                        moczu, sprzedaży wyrobów chłonnych oraz kompleksowego
                        doradztwa pacjentowi w tym zakresie.
                    </p>
                    <p style={fontStyle}>Link do listy szkoleń:</p>
                    <div style={textBox}>
                        <div class="row">
                            {/* <p style={fontStyle}>Link do szkolenia:</p> */}
                            {PermissionHandler(
                                permissions,
                                'Trainings',
                                'Read'
                            ) && (
                                <a
                                    href={pharmTrainings}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={menuElementContainer}
                                    className="dash-card"
                                >
                                    <FontAwesomeIcon icon={faBook} inverse />
                                    <span style={styleFont}>
                                        Szkolenia dla aptek
                                    </span>
                                </a>
                            )}
                            {PermissionHandler(
                                permissions,
                                'Trainings',
                                'Read'
                            ) && (
                                <a
                                    href={medshopTrainings}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={menuElementContainer}
                                    className="dash-card"
                                >
                                    <FontAwesomeIcon icon={faBook} inverse />
                                    <span style={styleFont}>
                                        Szkolenia dla sklepów medycznych
                                    </span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
