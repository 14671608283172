import decodeJwt from 'jwt-decode'
import md5 from 'md5'

function createHeader() {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)
    headers.append('Accept', 'text/event-stream,application/json')

    return headers
}

async function submitOrder(formData, session) {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/dps/send-order?session=${session}`, {
        method: 'POST',
        headers: createHeader(),
        body: formData   
    })
    return await fetch(request)
}

async function submitOrderDecision(decision, session) {
  const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/dps/send-order?decision=${decision}&session=${session}`, {
      method: 'GET',
      headers: createHeader()
  })
  return await fetch(request)
}

async function submitSimpleOrder(formData) {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/dps/send-simple-order`, {
        method: 'POST',
        headers: createHeader(),
        body: formData,
    })
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json()
    })
}

function parseStreamOutput(output) {
    const lines = output.split('\n');  
    const parsedData = {};

    lines.forEach(line => {
      const [key, value] = line.split(': ', 2);
      if (key && value) {
        if (key === 'data') {
          try {
            parsedData[key] = JSON.parse(value);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            parsedData[key] = value;
          }
        } else {
          parsedData[key] = value;
        }
      }
    });
  
    return parsedData;
  }

export default {
    submitOrder,
    submitOrderDecision,
    submitSimpleOrder,
    parseStreamOutput
}
