import decodeJwt from 'jwt-decode'

export default {
    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/authentication_token`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token)

                localStorage.setItem('token', token)
                localStorage.setItem('permissions', decodedToken.roles)
            })
    },
    loginByHash: ({ mode, user_id, hash }) => {
        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_by_token`, {
            method: 'POST',
            body: JSON.stringify({
                user_id: user_id,
                hash: hash,
                mode: mode,
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    if (response.status === 409) {
                        throw new Error('Link wygasł')
                    }
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(({ token, username }) => {
                const decodedToken = decodeJwt(token)

                localStorage.setItem('reload', true)
                localStorage.setItem('token', token)
                localStorage.setItem('permissions', decodedToken.roles)
            })
    },
    logout: () => {
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        localStorage.removeItem('hideBell')
        localStorage.removeItem('partnerName')
        localStorage.removeItem('shopId')
        localStorage.removeItem('shopUrl')
        localStorage.removeItem('indOrds')
        return Promise.resolve()
    },
    getNotifications: () => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/notifications`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            return response.json()
        })
    },
    saveConsents: ({ consents }) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/save_consents`, {
            method: 'POST',
            body: JSON.stringify({ consents: consents }),
            headers: headers,
        })
        return fetch(request).then((response) => {
            // if (response.status < 200 || response.status >= 300) {
            //   throw new Error(response.statusText);
            // }
            return response.json()
        })
    },
    saveRegulations: ({ regulations }) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/save_regulations`, {
            method: 'POST',
            body: JSON.stringify({ regulations: regulations }),
            headers: headers,
        })
        return fetch(request).then((response) => {
            return response.json()
        })
    },
    changePassword: ({ password, password2 }) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/change_password`, {
            method: 'POST',
            body: JSON.stringify({
                password: password,
                password2: password2,
            }),
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            // https://stackoverflow.com/questions/53511974/javascript-fetch-failed-to-execute-json-on-response-body-stream-is-locked
            //console.log(response.json());
            //console.log(response.text());
            return response.json()
        })
        // .then(() => {
        //   return Promise.resolve();
        // })
    },
    changePhone: ({ phone }) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/change_phone`, {
            method: 'POST',
            body: JSON.stringify({
                phone: phone,
            }),
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    updatePartnerConstDiscount: ({ partnerConstDiscountId, value }) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/merge-patch+json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/${partnerConstDiscountId}`, {
            method: 'PATCH',
            body: JSON.stringify({
                value: value,
            }),
            headers: headers,
        })

        return fetch(request).then((response) => {
            console.log(response)
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            response.message = 'Rabat został zmieniony'
            return response
        })
    },
    checkError: (error) => {
        const status = error.status
        if (status === 401) {
            localStorage.removeItem('token')
            return Promise.reject()
        }
        return Promise.resolve()
    },
    checkAuth: () => {
        if (isPublicUrl(window.location.hash.split('?')[0])) {
            return Promise.resolve()
        }
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
    },
    hasRole: (roles) => {
        if (isPublicUrl(window.location.hash.split('?')[0])) {
            return true
        }
        const userroles = localStorage.getItem('permissions')
        return roles.some((role) => userroles && userroles.split(',').includes(role))
    },
    getPermissions: () => {
        if (isPublicUrl(window.location.hash.split('?')[0])) {
            return Promise.resolve()
        }

        const role = localStorage.getItem('permissions')
        return role ? Promise.resolve(role) : Promise.reject()
    },
    getRefundInfo: (pickupPointId, dateFrom = '', dateTo = '', lastThreeMonths = false, integration = 'oracle_tzmo') => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(
            `${process.env.REACT_APP_API_ENTRYPOINT}/ords/pickup_point_refund/${pickupPointId}?pDateFrom=${dateFrom}&pDateTo=${dateTo}&lastThreeMonths=${lastThreeMonths}&integration=${integration}`,
            {
                method: 'GET',
                headers: headers,
            }
        )
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    updatePickupPoints: (payerId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/ords/pickup_point/${payerId}?&integration=${integration}`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.status
        })
    },
    updateInvoice: (order) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const reactApiEntrypoint = process.env.REACT_APP_API_ENTRYPOINT
        const payerRequest = new Request(`${reactApiEntrypoint}/payers/${order.payer.split('/')[2]}`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(payerRequest)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((payer) => {
                const request = new Request(
                    `${reactApiEntrypoint}/ords/invoices/${order.id.split('/')[2]}?&integration=${payer.integrationCodeName}`,
                    {
                        method: 'GET',
                        headers: headers,
                    }
                )
                return fetch(request).then((res) => {
                    if (res.status < 200 || res.status >= 300) {
                        throw new Error(res.statusText)
                    }
                    return res.status
                })
            })
    },
    updatePayersInvoices: (payerId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/ords/payer_invoices/${payerId}?&integration=${integration}`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.status
        })
    },
    updatePayersDebt: (payerId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/ords/payers_balance/${payerId}?&integration=${integration}`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.status
        })
    },

    getMyIp: () => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_ip`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    getMaintaince: () => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        if (token) {
            headers.append('Authorization', 'Bearer ' + token)
        }
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_maintaince`, {
            method: 'GET',
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    setMaintaince: (ips, message, status) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/set_maintaince`, {
            method: 'POST',
            body: JSON.stringify({
                ip_list: ips,
                body: message,
                status: status,
            }),
            headers: headers,
        })
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    singlePayerBalanceTest: (payerId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        if (token) {
            headers.append('Authorization', 'Bearer ' + token)
        }
        headers.append('Content-Type', 'application/json')

        const request = new Request(
            `${process.env.REACT_APP_API_ENTRYPOINT}/ords/single_payer_balance?&payerId=${payerId}?&integration=${integration}`,
            {
                method: 'GET',
                headers: headers,
            }
        )
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    invoiceForOrder: (orderId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        if (token) {
            headers.append('Authorization', 'Bearer ' + token)
        }
        headers.append('Content-Type', 'application/json')

        const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/ords/invoices_for_order?orderId=${orderId}&integration=${integration}`, {
            method: 'GET',
            headers: headers,
        })

        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
    ordersForPickupPoints: (pickupPointId, integration) => {
        var token = localStorage.getItem('token')
        var headers = new Headers()
        if (token) {
            headers.append('Authorization', 'Bearer ' + token)
        }
        headers.append('Content-Type', 'application/json')

        const request = new Request(
            `${process.env.REACT_APP_API_ENTRYPOINT}/ords/orders_for_pickup_point?pickupPointId=${pickupPointId}&integration=${integration}`,
            {
                method: 'GET',
                headers: headers,
            }
        )
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
    },
}

function isPublicUrl(hash) {
    const publicUrls = [
        //"#/singin",
        '#/reset_password',
        '#/login_hash',
        //'#/login',
    ]
    return publicUrls.includes(hash) ? true : false
}
