import * as React from 'react'
import {
    List,
    TextField,
    FunctionField,
    ReferenceField,
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    usePermissions,
    BooleanField,
} from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomPagination } from '../CustomElements/CustomPagination'
import EmptyList from '../EmptyList'
import CustomActionBar from '../CustomElements/CustomActionBar'
import RefundScale from './partials/RefundScale'
import PermissionHandler from '../../config/PermissionHandler'
import ezwmProvider from '../../config/ezwmProvider'
import { LinearProgress } from '@mui/material'

const status = [
    { id: 1, name: 'aktywny' },
    { id: 0, name: 'nieaktywny' },
]

const RefundScaleWrapper = ({ record }) => {
    const [refundStatuses, setRefundStatuses] = React.useState(null)
    const [refundStatusesLoading, setRefundStatusesLoading] = React.useState(false)

    React.useEffect(() => {
        if (record?.refundDocument && !refundStatuses) {
            setRefundStatusesLoading(true)
            ezwmProvider
                .getRefundDocStatuses(record?.refundDocument?.refundNumber, record?.refundDocument?.pesel)
                .then((data) => setRefundStatuses(data))
                .finally(() => setRefundStatusesLoading(false))
        }
    }, [record?.refundDocument])

    return !refundStatusesLoading ? <RefundScale refundMonths={refundStatuses} /> : <LinearProgress />
}

export default () => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            title="Pacjenci"
            pagination={<CustomPagination />}
            empty={<EmptyList title="pacjentów" add={PermissionHandler(permissions, 'Patients', 'Create')} />}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'Patients', 'Create')} />}
            filters={[
                <TextInput variant="outlined" resettable label="Imię" source="name" alwaysOn />,
                <TextInput variant="outlined" resettable label="Nazwisko" source="surname" alwaysOn />,
                <ReferenceInput source="department" reference="departments" link={false} alwaysOn>
                    <AutocompleteInput
                        label="Oddział"
                        optionText="name"
                        variant="outlined"
                        filterToQuery={(searchText) => ({
                            name: searchText,
                        })}
                        sx={{ minWidth: 300 }}
                        resettable
                        validate={null}
                    />
                </ReferenceInput>,
                <SelectInput
                    label="Status dofinansowania"
                    source="refundStatus"
                    choices={status}
                    alwaysOn
                    variant="outlined"
                    sx={{ minWidth: 200 }}
                    resettable
                />,
            ]}
        >
            <CustomDataGrid rowClick="show">
                <FunctionField
                    render={(record) => `${record.name} ${record.secondName ?? ''} ${record.surname} (ID ${record._id})`}
                    label="Imię i Nazwisko (ID)"
                />
                <FunctionField render={(record) => <RefundScaleWrapper record={record} />} label="Dofinansowanie NFZ" />
                <TextField source="_department.name" label="Oddział" />
                <BooleanField source="status" label="Status" valueLabelTrue="Aktywny" valueLabelFalse="Nieaktywny" />
            </CustomDataGrid>
        </List>
    )
}
