import React from 'react'
import { BooleanInput, required, SimpleForm, TextInput } from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required()

export default (props) => {
    return (
        <SimpleForm {...props} toolbar={<PostBottomToolbarAdd />}>
            <div className="d-flex w-100 justify-content-around">
                <div class="col-xs-6">
                        <TextInput
                            source="name"
                            label="Nazwa"
                            variant="outlined"
                            fullWidth
                            validate={requiredField}
                        />
                        <TextInput
                            source="content"
                            multiline
                            fullWidth
                            validate={requiredField}
                        />
                        <BooleanInput
                            source="status"
                            label="Status"
                            variant="outlined"
                        />
                </div>
            </div>
        </SimpleForm>
    )
}
