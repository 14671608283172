import { useGetList } from 'react-admin'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Card } from '@mui/material'
import { Pagination } from 'swiper/modules'

const boxStyled = {
    width: 500,
    height: 300,
    padding: 5,
}
const imgBannerStyle = {
    maxWidth: 500,
    height: 300,
    padding: 5,
}
export default (props) => {
    const { data, total, isPending, error } = useGetList('banners', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'published_at', order: 'DESC' },
        filter: {
            type: 1,
            active: true,
            partner: '/partners/' + localStorage.getItem('partnerId'),
        },
    })
    //{images.map(img=><SwiperSlide>...</SwiperSlide>)}
    if (!data?.[0]?.images) {
        return null
    } else {
        return (
            <Card>
                <>
                    <Swiper
                        direction={'vertical'}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                        style={boxStyled}
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {data?.[0]?.images?.map((img) => (
                            <SwiperSlide>
                                <img
                                    src={img?.contentUrl}
                                    style={imgBannerStyle}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            </Card>
        )
    }
}
