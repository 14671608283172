import decodeJwt from 'jwt-decode'
import md5 from 'md5'

function createHeader() {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)

    return headers
}
/** size:
 * @note small = 125x125
 * @note medium = 250x250
 * @note big = 800x800
 */
function getImgUrl(idProduct, idProductAttribute, size = 'medium') {
    return `${process.env.REACT_APP_API_ENTRYPOINT}/presta-image/${size}/${idProduct}/${idProductAttribute}`
}

async function getRefundDoc(refundNumber, identity, patientId) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/ezwm/get-refund-doc?refundNumber=${refundNumber}&identity=${identity}&patientId=${patientId}`,
        {
            method: 'GET',
            headers: createHeader(),
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json()
    })
}

async function getRefundDocStatuses(refundNumber, identity) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/ezwm/get-refund-doc-statuses?refundNumber=${refundNumber}&identity=${identity}`,
        {
            method: 'GET',
            headers: createHeader(),
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json().then((statuses) => Object.entries(statuses).map(([key, value]) => ({ [key]: value })))
    })
}

async function getRefundDocsStatusesByPatients(patientIds) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/ezwm/get-refund-docs-statuses-by-patients?patientIds=${patientIds?.join(',')}`,
        {
            method: 'GET',
            headers: createHeader(),
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json()
    })
}

export default {
    getRefundDoc,
    getRefundDocStatuses,
    getRefundDocsStatusesByPatients,
    getImgUrl,
}
