import React from 'react'

import { Box, Button, Typography, Divider } from '@mui/material'
import { useStore, useRedirect, useDataProvider, ShowBase, SimpleShowLayout, TextField } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import useStateRef from 'react-usestateref'

import ProductSearchSimple from './ProductSearchSimple'
import OrderLinesSimple from './OrderLinesSimple'
import dpsProvider from '../../../../config/dpsProvider'



const checkoutStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const currencyFormatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
})

export default ({ forceUpdate }) => {
    const dataProvider = useDataProvider()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const redirect = useRedirect()
    const confirm = useConfirm()

    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', false)
    const [orderSimpleDetails, setOrderSimpleDetails] = useStore('order_simple_dps_details', {})
    const [singleDepartment, setSingleDepartment] = React.useState(null)
    const [summaryPage, setSummaryPage] = React.useState(false)
    const [sending, setSending, sendingRef] = useStateRef(false)

    React.useEffect(() => {
        dataProvider
            .getOne('departments', {
                id: dpsPreferences.department,
            })
            .then((r) => setSingleDepartment(r.data))
    }, [dpsPreferences])

    React.useEffect(() => {
        updateSummaries(orderSimpleDetails, setOrderSimpleDetails, dpsPreferences)
        forceUpdate()
    }, [JSON.stringify(orderSimpleDetails), JSON.stringify(dpsPreferences)])

    const updateSummaries = (orderDetails, setOrderDetails) => {
        const getNormalPrice = (row) => {
            return parseFloat(row?.dpsPrice ?? row?.price) || 0
        }

        let _total = orderDetails?.products?.reduce(
            (accumulator, currentValue) => accumulator + getNormalPrice(currentValue) * currentValue.quantity,
            0
        )

        if (orderDetails?.products?.length > 0) {
            orderDetails.summary = _total
            setOrderDetails(orderDetails)
        }
    }

    const canSubmitOrder = () => {
        return orderSimpleDetails?.products?.length > 0
    }

    const goToSummary = () => {
        if (!canSubmitOrder()) {
            enqueueSnackbar('Brak wybranych produktów', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            return
        }

        setSummaryPage(true)
    }

    const Summary = () => {
        return (
            <>
                <Box sx={checkoutStyles}>
                    <Typography>Wartość zamówienia</Typography>
                    <Typography>{currencyFormatter.format(orderSimpleDetails?.summary ?? 0)}</Typography>
                </Box>
            </>
        )
    }

    const submitOrder = () => {
        setSending(true)
        const orderLines = orderSimpleDetails.products?.map(({ description, categories, attributes, features, ...other }) => other)
        const formData = new FormData()
        formData.append('orderPreferences', JSON.stringify({ ...dpsPreferences }))
        formData.append('orderLines', JSON.stringify(orderLines))

        dpsProvider
            .submitSimpleOrder(formData)
            .then((resp) => {
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                    confirmationText: 'Przejdź na stronę główną',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    setDpsPreferences(false)
                    setOrderSimpleDetails({})
                    redirect('/')
                })
            })
            .catch((resp) => {
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    return (
        singleDepartment && (
            <Box sx={{ margin: '20px 0' }}>
                <Box sx={{ margin: '10px 0' }}>
                    <ShowBase resource="pickup_points" id={singleDepartment?.pickupPoint}>
                        <SimpleShowLayout>
                            <TextField source="payerName" label="Instytucja" />
                            <TextField source="internalName" label="Oddział" />
                        </SimpleShowLayout>
                    </ShowBase>
                </Box>
                <Box sx={{ margin: '10px 0' }}>
                    <OrderLinesSimple forceUpdate={forceUpdate} editable={!summaryPage} />
                </Box>
                {!summaryPage && (
                    <Box>
                        <ProductSearchSimple forceUpdate={forceUpdate} department={singleDepartment} />
                    </Box>
                )}
                <Box sx={{ display: 'flex', margin: '30px 0' }}>
                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ flex: 1 }}>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Summary />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                            {!summaryPage ? (
                                <Button
                                    disabled={!canSubmitOrder(true) || sendingRef.current}
                                    onClick={() => goToSummary()}
                                    variant="contained"
                                    color="success"
                                >
                                    Przejdź do realizacji zamówienia
                                </Button>
                            ) : (
                                <Button disabled={sendingRef.current} onClick={() => submitOrder()} variant="contained" color="success">
                                    Zrealizuj zamówienie
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    )
}
