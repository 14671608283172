import * as React from 'react'
import {
    FunctionField,
    List,
    usePermissions,
    BooleanField,
    useDataProvider,
    ImageField,
    ReferenceField,
    AutocompleteField,
    DateTimeField,
    ReferenceArrayField,
    ReferenceManyField,
    WithListContext,
    DateField,
} from 'react-admin'

import CustomBooleanField from '../CustomFields/CustomBooleanField'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
import CustomActionBar from '../CustomElements/CustomActionBar'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import EmptyList from '../EmptyList'
import { CustomPagination } from '../CustomElements/CustomPagination'
import { useLocation } from 'react-router'

import PermissionHandler from '../../config/PermissionHandler'
//import { DateTimeField } from '@mui/x-date-pickers'

const miniMedia = {
    maxWidth: '300px',
    margin: '10px',
    width: 400,
}
export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const dataprovider = useDataProvider()
    const location = useLocation()
    React.useEffect(() => {}, [])
    console.log(props)
    return (
        <>
            <List
                {...props}
                pagination={<CustomPagination />}
                empty={<EmptyList title="Banery" />}
                actions={<CustomActionBar />}
                title={`Banery`}
            >
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        'Banners',
                        'Create'
                    )}
                />
                <CustomDataGrid>
                    <FunctionField
                        label="ID"
                        source="id"
                        render={(record) => `${record.id.split('/')[2]}`}
                    />

                    <FunctionField
                        label="Nazwa"
                        render={({ images }) =>
                            images.map((media) => <p>{media.filePath}</p>)
                        }
                    />

                    <FunctionField
                        label="Zdjęcie"
                        render={({ images }) => (
                            <div src={images.contentUrl}>
                                {images.map((media) => (
                                    <img
                                        style={miniMedia}
                                        src={media.contentUrl}
                                        key={media.id}
                                    />
                                ))}
                            </div>
                        )}
                    />

                    <FunctionField
                        label="Partner"
                        render={({ partner }) => (
                            <strong> {partner?.name}</strong>
                        )}
                    />

                    <BooleanField
                        source="active"
                        label="Aktywny"
                        valueLabelTrue="aktywny"
                        valueLabelFalse="nieaktywny"
                    />

                    <DateField
                        source="created_at"
                        label="Czas stworzenia"
                        showTime="true"
                        locales="pl-PL"
                    />

                    <CustomActionsButtons
                        label="Akcje"
                        isEditAvailable={PermissionHandler(
                            permissions,
                            'Banners',
                            'Update'
                        )}
                        isDeleteAvailable={PermissionHandler(
                            permissions,
                            'Banners',
                            'Delete'
                        )}
                    />
                </CustomDataGrid>
            </List>
        </>
    )
}
