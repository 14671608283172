import { HttpError } from 'react-admin';
import {
  fetchJsonLd,
  getDocumentationUrlFromHeaders,
} from '@api-platform/api-doc-parser';
import jsonld from 'jsonld';


// THIS FILE IS COPY FROM node_modules/@api-platform/admin/src/hydra/fetchHydra.ts AND HAVE ONE CHANGE

/**
 * Sends HTTP requests to a Hydra API.
 */
function customFetchHydra(
  url,
  options = {},
) {
  let requestHeaders = options.headers ?? new Headers();

  if (
    typeof requestHeaders !== 'function' &&
    options.user &&
    options.user.authenticated &&
    options.user.token
  ) {
    requestHeaders = new Headers(requestHeaders);
    requestHeaders.set('Authorization', options.user.token);
  }

  const authOptions = { ...options, headers: requestHeaders };

  return fetchJsonLd(url.href, authOptions).then((data) => {
    const { status, statusText, headers } = data.response;
    const body = 'body' in data ? data.body : undefined;

    if (status < 200 || status >= 300) {
      if (!body) {
        return Promise.reject(new HttpError(statusText, status));
      }

      delete (body).trace;

      const documentLoader = (input) => {
        const loaderOptions = authOptions;
        loaderOptions.method = 'GET';
        delete loaderOptions.body;

        return fetchJsonLd(input, loaderOptions).then((response) => {
          if (!('body' in response)) {
            throw new Error(
              'An empty response was received when expanding JSON-LD error document.',
            );
          }
          return response;
        });
      };

      return documentLoader(getDocumentationUrlFromHeaders(headers))
        .then((response) =>
          jsonld.expand(body, {
            expandContext: response.document,
          }),
        )

        .then((json) =>
          Promise.reject(
            new HttpError(
              (
                json[0][
                  'http://www.w3.org/ns/hydra/core#description'
                ]
              )?.[0]?.['@value'],
              status,
              json,
            ),
          ),
        )
        .catch((e) => {
          if ('body' in e) {
            return Promise.reject(e);
          }
// THIS IS THE CHANGE (data.document['hydra:description'])    
          return Promise.reject(new HttpError(data.document['hydra:description'], status));
        });
    }

    if (Array.isArray(body)) {
      return Promise.reject(
        new Error('Hydra response should not be an array.'),
      );
    }
    if (body && !('@id' in body)) {
      return Promise.reject(
        new Error('Hydra response needs to have an @id member.'),
      );
    }

    return {
      status,
      headers,
      json: body,
    };
  });
}

export default customFetchHydra;
