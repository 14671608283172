import * as React from 'react'
import { Create, SimpleForm, ImageField, ImageInput } from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

export default (props) => {
    return (
        <Create {...props} actions={null} title={'Dodaj obiekt multimedialny'}>
            <SimpleForm
                toolbar={
                    <PostBottomToolbarAdd saveRedirectPath="/media_objects" />
                }
            >
                <ImageInput source="file" label="Zdjęcia">
                    <ImageField source="src" label="Zdjęcia" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
