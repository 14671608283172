import React from 'react'
import { useState } from 'react'

import Card from '@mui/material/Card'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import FormLayout from './FormLayout'
//const { useState } = React;

const headText = {
    padding: '2%',
    color: '#de9a33',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '2em',
}

const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-beetween',
    padding: '20px 20px',
    margin: '20px 20px',
    width: '100em',
}

const menuElementContainer = {
    display: 'flex',
    width: '90%',
    minWidth: 100,
    minHeight: 50,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const fontsStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

export default () => {
    // console.log(props);
    //const [formData, setFormData] = useState();

    let linkRabaty = `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/statusactiverabat`
    let linkTimeCompleteOrder = `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/orderst`
    let linkRaportLog = `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/minimumnet`
    let linkSample = `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/sample`
    let linkCommerce = `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/ecommerceall`
    return (
        <Card>
            <h5 style={headText}>Pobierz raporty </h5>

            <div class="row row-cols-1 row-cols-md-2 g-6" style={boxStyle}>
                <div>
                    <div class="col">
                        <a
                            href={linkRabaty}
                            target="_blank"
                            style={menuElementContainer}
                            class="dash-card centred"
                        >
                            <span style={fontsStyle}> Rabaty </span>
                        </a>
                    </div>
                </div>

                <div class="col">
                    <a
                        href={linkTimeCompleteOrder}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}>
                            {' '}
                            Czas kompletacji zamówienia - budowa koszyka{' '}
                        </span>
                    </a>
                </div>

                <div class="col">
                    <a
                        href={linkRaportLog}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}>
                            Raport logistyczny - 650 zł
                        </span>
                    </a>
                </div>

                <div class="col">
                    <a
                        href={linkSample}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}>
                            Raport pobrań próbek na aptekę
                        </span>
                    </a>
                </div>

                <div class="col">
                    <a
                        href={linkCommerce}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}> E-commerce kwartalny</span>
                    </a>
                </div>

                <div class="col">
                    <Link
                        to="/form-raports/2"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}> Logowania do sklepu</span>
                    </Link>
                </div>

                <div class="col">
                    <Link
                        to="/form-raports/1"
                        style={menuElementContainer}
                        class="dash-card centred"
                    >
                        <span style={fontsStyle}>Raport z szkoleń</span>
                    </Link>
                </div>
            </div>
        </Card>
    )
}
