import * as React from 'react'
import { List, TextField, Datagrid, usePermissions, TextInput, ReferenceInput, AutocompleteInput, BooleanField } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const [payer, setPayer] = React.useState(null)

    React.useState(() => {
        const payerId = window.location.hash?.split('?payer=')?.[1]
        if (payerId) {
            setPayer(payerId)
        }
    })

    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            filter={payer ? { 'pickupPoint.payer': `${payer}` } : null}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'Departments', 'Create')} />}
            title={`Oddziały`}
            empty={<EmptyList title="oddziałów" add={PermissionHandler(permissions, 'Departments', 'Create')} />}
            filters={[
                <TextInput alwaysOn variant="outlined" resettable label="Nazwa" source="name" />,
                <ReferenceInput source="pickupPoint" reference="pickup_points" link={false} alwaysOn>
                    <AutocompleteInput
                        label="Punkt odbioru"
                        optionText="internalName"
                        variant="outlined"
                        filterToQuery={(searchText) => ({
                            internalName: searchText,
                        })}
                        sx={{ minWidth: 300 }}
                        resettable
                        validate={null}
                    />
                </ReferenceInput>,
            ]}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa oddziału" />
                <TextField source="pickupPointName" label="Powiązany punkt odbioru" />
                <BooleanField source="status" label="Status" valueLabelTrue="Aktywny" valueLabelFalse="Nieaktywny" />
                <BooleanField source="refundation" label="Dopłata DPS" valueLabelTrue="Tak" valueLabelFalse="Nie" />
                <TextField source="points" label="Punkty" />
                <TextField source="address" label="Adres" />
                {PermissionHandler(permissions, 'Departments', 'Update') && (
                    <CustomActionsButtons label="Akcje" isEditAvailable={true} />
                )}
            </CustomDataGrid>
        </List>
    )
}
