import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import useState from "react-usestateref";

const Product = (props) => {
  const [choosedProductId, setChoosedProductId] = useState(null);
  const [cost, setCost, costRef] = useState(0);
  const [szt, setSzt, sztRef] = useState(0);
  const [sztStr, setSztStr, sztStrRef] = useState('0');
  const [wartosc, setWartosc] = useState(0);
  const [type, setType, typeRef] = useState(0);

  let Id = props.productId;

  const handleProductChange = (e, v) => {
    let product = v;
    //let tmp = props.ProductsInfo.find((el) => el["id"] == e.target.value);

    let tmpRefundLimit = 0;
    if (!product) product = 0;
    else {
      try {
        tmpRefundLimit = parseFloat(product?.refundLimit?.replace(",", "."));
        product = parseFloat(product?.priceGross?.replace(",", "."));
      } catch (error) {
        product = 0;
      }
    }

    let tmpFullCost = sztRef.current * product;
    setChoosedProductId(e.target.value);
    setCost(product);
    setWartosc(tmpFullCost);
    setType(tmpRefundLimit);

    props.UpdateSum(
      props.productId,
      sztRef.current,
      tmpFullCost,
      tmpRefundLimit,
      costRef.current,
      v
    );
  };

  const handleCountChange = (e) => {
    let val = parseInt(e.target.value);
    let szt = parseInt(sztRef.current);
    

    if(!val || val<0){
      val=0;
    }

    if (!szt) {
      szt = 0;
    }

    let delta = szt - val;

    if (props.quantityLimit + delta < 0 && props.orderType == 1) {
      val = parseInt(szt);
    }

    let tmpFullCost = val * costRef.current;
    setSztStr(val.toString());
    setSzt(val);
    setWartosc(tmpFullCost);
    props.UpdateSum(
      props.productId,
      val,
      tmpFullCost,
      typeRef.current,
      costRef.current
    );
  };


  return (
    <div id={Id}>
      <div className="row align-items-center">
        <div className="col-6 col-md-5 form-group">
          <Autocomplete
            options={props?.ProductsInfo}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Wyszukaj produkt"
                variant="outlined"
              />
            )}
            getOptionLabel={(option) =>
              `${props.age == 2 ? option.nfzCodeChild : option.nfzCodeAdult} - ${option.name} ${option.size}`
            }
            onChange={(e, v) => handleProductChange(e, v)}
          />
        </div>

        <div className="col-6 col-md-2 form-group">
          <input
            type="text"
            className="form-control-plaintext"
            readonly
            value={cost != null ? parseFloat(cost).toFixed(2) + " zł" : null}
          />
        </div>

        <div className="col-6 col-md-2 form-group d-flex align-items-center">
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            className="d-inline"
            onChange={(e) => handleCountChange(e)}
           // onBlur={handleBlur}
            min={0}
            type="number"
            value={sztStrRef.current}
            variant="outlined"
            style={{ width: 100, marginRight: 20 }}
          />
          <div className="d-inline ml-1">szt.</div>
        </div>
        <div className="col-6 col-md-2 form-group">
          <span>{wartosc ? parseFloat(wartosc).toFixed(2) + " zł" : null}</span>
        </div>
        <div className="col-12 col-md-1 form-group">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              let isRemoved = props.RemoveFunc(props.keyProduct);
              if (isRemoved) props.UpdateSum(props.productId, 0, 0);
            }}
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
