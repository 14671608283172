import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardContent, CardActionArea, Typography, Box } from '@mui/material'

const RaportType = ({ title, icon }) => {
    const [style, setStyle] = React.useState({ color: '#e18a3d', background: '#ffffff' })

    return (
        <Card sx={{ width: 300, minHeight: 100, ...style }}>
            <CardActionArea
                sx={{ height: '100%' }}
                onClick={() => {}}
                onMouseEnter={() => setStyle({ color: '#ffffff', background: '#e18a3d' })}
                onMouseLeave={() => setStyle({ color: '#e18a3d', background: '#ffffff' })}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    {icon && <FontAwesomeIcon icon={icon} size="3x" />}
                    <Typography variant="h6" textAlign="center" display="flex" flexDirection="column">
                        {title}
                        <hr
                            style={{
                                width: '40%',
                                alignSelf: 'center',
                                opacity: 1,
                                height: '5px',
                                border: 'none',
                                borderRadius: '5px',
                                background: style.color,
                            }}
                        />
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default () => {
    return (
        <Card>
            <CardContent>
                <Box sx={{display:'flex', justifyContent:'space-evenly' }}>
                    <RaportType title="Zamówienia na pacjenta" />
                    <RaportType title="Zamówienia na instatucję/oddział" />
                    <RaportType title="Realizacja punktów i historia transakcji" />
                    <RaportType title="Raport ważności zleceń" />
                </Box>
            </CardContent>
        </Card>
    )
}
