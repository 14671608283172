import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Link, Modal } from "@mui/material"
import { useState } from "react"
import Contact from "./Contact";
import {
    faX
} from '@fortawesome/free-solid-svg-icons'

const ContactFormModal = (props) =>{

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '1200px',
        minHeight: '600px',
        border: 'none'
    };

    const [open,setOpen] = useState(false)

    const handleOpen = () => [
        setOpen(true)
    ]
    return  <>
                <Link
                    type="button"
                    style={props.menuElementContainer}
                    className="dash-card"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon
                        icon={props.icon}
                        inverse
                    />
                    <span style={props.fontStyle}>
                        Formularz kontaktowy
                    </span>
                </Link>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-describedby="child-modal-description"
                >
                    <Card style={style}>
                        <div id="child-modal-description">
                        {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. */}
                        <div className="d-flex d-flex justify-content-end m-2">
                            <FontAwesomeIcon role="button" icon={faX} onClick={()=>setOpen(false)}/>
                        </div>
                        <Contact/>
                        </div>
                    </Card>
                </Modal>
    </>
}

export default ContactFormModal