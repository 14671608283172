import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useInput } from 'react-admin';

//import "./CustomImagePlugin";

const TinyMCEInput = ({ source, label, ...props }) => {
  const { 
    field : {value, onChange}  } = useInput({ 
    source,
  });
  const [initialValue] = useState(value || props.defaultValue);
  

  return (
    <>
      <div>
        <Editor
            apiKey='6opw8w5zbugd1523uz5wbgf0mmletiawv5b2i6p5n4tw6szf'
          init={{
            id: props.id,
            height: props.height ? props.height : 500,
            menubar: props.menubar !== undefined ? props.menubar : true,
            external_plugins: {
              "image-plugin": "/admin/image-plugin/plugin.js",
              "gallery-plugin": "/admin/gallery-plugin/plugin.js",
            },
            relative_urls: false,
            content_css: [
              "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css",
              "/css/styles.css",
            ],
            remove_script_host: true,
            plugins: [
              'advlist','autolink','lists','link','image','charmap','preview',
              'anchor','searchreplace','visualblocks','code','fullscreen',
              'insertdatetime','media','table','help','wordcount','template',
              //'print','paste'
           ],
            toolbar: 'undo redo | formatselect | casechange blocks | bold italic backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help' +
            'template | image-plugin | gallery-plugin',
            templates: [
              {
                title: "Box produktowy",
                description: "Dodanie boksu z produktem",
                content:
                  ' <div className="d-inline-flex flex-column radius-3 shadow-blue bg-white p-4 col-12 col-md-3 tiny-prod-box">\n' +
                  "\n" +
                  '                    <div className="position-relative text-center pb-3">\n' +
                  '                        <a href="#" title=""><img src="https://senicare.a100.com.pl/images/doc-placeholder.jpg" alt="" className="img-fluid shadow-blue radius-3 doc-list-photo "/></a>\n' +
                  "                    </div>\n" +
                  "\n" +
                  '                    <h5 className="mb-3 text-dark doc-cat-heading fw-normal">\n' +
                  '                        <span className="bg-white pe-2 d-inline-block position-relative">Nazwa produktu</span>\n' +
                  "                    </h5>\n" +
                  '                    <div className="fs-7 mb-4">Interdum et malesuada fames ac ante ipsum primis in faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque commodo sagittis auctor.</div>\n' +
                  '                    <a href="#" title="więcej" className="py-3 px-4 text-light d-inline-block rounded-pill bg-green text-center btn-small mt-auto w-100 text-decoration-none">zobacz produkt</a>\n' +
                  "                </div>",
              },
            ],
          }}
          initialValue={initialValue}
          onEditorChange={(content) => onChange(content)}//props.onChange(content)}//props.change(content)}
        />
      </div>
    </>
  );
};

export default TinyMCEInput;
