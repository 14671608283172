import * as React from 'react'
import { List, TextField, ReferenceField,usePermissions } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'
import CustomBooleanField from '../CustomFields/CustomBooleanField'
import { CustomPagination } from '../CustomElements/CustomPagination'

const ModuleName = 'PartnerEmails'

export default () => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            empty={<EmptyList title="szablonów e-mail" add={true} />}
            actions={
                <CustomActionBar
                    isAddVisible={PermissionHandler(
                        permissions,
                        ModuleName,
                        'Create'
                    )}
                />
            }
            bulkActionButtons={false}
            title={`Szablony e-mail`}
            pagination={<CustomPagination />}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa szablonu" />
                <TextField source="subject" label="Temat" />
                <ReferenceField source="partner" reference="partners">
                    <TextField source="name" label="Partner" />
                </ReferenceField>

                {PermissionHandler(permissions, ModuleName, 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isEditAvailable={true}
                        isDeleteAvailable={true}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
