import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import UploadDocument from './UploadDocument'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

import {
    List,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    Filter,
    DateInput,
    SelectInput,
} from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import EmptyList from '../EmptyList'

const Documents = () => {
    const [show, setShow] = useState(false)
    const [hover, setHover] = useState(false)

    const showModal = () => {
        setShow(true)
    }

    const hideModal = () => {
        setShow(false)
    }

    const menuElementContainer = {
        display: 'flex',
        width: '100%',
        minWidth: 250,
        minHeight: 200,
        maxHeight: 100,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2em',
        textDecoration: 'none',
        flexDirection: 'column',
        cursor: 'pointer',
    }

    const fontStyle = {
        padding: '2%',
        color: '#d4731c',
        textTransform: 'normal',
        textAlign: 'center',
        fontSize: '1.25rem',
    }

    const headerFontStyle = {
        color: '#d4731c',
        textTransform: 'normal',
        textAlign: 'center',
        fontSize: '2rem',
    }

    const getDocType = (type) => {
        switch (type) {
            case 2:
                return 'Zestawienie wydanych środków pomocniczych'
            case 3:
                return 'Nota księgowa'
            case 4:
                return 'Zestawienie + nota księgowa'
            default:
                return 'Nieznany typ'
        }
    }

    return (
        <>
            <Card>
                <CardContent>
                    <div className="page-content m-2">
                        <div
                            className="row"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="col">
                                <div
                                    className="dash-card"
                                    style={menuElementContainer}
                                    onClick={showModal}
                                >
                                    {!hover ? (
                                        <>
                                            <h2 style={headerFontStyle}>
                                                <FontAwesomeIcon
                                                    icon={faFileExport}
                                                    inverse
                                                    className="mr-4"
                                                />
                                                Dodaj dokument
                                            </h2>
                                            <span style={fontStyle}>
                                                Dotyczy punktów realizacji
                                                posiadających umowy współpracy w
                                                zakresie środków pomocniczych z
                                                TZMO
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                style={headerFontStyle}
                                                onMouseOver={() =>
                                                    setHover(true)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileExport}
                                                    inverse
                                                    className="mr-4"
                                                />
                                                Przekaż
                                            </span>
                                            <span style={fontStyle}>
                                                Dotyczy punktów realizacji
                                                posiadających umowy współpracy w
                                                zakresie środków pomocniczych z
                                                TZMO
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <List
                            basePath="/documents"
                            resource="refund_documents"
                            bulkActionButtons={false}
                            actions={false}
                            empty={<EmptyList title="dokumentów" add={false} />}
                            title={`Dokumenty rozliczeniowe`}
                            filters={[
                                <DateInput
                                    label="Data po"
                                    source="dateUploaded[after]"
                                    alwaysOn
                                    variant="outlined"
                                    resettable
                                />,
                                <DateInput
                                    label="Data przed"
                                    source="dateUploaded[before]"
                                    alwaysOn
                                    variant="outlined"
                                    resettable
                                />,
                                <SelectInput
                                    label="Typ dokumentu"
                                    source="type"
                                    choices={[
                                        {
                                            id: 2,
                                            name: 'Zestawienie wydanych środków pomocniczych',
                                        },
                                        { id: 3, name: 'Nota księgowa' },
                                        {
                                            id: 4,
                                            name: 'Zestawienie + nota księgowa',
                                        },
                                    ]}
                                    alwaysOn
                                    variant="outlined"
                                    resettable
                                />,
                            ]}
                        >
                            <CustomDataGrid>
                                <DateField
                                    source="dateUploaded"
                                    showTime
                                    locales="pl-PL"
                                    label="Data wysyłki"
                                />
                                <DateField
                                     source="refundRange"
                                     options={{
                                        year: 'numeric',
                                        month: 'long',
                                    }}
                                     locales="pl-PL"
                                     label="Okres"
                                />
                                <FunctionField
                                    render={(record) => getDocType(record.type)}
                                    label="Typ dokumentu"
                                />
                                <FunctionField
                                    render={(record) => (
                                        <span
                                            style={{
                                                display: 'block',
                                                textAlign: 'initial',
                                            }}
                                        >
                                            {record.filename}
                                        </span>
                                    )}
                                    label="Nazwa pliku"
                                />
                                <ReferenceField
                                    reference="pickup_points"
                                    source="pickupPoint"
                                    link={false}
                                    label="Punkt odbioru"
                                >
                                    <TextField
                                        source="internalName"
                                        label="Punkt odbioru"
                                    />
                                </ReferenceField>
                            </CustomDataGrid>
                        </List>
                    </div>
                </CardContent>
            </Card>
            <UploadDocument
                isOpen={show}
                onClose={() => {
                    hideModal()
                }}
            />
        </>
    )
}

export default Documents
