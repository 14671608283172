import * as React from 'react'
import {
    List,
    TextField,
    usePermissions,
    FunctionField,
    ImageField,
} from 'react-admin'

import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'
import PermissionHandler from '../../config/PermissionHandler'

export default (props) => {
    console.log(props.contentUrl)
    const { isLoading, permissions } = usePermissions()
    let dataMedia = props.toString(props)
    console.log(dataMedia)
    return (
        <List title={'Obiekty multimedialne'}>
            {/* <EmptyList title="media_objects" /> */}
            <CustomActionBar
                isAddVisible={PermissionHandler(
                    permissions,
                    'MediaObjects',
                    'Create'
                )}
            />
            <CustomDataGrid>
                <FunctionField
                    source="ID"
                    render={(record) => `${record.id.split('/').pop()}`}
                />
                <TextField source="filePath" label="Nazwa" />
                <ImageField source="contentUrl" label="Zdjęcia" />
                <CustomActionsButtons
                    isDeleteAvailable={PermissionHandler(
                        permissions,
                        'MediaObjects',
                        'Delete'
                    )}
                />
            </CustomDataGrid>
        </List>
    )
}
