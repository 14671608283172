export const emailTemplates = [
    { name: 'Porzucony koszyk', id: 'abandoned_cart' },
    { name: 'Aktywne konto', id: 'activated_user' },
    { name: 'Zamknięte zamówienie', id: 'closed_order' },
    { name: 'Auto odpowiedź na kontakt', id: 'contact_auto' },
    { name: 'Nowy użytkownik', id: 'new_user' },
    { name: 'Reset hasła', id: 'reset_password' },
    { name: 'Nowe zamówienie', id: 'new_order' },
    { name: 'Nowy dokument rozliczeniowy', id: 'new_refund_file' },
]
