import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

export default ({ label, optionLabel, callback, value, setValue }) => {
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    let debounce

    React.useEffect(() => {
        callback(null)?.then((r) => {
            if (!r.errors) setOptions(r)
        })
    }, [])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e, v) => {
        setValue(v)
        if (v && v?.length >= 3) {
            clearTimeout(debounce)
            setLoading(true)
            setOptions([])
            debounce = setTimeout(() => {
                callback(v)
                    ?.then((r) => {
                        if (!r.errors) setOptions(r)
                    })
                    .finally(() => setLoading(false))
            }, 1000)
        }
    }

    return (
        <Autocomplete
            sx={{ width: 300 }}
            freeSolo
            disableClearable
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option?.[optionLabel] === value?.[optionLabel]}
            getOptionLabel={(option) => option?.[optionLabel] || ''}
            filterOptions={(x) => x}
            options={options}
            loading={loading}
            inputValue={value}
            onInputChange={(e, v, r) => handleChange(e, v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        },
                    }}
                />
            )}
        />
    )
}
