import React, { useEffect, useState } from 'react'
import {
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    email,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    FormDataConsumer,
    usePermissions,
    useGetOne,
    useGetList,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
import { useSnackbar } from 'notistack'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

const transform = (data) => {
    delete data.email_confirm
    return {
        //transform type from string to int
        ...data,
        //status: JSON.parse(data.status.toLowerCase()),
        type: parseInt(data.type),
        status: true,
    }
}

const isSameEmail = (value, allValues) => {
    if (allValues.email != value) {
        return 'Adresy e-mail się różnią'
    }

    return null
}

const validateSameEmail = [required('Pole wymagane'), isSameEmail]

export default (props) => {
    const { data: userData } = useGetOne('pk_users', { id: `pk_users/me` })
    const [isAnyWorker, setIsAnyWorker] = useState(true);
    const {data, isLoading:isWorkersLoadning} = useGetList('workers')
    useEffect(()=>{
        setIsAnyWorker(!isWorkersLoadning ? data.length > 0 : true)
    })
    const { isLoading, permissions } = usePermissions()

    return (
        <Create
            {...props}
            actions={null}
            title={`Dodaj pracownika`}
            transform={transform}
        >
            <SimpleForm toolbar={<PostBottomToolbarAdd appendId={true} />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            source="name"
                            validate={requiredField}
                            fullWidth
                            label="Imię"
                        />

                        <TextInput
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                            label="Nazwisko"
                        />

                        <SelectInput
                            source="type"
                            variant="outlined"
                            validate={requiredField}
                            choices={[
                                { id: 10, name: 'pracownik', disabled: !isAnyWorker },
                                { id: 20, name: 'kierownik' },
                            ]}
                            fullWidth
                            label="Typ"
                            helperText = {!isAnyWorker? 'Nie ma żadnych pracowników. Dodaj kierownika':''}
                        />

                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.type && (
                                    <tr>
                                        <td colSpan={2}>
                                            {formData.type == 10
                                                ? 'Składanie zamówień do przypisanego punktu odbioru, a także historia składanych zamówień dla danego punktu'
                                                : 'Ma możliwość przeglądania zamówień dla wszystkich przypisanych do siebie punktów odbioru a także składać zamówienie do wybranego punktu. Odpowiada także za dodawanie i zarządzanie pracownikami pierwszego stołu'}
                                        </td>
                                    </tr>
                                )
                            }
                        </FormDataConsumer>

                        <ReferenceArrayInput
                            source="pickupPoints"
                            reference="pickup_points"
                            validate={requiredField}
                            fullWidth
                        >
                            <AutocompleteArrayInput
                                variant="outlined"
                                optionText="internalName"
                                fullWidth
                                label="Punkty odbioru"
                                filterToQuery={(searchText) => ({
                                    internalName: searchText,
                                })}
                                validate={requiredField}
                            />
                        </ReferenceArrayInput>

                        <TextInput
                            variant="outlined"
                            source="email"
                            validate={isEmail}
                            onPaste={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            fullWidth
                            label="Adres e-mail"
                        />

                        <TextInput
                            variant="outlined"
                            source="email_confirm"
                            validate={validateSameEmail}
                            onPaste={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            autoComplete="new-password"
                            label="Powtórz adres e-mail"
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="phone"
                            fullWidth
                            label="Numer tel."
                        />

                        {/* <BooleanInput
                            variant="outlined"
                            label="Status"
                            source="status"
                            fullWidth
                        />

                        <p>
                            Czy dany użytkownik będzie miał możliwość logowania
                        </p> */}
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
