import React from 'react'
import { useRecordContext, useDataProvider, useRefresh } from 'react-admin'
import {
    DataGrid,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    plPL,
} from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'
import fastOrderProvider from '../../../config/fastOrderProvider'
import { useSnackbar } from 'notistack'
import ezwmProvider from '../../../config/ezwmProvider'

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()

    const [isLoadingProductRemoval, setIsLoadingProductRemoval] =
        React.useState(false)

    const columns: GridColDef[] = [
        { field: 'imgUrl', headerName: 'Zdjęcie', flex: 2,
        renderCell: (params) => {
            return <img src={`${ezwmProvider.getImgUrl(params.row.prestaIdProduct, params.row.prestaIdProductAttribute ?? null, 'small')}`} />
        },
        },
        { field: 'sku', headerName: 'SKU', flex: 1 },
        { field: 'bloz', headerName: 'BLOZ7', flex: 1 },
        {
            field: 'name',
            headerName: 'Nazwa',
            flex: 3,
        },
        {
            field: 'add',
            headerName: 'Akcje',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => removeProduct(params.row?.["@id"])}
                    disabled={isLoadingProductRemoval}
                >
                    Usuń
                </Button>,
            ],
            flex: 1,
        },
    ]

    const removeProduct = (id) => {
        setIsLoadingProductRemoval(true)
        dataProvider
            .delete('patient_products', {
                id: id,
            })
            .then((r) => {
                enqueueSnackbar('Produkt został usunięty', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            })
            .catch((e) =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => {
                setIsLoadingProductRemoval(false)
                refresh()
            })
    }

    return (
        <Box className="mb-4">
            <Box
                style={{
                    width: '100%',
                    minHeight: '275px',
                    display: 'grid',
                }}
            >
                <DataGrid
                    rows={record?._patientProducts ?? []}
                    columns={columns}
                    getRowId={(row) => row?.id}
                    getRowHeight={() => 'auto'}
                    loading={!record}
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'rgba(0,0,0,.07)',
                        },
                        '& .odd': {
                            backgroundColor: 'rgba(0,0,0,.02)',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 == 0
                            ? 'even'
                            : 'odd'
                    }
                    slots={{
                        toolbar: () => (
                            <Box>
                                <GridToolbarContainer>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        width="100%"
                                        marginX="10px"
                                    >
                                        <Box>
                                            <GridToolbarDensitySelector />
                                            <GridToolbarColumnsButton />
                                        </Box>
                                    </Box>
                                </GridToolbarContainer>
                                <Divider sx={{ opacity: 1 }} />
                            </Box>
                        ),
                        loadingOverlay: () => (
                            <Box>
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </Box>
                        ),
                    }}
                    localeText={
                        plPL.components.MuiDataGrid.defaultProps.localeText
                    }
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                />
            </Box>
        </Box>
    )
}
