import React from 'react'
import { useStore, Title } from 'react-admin'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import fastOrderProvider from '../../config/fastOrderProvider'
import { useSnackbar } from 'notistack'
import Toolbar from './components/Toolbar'
import OrderLines from './components/OrderLines'
import NewTemplateModal from './components/NewTemplateModal'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import { useConfirm } from 'material-ui-confirm'

const fontStyle = {
    padding: '4% 2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
    minWidth: '200px',
    display: 'block',
}

const cardStyle = {
    padding: '2%',
}

export default () => {
    const confirm = useConfirm()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [orderItems, setOrderItems] = useStore('fastOrderCart', [])
    const [open, setOpen] = React.useState(false)
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const [openAlert, setOpenAlert] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertCallback, setAlertCallback] = React.useState(() => {})

    const submitFile = (e) => {
        const file = e.target.files[0]
        if(!file){
            return;
        }

        const shopId = localStorage.getItem('shopId');

        if(!shopId){
            enqueueSnackbar(`Brak przypisanego sklepu. Zaloguj się ponownie lub zmień użytkownika`, {
                variant: 'error',
                autoHideDuration: 10000,
            })
            return;
        }

        const formData = new FormData()
        formData.append('file', file)
        formData.append('id_shop',shopId);

        const importProcess = () =>
            fastOrderProvider.uploadOrderFile(formData).then((data) => {
                if (data?.length > 0) {
                    enqueueSnackbar('Import przebiegł pomyślnie', {
                        variant: 'success',
                        autoHideDuration: 5000,
                    })
                    setOrderItems(data)
                } else {
                    
                }
            }).catch(e=>{
                enqueueSnackbar(`Wystąpił błąd: ${e}`, {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            })

        if (orderItems.length > 0) {
            confirm({
                title: 'Uwaga!',
                description:
                    'Importowanie linii zamówienia z pliku spowoduje wyczyszczenie obecnego koszyku. Czy chcesz kontynuować?',
                confirmationText: 'Przejdź dalej',
                confirmationButtonProps: {
                    variant: 'outlined',
                    color: 'success',
                },
                cancellationText: 'Anuluj',
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'primary',
                },
            })
                .then(() => {
                    importProcess()
                })
                .catch(() => {})
        } else {
            importProcess()
        }
    }

    const submitOrder = () => {
        confirm({
            title: 'Uwaga!',
            description:
                'Przejście do realizacji zamówienia, spowoduje wyczyszczenie aktualnego widoku. Czy chcesz kontynuować?',
            confirmationText: 'Przejdź dalej',
            confirmationButtonProps: { variant: 'outlined', color: 'success' },
            cancellationText: 'Anuluj',
            cancellationButtonProps: { variant: 'contained', color: 'primary' },
        })
            .then(() => {
                const skus = orderItems
                    .map((el) => el.reference + '|' + el.quantity)
                    .join(',')

                setOrderItems([])
                fastOrderProvider.redirectToPrestashopOrder(skus)
            })
            .catch(() => {})
    }

    return (
        <>
            <Card>
                <Title title="Szybkie zamówienie" />
                <CardContent>
                    <Box className="d-flex justify-content-around">
                        <Box
                            className="dash-card"
                            style={cardStyle}
                            component="label"
                        >
                            <span style={fontStyle}>
                                Importuj z Kamsoft<br /> Plik importowy: csv/xlsx <br/>
                            </span>
                            <input
                                className="d-none"
                                type="file"
                                accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={submitFile}
                            />
                        </Box>
                        {/* <Box className="dash-card" style={cardStyle} component="a">
                        <span style={fontStyle}>Pobierz plik importowy</span>
                    </Box> */}
                        <Box
                            className="dash-card"
                            style={cardStyle}
                            href="#/fast-order/templates"
                            component="a"
                        >
                            <span style={fontStyle}>Zamówienie z szablonu</span>
                        </Box>
                    </Box>

                    <Toolbar forceUpdate={forceUpdate} />
                    <OrderLines />
                    <NewTemplateModal
                        isOpen={open}
                        setIsOpen={setOpen}
                        products={orderItems}
                    />

                    <Box className="d-flex justify-content-end mt-4">
                        <Button
                            disabled={!orderItems.length}
                            onClick={() => setOpen(true)}
                            sx={{ marginRight: '20px' }}
                            variant="contained"
                            color="warning"
                            startIcon={<DynamicFormIcon />}
                        >
                            Zapisz jako szablon
                        </Button>
                        <Button
                            disabled={!orderItems.length}
                            onClick={submitOrder}
                            variant="contained"
                            color="success"
                        >
                            Przejdź do realizacji zamówienia
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}
