async function uploadDocument(formData) {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/refundation/upload-file`,
        {
            method: 'POST',
            headers: headers,
            body: formData,
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            switch (response.status) {
                case 409:
                    throw new Error(
                        'nieprawidłowy typ pliku (dostępne: pdf, jpg, jpeg, png)'
                    )
                default:
                    throw new Error(response.status)
            }
        } else return response
    })
}

export default {
    uploadDocument,
}
