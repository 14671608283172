import React from 'react'
import { useStore } from 'react-admin'
import {
    DataGrid,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    plPL,
} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import fastOrderProvider from '../../../config/fastOrderProvider'
import { useSnackbar } from 'notistack'
import EditToOrder from './EditToOrder'

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [orderItems, setOrderItems] = useStore('fastOrderCart', [])
    // const [searchedProducts, setSearchedProducts] = React.useState([])
    // const [searchLoading, setSearchLoading] = React.useState(false)
    // const [bloz7, setBloz7] = React.useState()
    // const [ean, setEan] = React.useState()
    // const [productName, setProductName] = React.useState()

    const columns: GridColDef[] = [
        { field: 'bloz', headerName: 'BLOZ7', flex: 1 },
        { field: 'ean', headerName: 'EAN', flex: 1 },
        {
            field: 'name',
            headerName: 'Nazwa',
            valueGetter: (params) => {
                return `${params.value || ''} ${
                    params?.row?.attributes
                        ?.map((opt) => `${opt.attribute_name} - ${opt.name}`)
                        ?.join(', ') || ''
                }`
            },
            flex: 2,
        },
        {
            field: 'edit',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <EditToOrder row={params?.row} />,
            ],
            flex: 1,
        },
    ]

    return (
        <Box>
            <Box
                style={{
                    width: '100%',
                    minHeight: '275px',
                    display: 'grid',
                }}
            >
                <DataGrid
                    rows={orderItems}
                    columns={columns}
                    getRowId={(row) => row?.id}
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'rgba(0,0,0,.07)',
                        },
                        '& .odd': {
                            backgroundColor: 'rgba(0,0,0,.02)',
                        },
                        
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 == 0
                            ? 'even'
                            : 'odd'
                    }
                    slots={{
                        toolbar: () => (
                            <Box>
                                <GridToolbarContainer>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        width="100%"
                                        marginX="10px"
                                    >
                                        <Typography variant="h5" marginY={1.5}>
                                            Linie zamówienia
                                        </Typography>
                                        <Box>
                                            <GridToolbarDensitySelector />
                                            <GridToolbarColumnsButton />
                                        </Box>
                                    </Box>
                                </GridToolbarContainer>
                                <Divider sx={{ opacity: 1 }} />
                            </Box>
                        ),
                    }}
                    localeText={
                        plPL.components.MuiDataGrid.defaultProps.localeText
                    }
                    hideFooter={true}
                />
            </Box>
        </Box>
    )
}
