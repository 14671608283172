import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from "react-admin";

import { PostShowActions } from "../CustomElements/PostBottomToolbars";
import PermissionHandler from "../../config/PermissionHandler";
import { CustomTitle } from "../CustomElements/CustomTitle";

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  const IsEditable = (props) => {
    return (
      props?.record?.category !== "seni-quarter" &&
      props?.record?.category !== "seni-edu" && <EditButton {...props} />
    );
  };

  return (
    <Show
      {...props}
      actions={<PostShowActions />}
      title={
        <CustomTitle
          resourceName="Płatnik użytkonwik"
          fields={["name", "surname"]}
        />
      }
    >
      <SimpleShowLayout>
        {/* <CustomTextField source="oracleId" txtLabel="Oracle ID" />
        <CustomTextField source="name" txtLabel="Numer klienta" />
        <CustomTextField source="nip" txtLabel="NIP" /> */}
        {PermissionHandler(permissions, "PayerDiscounts", "Update") && (
          <ReferenceManyField
            label="Rabaty"
            target="payer"
            reference="payer_discounts"
            filter={{ payer: props.id.split("/")[2] }}
          >
            <Datagrid>
              <TextField source="category" label="Marka" />
              <TextField source="value" label="Wartość [%]" />
              <DateField
                source="updatedAt"
                label="Aktualizowany"
                emptyText="brak danych"
              />
              <IsEditable />
            </Datagrid>
          </ReferenceManyField>
        )}
      </SimpleShowLayout>
    </Show>
  );
};
