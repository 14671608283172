import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  ReferenceInput,
  required,
  SimpleForm,
  email,
  TextInput,
  usePermissions,
} from "react-admin";
import { CustomTitle } from "../CustomElements/CustomTitle";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import PermissionHandler from "../../config/PermissionHandler";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  return (
    <Edit
      {...props}
      actions={null}
      title={
        <CustomTitle
          resourceName="Płatnik użytkonwik"
          fields={["name", "surname"]}
        />
      }
    >
      <SimpleForm
        toolbar={
          <PostBottomToolbarEdit
            isDeleteAvailable={PermissionHandler(
              permissions,
              "PayerUsers",
              "Delete"
            )}
          />
        }
      >
        <div className="d-flex w-100 justify-content-around">
          <div className="col-sm-5">
            
              
                
                    <TextInput
                      variant="outlined"
                      source="name"
                      label="Imię"
                      validate={requiredField}
                      fullWidth
                    />
                  
                
                    <TextInput
                      variant="outlined"
                      source="surname"
                      label="Nazwisko"
                      validate={requiredField}
                      fullWidth
                    />
                  

                
                    <TextInput
                      variant="outlined"
                      source="email"
                      label="E-mail"
                      validate={isEmail}
                      fullWidth
                    />
                  

                
                    <TextInput
                      variant="outlined"
                      source="phone"
                      label="Numer Telefonu"
                      fullWidth
                    />
                  
                
                    <ReferenceInput
                      source="partner"
                      reference="partners"
                      validate={requiredField}
                      fullWidth
                    >
                      <AutocompleteInput
                        variant="outlined"
                        optionText="name"
                        label="Partner"
                        filterToQuery={(searchText) => ({ name: searchText })}
                        />
                    </ReferenceInput>
                  
                
                    <BooleanInput
                      variant="outlined"
                      source="status"
                      label="Status"
                    />
                  
              
            </div>
          </div>
        </SimpleForm>
    </Edit>
  );
};
