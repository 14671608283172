import * as React from "react";
import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  usePermissions,
  NumberInput,
  useRecordContext,
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

import PermissionHandler from "../../config/PermissionHandler";

const requiredField = required("Pole wymagane");

export default (props) => {
  const { isLoading, permissions } = usePermissions();

  const CustomTitle = () => {
    const record = useRecordContext();
    return record ? `Próbka ${record?.name}` : null;
  };

  return (
    <Edit {...props} actions={null} title={<CustomTitle />}>
      <SimpleForm
        toolbar={
          <PostBottomToolbarEdit
            {...props}
            saveRedirectPath="/samples"
            isDeleteAvailable={PermissionHandler(
              permissions,
              "Samples",
              "Delete"
            )}
          />
        }
      >
        <div className="d-flex w-100 justify-content-around">
          <div className="col-sm-5">
            
              
                
                    <TextInput
                      variant="outlined"
                      label={"Nazwa"}
                      source="name"
                      validate={requiredField}
                      fullWidth
                    />
                  
                
                    <TextInput
                      variant="outlined"
                      label={"SKU"}
                      source="sku"
                      validate={requiredField}
                      fullWidth
                    />
                  
                
                    <NumberInput
                      variant="outlined"
                      label={"Maksymanlna ilość do zamówienia"}
                      source="maxOrder"
                      type={"number"}
                      validate={requiredField}
                      fullWidth
                    />
                  
              
            </div>
          </div>
        </SimpleForm>
    </Edit>
  );
};
