import React from 'react'
import { useRecordContext, useDataProvider, useRefresh } from 'react-admin'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, plPL } from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'
import fastOrderProvider from '../../../config/fastOrderProvider'
import { useSnackbar } from 'notistack'
import ProductModal from '../../CustomElements/ProductModal'
import ezwmProvider from '../../../config/ezwmProvider'

export default () => {
    const record = useRecordContext()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [searchedProducts, setSearchedProducts] = React.useState([])
    const [searchLoading, setSearchLoading] = React.useState(false)
    const [bloz7, setBloz7] = React.useState('')
    const [ean, setEan] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [size, setSize] = React.useState('')
    const [productName, setProductName] = React.useState('')

    const [errorMsg, setErrorMsg] = React.useState('')
    const [isLoadingProductCreation, setIsLoadingProductCreation] = React.useState(false)

    const [openProductModal, setOpenProductModal] = React.useState(false)
    const [dataProductModal, setDataProductModal] = React.useState({})

    const columns: GridColDef[] = [
        {
            field: 'url',
            headerName: 'Zdjęcie',
            flex: 2,
            renderCell: (params) => {
                return <img src={`${ezwmProvider.getImgUrl(params.row.id?.split('-')[0], params.row.id?.split('-')?.[1] ?? null, 'small')}`} />
            },
        },
        { field: 'reference', headerName: 'SKU', flex: 1 },
        {
            field: 'name',
            headerName: 'Nazwa',
            valueGetter: (params) => {
                return `${params.value || ''} ${params?.row?.options?.map((opt) => `${opt.name} - ${opt.value}`)?.join(', ') || ''}`
            },
            flex: 3,
        },
        {
            field: 'add',
            type: 'actions',
            headerName: 'Akcje',
            getActions: (params: GridRowParams) => [
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() =>
                        addProduct(
                            params.row.reference,
                            `${params.row.name}, ${params.row.attributes?.map((attr) => attr.name)?.join(' ')}`,
                            params.row.bloz,
                            Number(params.row.id?.split('-')[0]),
                            Number(params.row.id?.split('-')?.[1]) ?? null
                        )
                    }
                    disabled={isLoadingProductCreation}
                >
                    Przypisz
                </Button>,
            ],
            flex: 1,
        },
    ]

    const addProduct = (reference, name, bloz, idProduct, idProductAttribute = null) => {
        setIsLoadingProductCreation(true)
        dataProvider
            .create('patient_products', {
                data: {
                    patient: record.id,
                    sku: reference,
                    name: name,
                    bloz: bloz,
                    prestaIdProduct: idProduct,
                    prestaIdProductAttribute: idProductAttribute,
                },
            })
            .then((r) => {
                enqueueSnackbar('Produkt został przypisany do pacjenta', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            })
            .catch((e) =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => {
                setIsLoadingProductCreation(false)
                refresh()
            })
    }

    const submitSearch = (e) => {
        const validateResult = validate()

        if (validateResult !== true) {
            setErrorMsg(validateResult)
            return
        } else {
            setErrorMsg('')
        }

        setSearchLoading(true)
        setSearchedProducts([])
        fastOrderProvider
            .searchProducts(bloz7, ean, productName, true, category, size)
            .then((data) => {
                if (Array.isArray(data)) {
                    setSearchedProducts(data)
                } else {
                    enqueueSnackbar('Wystąpił błąd', {
                        variant: 'error',
                        autoHideDuration: 5000,
                    })
                }
            })
            .catch((e) => {
                enqueueSnackbar(e?.message ?? 'Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => setSearchLoading(false))
    }

    const clearFilters = () => {
        setBloz7('')
        setEan('')
        setProductName('')
        setCategory('')
        setSize('')
    }

    const validate = () => {
        if (productName.length > 0 && productName.length < 3) {
            return 'Nazwa musi mieć co najmniej 3 znaki'
        }

        if (bloz7.length > 0 && (bloz7.length < 4 || bloz7.length > 7)) {
            return 'BLOZ musi mieć między 4 a 7 cyfr'
        }

        if (ean.length > 0 && (ean.length < 5 || ean.length > 13)) {
            return 'EAN musi mieć między 5 a 13 cyfr'
        }

        if (category.length > 0 && category.length < 5) {
            return 'Kategoria musi mieć minimum 5 znaków'
        }

        if (size.length > 0 && size.length < 2) {
            return 'Rozmiar musi mieć minimum 2 znaki'
        }

        return true
    }

    const onRowClick = (params, event, details) => {
        setDataProductModal(params.row)
        setOpenProductModal(true)
    }

    return (
        <Box className="mb-4">
            <ProductModal open={openProductModal} setOpen={setOpenProductModal} data={dataProductModal} />
            <Box className="d-flex justify-content-around  my-2">
                <TextField value={bloz7} onChange={(e) => setBloz7(e.target.value)} label="BLOZ7" variant="outlined" className="w-25" />
                <TextField value={ean} onChange={(e) => setEan(e.target.value)} label="EAN" variant="outlined" className="w-25" />
                <TextField
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    label="Nazwa produktu"
                    variant="outlined"
                    className="w-25"
                />
                <TextField value={category} onChange={(e) => setCategory(e.target.value)} label="Kategoria" variant="outlined" className="w-25" />
                <TextField value={size} onChange={(e) => setSize(e.target.value)} label="Rozmiar" variant="outlined" className="w-25" />
                <Tooltip title="Wyczyszcz filtry">
                    <IconButton
                        onClick={clearFilters}
                        size="small"
                        sx={{ width: '52px' }}
                        disabled={(!bloz7 && !ean && !productName && !category && !size) || searchLoading}
                        color="warning"
                    >
                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
                <Button
                    onClick={submitSearch}
                    disabled={(!bloz7 && !ean && !productName && !category && !size) || searchLoading}
                    size="small"
                    variant="contained"
                    className="mt-2 px-5 py-2 h-75"
                >
                    Szukaj
                </Button>
            </Box>
            {errorMsg && (
                <Alert className="mb-2" color="error" severity="error">
                    {errorMsg}
                </Alert>
            )}
            <Box
                style={{
                    width: '100%',
                    minHeight: '275px',
                    display: 'grid',
                }}
            >
                <DataGrid
                    rows={searchedProducts}
                    columns={columns}
                    getRowId={(row) => row?.id + row?.reference}
                    onRowClick={onRowClick}
                    getRowHeight={() => 'auto'}
                    disableColumnMenu
                    loading={searchLoading}
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'rgba(0,0,0,.07)',
                        },
                        '& .odd': {
                            backgroundColor: 'rgba(0,0,0,.02)',
                        },
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
                    slots={{
                        toolbar: () => (
                            <Box>
                                <GridToolbarContainer>
                                    <Box display="flex" justifyContent="space-between" width="100%" marginX="10px">
                                        <Typography variant="h5" marginY={1.5}>
                                            Wyniki wyszukiwania
                                        </Typography>
                                        <Box>
                                            <GridToolbarDensitySelector />
                                            <GridToolbarColumnsButton />
                                        </Box>
                                    </Box>
                                </GridToolbarContainer>
                                <Divider sx={{ opacity: 1 }} />
                            </Box>
                        ),
                        loadingOverlay: () => (
                            <Box>
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </Box>
                        ),
                    }}
                    localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                />
            </Box>
        </Box>
    )
}
