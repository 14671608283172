import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import React, { useEffect } from 'react'
import { Title, useDataProvider, useGetList, useRedirect, useGetOne } from 'react-admin'
import useState from 'react-usestateref'
import { useSnackbar } from 'notistack'

import Product from './Product'
import { Box, Button, CardActionArea, Typography, MenuItem, Tooltip, InputAdornment, IconButton, Switch, FormControlLabel, Link } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'
import Info from '@mui/icons-material/Info'
import { useConfirm } from 'material-ui-confirm'
import { SwitchTextTrack } from '../CustomInputs/SwitchTextTrack'

export default (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const redirect = useRedirect()
    const [productList, setProductList, productListRef] = useState([])
    const [ProductsInfo, setProductsInfo, ProductsInfoRef] = useState([])
    const [sending, setSending, sendingRef] = useState(false)
    const [isInvoice, setIsInvoice] = React.useState(false)
    const [isFirm, setIsFirm] = React.useState(false)

    const UprawnieniaSelect = [
        { key: 'IW', value: 1 },
        { key: 'IB', value: 2 },
        { key: 'OR', value: 3 },
        { key: '47.ZN', value: 4 },
        { key: '47.DN', value: 5 },
        { key: '47.ZDN', value: 6 },
    ]
    const IloscMiesRefNaZlecSelect = [
        { key: '1', value: 1 },
        { key: '2', value: 2 },
        { key: '3', value: 3 },
        { key: '4', value: 4 },
        { key: '5', value: 5 },
        { key: '6', value: 6 },
    ]

    const ageSelect = [
        { key: 'Osoba pełnoletnia', value: 1 },
        { key: 'Osoba niepełnoletnia', value: 2 },
    ]
    // const partnerAgreement = {
    //     'TZMO': 'umowatzmo.pdf',
    //     'SMP': '',
    //     'Citonet Szczecin': '',
    //     'Citonet Łódź': '',
    //     'Citonet Kraków': '',
    //     'Farmpol': '',
    // }

    const [productKey, setProductKey] = useState(0)
    const [sumaSzt, setSumaSzt, sumaSztRef] = useState(0)
    const [sumaWartosc, setSumaWartosc] = useState(0)
    const [IloscMiesWyk, setIloscMiesWyk, IloscMiesWykRef] = useState(0)
    const [IloscSztukNaMies, setIloscSztukNaMies, IloscSztukNaMiesRef] = useState(0)
    const [KwotaRef, setKwotaRef] = useState(0)
    const [Uprawenia, setUprawienia, UprawieniaRef] = useState(0)
    const [sztLimit, setSztLimit, sztLimitRef] = useState(90)
    const [quantityLimit, setQuantityLimit, quantityLimitRef] = useState(0)
    const [age, setAge, ageRef] = useState(0)

    const [orderType, setOrderType, orderTypeRef] = useState(1)
    const dataProvider = useDataProvider()
    const { data: PickupPoints } = useGetList('pickup_points', {
        pagination: { page: 1, perPage: 999 },
        sort: { field: 'id', order: 'DESC' },
    })
    // const { data:meData, } = useGetOne('pk_users', {
    //     id: `pk_users/me`,
    // })

    const getProducts = async () => {
        const fetchHeaders = () => ({
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        })

        const data = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/product_refunds?page=1&itemsPerPage=400`, {
            method: 'GET',
            headers: new Headers(fetchHeaders()),
        }).then((data) => {
            try {
                data = data.json()
                return data
            } catch (error) {
                return []
            }
        })
        setProductsInfo(data ? data['hydra:member'] && data['hydra:member'] : [])
    }

    const AddProduct = () => {
        const tmpArr = Object.assign([], productListRef.current)
        tmpArr.push({ keyProduct: productKey, count: 0, value: 0, valueOne: 0 })
        setProductList(tmpArr)

        setProductKey(productKey + 1)
    }

    const RemoveProduct = (key) => {
        if (productListRef.current?.length > 1 && productListRef.current?.filter(Boolean)?.length > 1) {
            let tmp = productListRef.current
            delete tmp[key]
            setProductList(tmp)
            return true
        }
        return false
    }

    const updateSum = (Key, Count, Value, RefundLimit, ValueOne, description = null) => {
        let tmp = productListRef.current.map((el) =>
            el.keyProduct == Key
                ? {
                      ...el,
                      count: Count,
                      value: Value,
                      refundLimit: RefundLimit,
                      valueOne: ValueOne,
                      description: description ?? el?.description,
                  }
                : el
        )

        setSumaSzt(
            tmp.length > 0
                ? tmp
                      .map((e) => {
                          return isNaN(e.count) || e.count == null || e.count == '' ? 0 : parseInt(e.count)
                      })
                      .reduce((a, b) => {
                          return parseInt(a + b)
                      })
                : null
        )

        setSumaWartosc(
            tmp.length > 0
                ? tmp
                      .map((e) => {
                          return isNaN(e.value) || e.value == null ? 0 : parseFloat(e.value)
                      })
                      .reduce((a, b) => {
                          return parseFloat(a + b)
                      })
                : null
        )

        setProductList(tmp)

        updateValues()
    }

    const updateValues = () => {
        let sumaRefundacji = 0
        let sumaRazem = 0
        let iloscProduktow = 0

        let iloscDostepnaNaMiesiac = IloscSztukNaMiesRef.current * IloscMiesWykRef.current

        let limit = 0.8

        if (ageRef.current == 2) {
            limit = 0.9
        }

        if ([1, 2, 3].includes(parseInt(UprawieniaRef.current))) limit = 1

        productListRef.current.map((prod, index) => {
            iloscProduktow += parseInt(prod?.count)
            if ((iloscProduktow <= iloscDostepnaNaMiesiac && iloscDostepnaNaMiesiac != 0) || orderTypeRef.current == 2) {
                if (prod?.valueOne >= prod?.refundLimit) {
                    sumaRefundacji += prod?.count * (prod?.refundLimit * limit)
                } else {
                    sumaRefundacji += prod?.count * (prod?.valueOne * limit)
                }
            } else {
                if (prod?.valueOne >= prod?.refundLimit) sumaRefundacji = iloscDostepnaNaMiesiac * (prod?.refundLimit * limit)
                else sumaRefundacji += iloscDostepnaNaMiesiac * (prod?.valueOne * limit)
            }
            sumaRazem += prod?.count * prod?.value
        })

        setKwotaRef(sumaRefundacji)

        let limitQuantity = getProductQuantityLimit()
        setQuantityLimit(limitQuantity)
    }

    useEffect(() => {
        if (localStorage.getItem('indOrds') != 'true') {
            enqueueSnackbar('Nie posiadasz dostępu', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            redirect('/')
        }

        getProducts()
        AddProduct()
    }, [])

    const getProductQuantityLimit = () => {
        return IloscSztukNaMiesRef.current * IloscMiesWykRef.current - sumaSztRef.current
    }

    const isQuantityLimit = () => {
        return sumaSztRef.current > 0 && getProductQuantityLimit() && orderTypeRef.current == 1 <= 0 ? true : false
    }

    const onSubmitOrder = (e) => {
        e.preventDefault()
        setSending(true)
        const productsOrder = productListRef.current.filter((p) => p.count > 0)

        if (productsOrder.length <= 0) {
            enqueueSnackbar('Brak wybranych produktów', {
                variant: 'warning',
                autoHideDuration: 5000,
            })
            setSending(false)
            return
        }

        const data = new FormData(e.target)
        const formObject = Object.fromEntries(data.entries())
        const postData = {
            ...formObject,
            orderLines: productsOrder,
            summary: {
                total: sumaWartosc,
                refund: orderTypeRef.current == 1 ? KwotaRef : 0,
                patientTotal: orderTypeRef.current == 1 ? sumaWartosc - KwotaRef : sumaWartosc,
            },
        }

        dataProvider
            .create('individual_orders', { data: postData })
            .then((resp) => {
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: (
                        <Box>
                            <Typography variant="h6" gutterBottom>Twoje zamówienie od id {resp?.data?.originId} zostało złożone i przesłane do BOK.</Typography>
                            <Typography variant="subtitle1" fontWeight='bold' gutterBottom>Dane Kupującego</Typography>
                            <Typography variant="body2">
                                {postData.name} {postData.surname}
                            </Typography>
                            <Typography variant="body2">
                                {postData.street} {postData.number},
                            </Typography>
                            <Typography variant="body2">
                                {postData.postCode} {postData.city}
                            </Typography>
                            {orderTypeRef.current == 2 && <Typography variant="body2">{postData.peselNonRefund}</Typography>}
                            <Typography variant="subtitle1" fontWeight='bold' gutterBottom>Produkty</Typography>
                            {postData.orderLines.map((prod) => (
                                <Typography variant="body2">
                                    {prod.description.name} {prod.description.size} - {parseFloat(prod.valueOne).toFixed(2)}zł x {prod.count}szt (
                                    {parseFloat(prod.value).toFixed(2)}zł)
                                </Typography>
                            ))}
                            <Typography variant="subtitle1" fontWeight='bold' gutterBottom>Podsumowanie</Typography>
                            {orderTypeRef.current == 1 && (
                                <>
                                    <Typography variant="body2">
                                        Kwota dopłaty pacjenta: {parseFloat(postData.summary.patientTotal).toFixed(2)}zł
                                    </Typography>
                                    <Typography variant="body2">Kwota refundacji: {parseFloat(postData.summary.refund).toFixed(2)}zł</Typography>
                                </>
                            )}
                            <Typography variant="body2">Razem: {parseFloat(postData.summary.total).toFixed(2)}zł</Typography>
                        </Box>
                    ),
                    confirmationText: 'Przejdź do listy zamówień',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    redirect('/individual_orders')
                })
            })
            .catch((resp) => {
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    const OrderType = ({ title, icon, type }) => {
        let style = { color: '#e18a3d', background: '#ffffff' }
        if (type == orderTypeRef.current) {
            style = { color: '#ffffff', background: '#e18a3d' }
        }
        return (
            <Card sx={{ width: 400, minHeight: 100, ...style }}>
                <CardActionArea sx={{ height: '100%' }} onClick={() => setOrderType(type)}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}
                    >
                        <FontAwesomeIcon icon={icon} size="3x" />
                        <Typography variant="h6" textAlign="center" display="flex" flexDirection="column">
                            {title}
                            <hr
                                style={{
                                    width: '40%',
                                    alignSelf: 'center',
                                    opacity: 1,
                                    height: '5px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    background: style.color,
                                }}
                            />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    const InputInfo = ({ title }) => {
        return (
            <InputAdornment position="end">
                <Tooltip title={title}>
                    <IconButton aria-label="Dane ze zlecenia" edge="end">
                        <Info />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        )
    }

    return (
        <Card>
            <Title title="Nowe zamówienie indywidualne" />
            <CardContent>
                <div className="d-flex justify-content-around mb-4">
                    <OrderType type={1} title="Zamówienie z dofinansowaniem NFZ" icon={faHandHoldingDollar} />
                    <OrderType type={2} title="Zamówienie bez dofinansowania" icon={faWallet} />
                </div>
                <hr className="my-4"></hr>
                <div className="container-fluid">
                    {orderTypeRef.current == 1 && (
                        <>
                            <span className="big-text mb-2">Dane zlecenia</span>
                            <div className="mt-2">
                                <div className="form-group row align-items-center mb-4">
                                    <div className="col-sm-3">Wiek</div>
                                    <div className="col-sm-5">
                                        <Select
                                            defaultValue={0}
                                            onChange={(e) => {
                                                setAge(e.target.value)
                                                updateValues()
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>Wybierz opcję</MenuItem>
                                            {ageSelect?.map((x) => (
                                                <MenuItem value={x.value}>{x.key}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-4">
                                    <div className="col-sm-3">Uprawnienia</div>
                                    <div className="col-sm-5">
                                        <Select
                                            defaultValue={0}
                                            onChange={(e) => {
                                                setUprawienia(e.target.value)
                                                if (!UprawieniaRef.current || [0, 1, 2, 3].includes(parseInt(UprawieniaRef.current))) setSztLimit(90)
                                                else if ([4, 5, 6].includes(parseInt(UprawieniaRef.current))) setSztLimit(999999999)
                                                updateValues()
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>Wybierz, jeśli dotyczy</MenuItem>
                                            {UprawnieniaSelect?.map((x) => (
                                                <MenuItem value={x.value}>{x.key}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-4">
                                    <div className="col-sm-3">Ilość sztuk na miesiąc</div>
                                    <div className="col-sm-5">
                                        <TextField
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                            }}
                                            variant="outlined"
                                            type="number"
                                            onChange={(e) => {
                                                if (e.target.value <= sztLimitRef.current && e.target.value >= 0) setIloscSztukNaMies(e.target.value)
                                                else if (e.target.value > sztLimitRef.current) setIloscSztukNaMies(sztLimitRef.current)
                                                else if (e.target.value < 0) setIloscSztukNaMies(0)

                                                updateValues()
                                            }}
                                            value={IloscSztukNaMies}
                                            min={0}
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-4">
                                    <div className="col-sm-3">Na ile miesięcy chcesz zamówić?</div>
                                    <div className="col-sm-5">
                                        <Select
                                            defaultValue={0}
                                            className="browser-default custom-select"
                                            onChange={(e) => {
                                                setIloscMiesWyk(e.target.value)
                                                updateValues()
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>Wybierz opcję</MenuItem>
                                            {IloscMiesRefNaZlecSelect?.map((x) => (
                                                <MenuItem value={x.value}>{x.key}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center my-5">
                                <div className="col-md-3 offset-md-3">
                                    Dostępnych <span style={{ fontWeight: 'bold' }}>{IloscSztukNaMies * IloscMiesWyk}</span> sztuk do zamówienia
                                </div>
                            </div>

                            <div className="h-25 d-inline-block"></div>
                        </>
                    )}
                    {productListRef.current?.map((x, index) => {
                        return (
                            <Product
                                keyProduct={index}
                                productId={x?.keyProduct}
                                ProductsInfo={ProductsInfo}
                                RemoveFunc={RemoveProduct}
                                UpdateSum={updateSum}
                                quantityLimit={quantityLimitRef.current}
                                age={ageRef.current}
                                orderType={orderTypeRef.current}
                            />
                        )
                    })}
                    <div className="row">
                        <div className="col-sm mt-2">
                            <button type="button" className="btn btn-success" onClick={AddProduct}>
                                Dodaj produkt
                            </button>
                        </div>
                        {isQuantityLimit() && (
                            <div className="col-sm mt-4">
                                <div class="alert alert-warning" role="alert">
                                    Osiągnięto limit ilościowy
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="h-25 d-inline-block"></div>
                    <hr></hr>
                    <div className="d-flex w-100 justify-content-between">
                        <div style={{ flexGrow: 3 }} className="pe-4">
                            <Box component="form" onSubmit={onSubmitOrder} className="row" style={{ maxWidth: 900 }}>
                                <span className="big-text mb-2">Miejsce złożenia zamówienia</span>
                                <div>
                                    <Select required name="pickupPointId" sx={{ width: 600 }}>
                                        {PickupPoints?.map((pp) => (
                                            <MenuItem value={pp._id}>{pp.internalName}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <span className="big-text mb-2">Dane klienta</span>
                                <div style={{ maxWidth: 350 }} className="col">
                                    <div className="d-flex flex-column">
                                        <TextField variant="outlined" label="Imię" name="name" required />
                                        <TextField variant="outlined" label="Nazwisko" name="surname" required />
                                        {orderTypeRef.current == 2 && <TextField variant="outlined" label="PESEL" required name="peselNonRefund" />}
                                        <TextField variant="outlined" required label="Ulica" name="street" />
                                        <TextField variant="outlined" label="Numer domu/mieszkania" required name="number" />
                                        <TextField variant="outlined" label="Kod pocztowy" required name="postCode" />
                                        <TextField variant="outlined" label="Miejscowość" required name="city" />
                                        <TextField variant="outlined" label="Numer telefonu" required name="phone" />
                                        <TextField variant="outlined" label="Adres e-mail" name="email" />
                                    </div>
                                </div>
                                {orderTypeRef.current == 1 && (
                                    <>
                                        <div style={{ maxWidth: 350 }} className="col">
                                            <div className="d-flex flex-column">
                                                <TextField
                                                    variant="outlined"
                                                    label="PESEL"
                                                    required
                                                    name="pesel"
                                                    InputProps={{
                                                        endAdornment: <InputInfo title="Dane ze zlecenia" />,
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label="Pin / numer zlecenia"
                                                    required
                                                    name="refundNumber"
                                                    InputProps={{
                                                        endAdornment: <InputInfo title="Dane ze zlecenia" />,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ maxWidth: 350 }} className="col">
                                            <div className="d-flex flex-column">
                                                <TextField variant="outlined" label="Imię osoby odbierającej" required name="receiverName" />
                                                <TextField variant="outlined" label="Nazwisko osoby odbierającej" required name="receiverSurname" />
                                                <TextField variant="outlined" label="PESEL osoby odbierającej" required name="receiverPesel" />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row">
                                    <FormControlLabel
                                        control={<Switch checked={isInvoice} onChange={(e) => setIsInvoice(e.target.checked)} />}
                                        label="Faktura"
                                    />
                                </div>
                                {isInvoice && (
                                    <div className="row">
                                        <span className="big-text mb-2">Dane do faktury</span>
                                        <div style={{ maxWidth: 350 }} className="d-flex flex-column">
                                            <SwitchTextTrack
                                                checked={isFirm}
                                                onChange={(e) => setIsFirm(e.target.checked)}
                                                falseLabel="Osoba prywatna"
                                                trueLabel="Firma"
                                            />
                                            {isFirm ? (
                                                <>
                                                    <TextField variant="outlined" label="Nazwa firmy" name="nameFirmInvoice" required />
                                                    <TextField variant="outlined" label="NIP" name="nipInvoice" required />
                                                </>
                                            ) : (
                                                <>
                                                    <TextField variant="outlined" label="Imię" name="nameInvoice" required />
                                                    <TextField variant="outlined" label="Nazwisko" name="surnameInvoice" required />
                                                </>
                                            )}
                                            <TextField variant="outlined" label="Ulica" name="streetInvoice" required />
                                            <TextField variant="outlined" label="Numer domu/mieszkania" required name="numberInvoice" />
                                            <TextField variant="outlined" label="Kod pocztowy" required name="postCodeInvoice" />
                                            <TextField variant="outlined" label="Miejscowość" required name="cityInvoice" />
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-end">
                                    <Button
                                        size="large"
                                        sx={{ width: 300, height: 50 }}
                                        variant="contained"
                                        color="success"
                                        type="submit"
                                        disabled={sendingRef.current}
                                    >
                                        Złóż Zamówienie
                                    </Button>
                                </div>
                            </Box>
                        </div>
                        <div style={{ flexGrow: 0.5 }} className="d-flex flex-wrap flex-column">
                            <div className="d-flex w-100">
                                <div>
                                    <div className="row">
                                        <div className="big-text col-12">Suma</div>
                                        <div className="col-6">
                                            <span className="ml-1 d-inline">szt.</span>
                                            <input
                                                type="text"
                                                className="form-control-plaintext d-inline text-center auto-w"
                                                readonly
                                                value={sumaSzt}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="text"
                                                className="form-control-plaintext"
                                                readonly
                                                value={parseFloat(sumaWartosc).toFixed(2) + ' zł'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {orderTypeRef.current == 1 && (
                                <div
                                    className="sumup jumbotron col-12  mt-4 col-lg-6 w-100"
                                    style={{
                                        padding: '4em 2em',
                                        marginBottom: '2em',
                                        borderRadius: '4.8px',
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-12 col-lg-6">Kwota dopłaty pacjenta</div>
                                        <div className="col-12 col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control-plaintext text-lg-right"
                                                readonly
                                                value={
                                                    isFinite(KwotaRef)
                                                        ? parseFloat(sumaWartosc - KwotaRef < 0 ? 0 : sumaWartosc - KwotaRef).toFixed(2) + ' zł'
                                                        : (0).toFixed(2) + ' zł'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">Kwota refundacji</div>
                                        <div className="col-12 col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control-plaintext text-lg-right"
                                                readonly
                                                value={isFinite(KwotaRef) ? parseFloat(KwotaRef).toFixed(2) + ' zł' : (0).toFixed(2) + ' zł'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">Razem</div>
                                        <div className="col-12 col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control-plaintext text-lg-right"
                                                readonly
                                                value={parseFloat(sumaWartosc).toFixed(2) + ' zł'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* {meData ?  */}
                            <Button
                                variant="outlined"
                                component={Link}
                                size="large"
                                sx={{ width: 300, height: 50, marginX: 'auto' }}
                                color="warning"
                                // href={window.location.protocol + "/files/" + partnerAgreement[meData.partnerName ?? 'TZMO']}
                                href={window.location.protocol + '/files/UMOWA_ZAMOWIENIA_INDYWIDUALNE.pdf'}
                                target="_blank"
                            >
                                Umowa dla klienta (.pdf)
                            </Button>
                            {/* : <></> } */}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
