import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import { Card } from '@mui/material'

import { Pagination } from 'swiper/modules'
import Chart from '../Chart/Chart'

const fontStyle = {
    padding: '3%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

export default (props) => {
    const [isFetched, setIsFetched] = useState(false)
    const [dataChart, setDataChart] = useState()
    const [isLoading, setLoading] = useState()
    const [isError, setError] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_chart_statistic`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => response.json())
            .then((json) => setDataChart(json?.[0]))
            .catch((e) => setError(e))
            .finally(() => setLoading(false))
    }, [isFetched])
    if (data != undefined && isFetched == false) setIsFetched(true)

    const stat30DaysAmount = dataChart?.orders30Days[0]?.orderAmount
    const stat30DaysPrice = dataChart?.orders30Days[0]?.orderPrice
    const lastOrder = dataChart?.lastOrderPrice
    const lastOrderDate = dataChart?.lastOrderDate
    const lastLogin = dataChart?.lastLogin
    return (
        <Card>
            <>
                <Swiper
                    direction={'vertical'}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    <SwiperSlide className="swiper-slide">
                        <Chart />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <div>
                            <div className="content-slide">
                                <h3>
                                    Liczba zamówień za ostatnie 30 dni:
                                    <span> {stat30DaysAmount}</span>
                                </h3>
                            </div>
                            <div className="content-slide">
                                {' '}
                                <br />
                                <h3 className="slide-heading">
                                    TOTAL Wartość zamówień za ostatnie 30 dni: <span className="slide-span"> {stat30DaysPrice} zl</span>
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <div style={fontStyle}>
                            <h3>
                                Data ostatniego logowania: <span> {lastLogin}</span>
                            </h3>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <div className="content-slide">
                            <h3>
                                Data ostatniego zamówienia:
                                <span>{lastOrderDate}</span>
                            </h3>
                        </div>
                        <div className="content-slide">
                            <h3 className="slide-heading">
                                Wartość ostatniego zamówienia:
                                <span className="slide-span"> {lastOrder} zl </span>
                            </h3>
                            zl
                        </div>
                    </SwiperSlide>
                    ...
                </Swiper>
            </>
        </Card>
    )
}
