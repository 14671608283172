import * as React from 'react'
import {
    Create,
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import PermissionHandler from '../../config/PermissionHandler'
import { emailTemplates } from '../../config/emailTemplates'
import TinyMCEInput from '../CustomInputs/TinyMCEInput'

const requiredField = required('Pole wymagane')

export default (props) => {
    return (
        <Create {...props} actions={null} title={`Dodaj nowy szablon`}>
            <SimpleForm
                toolbar={<PostBottomToolbarEdit isDeleteAvailable={false} />}
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <SelectInput
                            source="name"
                            variant="outlined"
                            validate={requiredField}
                            choices={emailTemplates}
                            fullWidth
                            label="Nazwa szablonu"
                        />

                        <TextInput
                            source="subject"
                            validate={requiredField}
                            label="Temat"
                            variant="outlined"
                            fullWidth
                        />

                        <TinyMCEInput source="body" />

                        <ReferenceInput
                            source="partner"
                            reference="partners"
                            fullWidth
                            allowEmpty
                        >
                            <AutocompleteInput
                                variant="outlined"
                                label="Partner"
                                optionText={'name'}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                            />
                        </ReferenceInput>
                        <span>
                            Zostaw puste jeśli tworzysz szablon domyślny /
                            wspólny
                        </span>
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
