import React, { useState } from 'react'
import { useGetOne, useGetList } from 'react-admin'
import refundationProvider from '../../config/refundationProvider'
import { useSnackbar } from 'notistack'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

const UploadDocument = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [file, setFile] = useState(null)
    const [selectedOptions, setSelectedOption] = useState()
    const [selectedPickupPoint, setSelectedPickupPoint] = useState()
    const [range, setRange] = useState(dayjs())
    const [isError, setIsError] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)

    const { data, loading, error } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })

    const {
        data: PickUpPointsData,
        loading: PickUpPointsLoading,
        error: PickUpPointsError,
    } = useGetList('pickup_points', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    })

    const handleClose = () => {
        setFile(null)
        setIsError(false)
        setSelectedPickupPoint(-1)
        setSelectedOption(-1)
        setRange(dayjs())
        props.onClose()
    }

    const saveHandler = () => {
        if (!file) {
            setIsError('Brak załączonego pliku')
            return
        }
        if (!selectedOptions || selectedOptions == -1) {
            setIsError('Proszę zaznaczyć rodzaj pliku')
            return
        }
        if (!selectedPickupPoint || selectedPickupPoint == -1) {
            setIsError('Proszę zaznaczyć jedną z aptek')
            return
        }
        if (!range) {
            setIsError('Proszę zaznaczyć okres')
            return
        }

        if(![".doc",".docx",".pdf",".jpg",".jpeg",".png"].some(e=>file?.name?.endsWith(e))){
            setIsError('Rozszerzenie pliku jest nieprawidłowe. Akceptujemy pliki z końcówkami: .doc, .docx, .pdf, .jpg, .jpeg, .png')
            return
        }

        setLoadingFile(true)
        const formData = new FormData()

        let pp = PickUpPointsData.find((el) => el.id == selectedPickupPoint)

        formData.append('range', range?.format('YYYY-MM'))
        formData.append('pickupPointName', pp.name)
        formData.append('pickupPointPostCode', pp.postCode)
        formData.append('pickupPointStreet', pp.street)
        formData.append('pickupPointVoivodeship', pp.voivodeship.split('/')[2])
        formData.append('pickupPointId', pp.id.split('/')[2])
        formData.append('fileType', selectedOptions)
        formData.append('file', file)
        refundationProvider
            .uploadDocument(formData)
            .then((res) => {
                if (res.status === 201) {
                    setFile(null)
                    setIsError(false)
                    enqueueSnackbar('Dodanie pliku przebiegło pomyślnie', {
                        variant: 'success',
                        autoHideDuration: 5000,
                    })
                    handleClose()
                } else if (res.status >= 400) {
                    enqueueSnackbar('Wystąpił błąd', {
                        variant: 'error',
                        autoHideDuration: 5000,
                    })
                }
            })
            .catch((err) => {
                enqueueSnackbar('Wystąpił błąd: ' + err.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setLoadingFile(false)
            })
    }

    return (
        <div
            className="modal"
            style={{ display: props.isOpen ? 'block' : 'none' }}
        >
            <div
                className="bg-secondary"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    opacity: 0.7,
                }}
                onClick={loadingFile ? null : handleClose}
            />
            {data && PickUpPointsData && !loading && !PickUpPointsLoading ? (
                <>
                    <div className="modal-dialog" style={{ top: '10%' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Dodaj plik</h5>
                            </div>
                            <form className="modal-body">
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        startIcon={<CloudUploadIcon />}
                                        color="info"
                                    >
                                        Przeglądaj
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={(e) => {
                                                setFile(e.target.files[0])
                                            }}
                                            id="inputFile"
                                            accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
                                        />
                                    </Button>
                                    <label
                                        style={{
                                            wordBreak: 'break-all',
                                        }}
                                    >
                                        {!file ? 'Wybierz plik...' : file?.name}
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        onChange={(e) => {
                                            setSelectedOption(e.target.value)
                                        }}
                                        value={selectedOptions}
                                        style={{ width: '100%' }}
                                        defaultValue={-1}
                                    >
                                        <MenuItem selected value={-1}>
                                            - Proszę wybrać rodzaj pliku -
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            Zestawienie wydanych środków
                                            pomocniczych
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            Nota księgowa
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            Zestawienie + nota księgowa
                                        </MenuItem>
                                    </Select>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        onChange={(e) => {
                                            setSelectedPickupPoint(
                                                e.target.value
                                            )
                                        }}
                                        value={selectedPickupPoint}
                                        style={{ width: '100%' }}
                                        defaultValue={-1}
                                    >
                                        <MenuItem selected value={-1}>
                                            - Proszę wybrać aptekę -
                                        </MenuItem>
                                        {PickUpPointsData.map((pp) => {
                                            return (
                                                <MenuItem value={pp.id}>
                                                    {pp.internalName}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="mt-2">
                                    <DatePicker
                                        value={range}
                                        onChange={setRange}
                                        slotProps={{
                                            field: { clearable: true },
                                            textField: {
                                                variant: 'outlined',
                                                fullWidth: true,
                                                size: 'medium',
                                            },
                                        }}
                                        views={['year', 'month']}
                                        label="Okres"
                                        shouldDisableYear={(date) =>
                                            date.get('year') <
                                            dayjs().get('year') - 1
                                        }
                                        minDate={dayjs().subtract(1, 'year')}
                                        maxDate={dayjs()}
                                    />
                                </div>
                                {isError && (
                                    <p className="text-danger">{isError}</p>
                                )}
                            </form>
                            <div className="modal-footer">
                                <Button
                                    component="label"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                    disabled={loadingFile}
                                    type="button"
                                >
                                    Zamknij
                                </Button>

                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="success"
                                    onClick={saveHandler}
                                    disabled={loadingFile}
                                    sx={{marginLeft: '10px'}}
                                >
                                    {loadingFile && (
                                        <div
                                            className="spinner-border spinner-border-sm mr-2"
                                            role="status"
                                        />
                                    )}
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <h1>Ładowanie...</h1>
            )}
        </div>
    )
}

export default UploadDocument
