import * as React from 'react'
import {
    Edit,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    usePermissions,
    useRecordContext,
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')

const EditRenderer = (props) => {
    const record = useRecordContext()
    return (
        <SimpleForm
            {...props}
            toolbar={
                <PostBottomToolbarEdit
                    saveRedirectPath={`/payer_networks/${encodeURIComponent(
                        record.payerNetwork
                    )}/show`}
                    cancelRedirectPath={`/payer_networks/${encodeURIComponent(
                        record.payerNetwork
                    )}/show`}
                    isDeleteAvailable={false}
                />
            }
        >
            <p>Marka: <TextField source="category" /></p>
            
            <NumberInput
                source="value"
                label="Wartość [%]"
                min={0}
                max={60}
                step="0.1"
                validate={requiredField}
                variant="outlined"
            />
        </SimpleForm>
    )
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Edit
            {...props}
            actions={null}
            title={<CustomTitle resourceName="Rabat" fields={['name']} />}
        >
            <EditRenderer />
        </Edit>
    )
}
