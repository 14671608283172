import { Box, Card, CardContent, Divider, Modal, Typography } from '@mui/material'
import * as React from 'react'
import ezwmProvider from '../../config/ezwmProvider'

const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    position: 'absolute',
}

const featureBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '300px',
}

export default ({ open, setOpen, data }) => {
    return (
        Object.keys(data).length > 0 && (
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                        <Box sx={{ flex: 1 }}>
                            <img src={`${ezwmProvider.getImgUrl(data.id?.split('-')[0], data.id?.split('-')?.[1] ?? null, 'big')}`} />
                        </Box>
                        <Box sx={{ flex: 2 }}>
                            <Typography color='#240a51' variant="h4" gutterBottom>{data.name}</Typography>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography color='#240a51' variant="h5" gutterBottom>
                                        Cechy produktu
                                    </Typography>
                                    <Box>
                                        <Box sx={featureBoxStyle}>
                                            <Typography variant="subtitle2">Indeks</Typography>
                                            <Typography variant="h6">{data.reference}</Typography>
                                        </Box>
                                        <Box sx={featureBoxStyle}>
                                            <Typography variant="subtitle2">ean13</Typography>
                                            <Typography variant="h6">{data.ean}</Typography>
                                        </Box>
                                        <Box sx={featureBoxStyle}>
                                            <Typography variant="subtitle2">bloz7</Typography>
                                            <Typography variant="h6">{data.bloz}</Typography>
                                        </Box>
                                        <Divider sx={{ margin: '10px 0' }} />
                                        {Object.values(data.features).map((f) => (
                                            <>
                                                <Box sx={featureBoxStyle}>
                                                    <Typography variant="subtitle2">{f.name}</Typography>
                                                    <Typography sx={{ textAlign: 'end' }} variant="h6">
                                                        {f.values.map((v) => (
                                                            <>
                                                                {v}
                                                                <br />
                                                            </>
                                                        ))}
                                                    </Typography>
                                                </Box>
                                                <Divider sx={{ margin: '5px 0' }} />
                                            </>
                                        ))}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                    <Divider sx={{ marginBottom: '20px' }}>
                        <Typography color='#240a51' variant="h4" gutterBottom>
                            Opis produktu
                        </Typography>
                    </Divider>
                    <div className="product-modal-wysiwig" dangerouslySetInnerHTML={{ __html: data.description }} />
                </Box>
            </Modal>
        )
    )
}
