import { BarChart } from '@mui/x-charts/BarChart'
import React, { useEffect, useState } from 'react'

import { axisClasses } from '@mui/x-charts/ChartsAxis'

export default (props) => {
    const [isFetched, setIsFetched] = useState(false)
    const [dataChart, setDataChart] = useState()
    const [isLoading, setLoading] = useState()
    const [isError, setError] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        setLoading(true)
        fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_chart_statistic`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then((response) => response.json())
            .then((json) => setDataChart(json?.[0]))
            .catch((e) => setError(e))
            .finally(() => setLoading(false))
    }, [isFetched])
    if (data != undefined && isFetched == false) setIsFetched(true)

    let firstMonth = dataChart?.ordersChart?.[0]?.orderMonth
    let secondMonth = dataChart?.ordersChart?.[1]?.orderMonth
    let thirdMonth = dataChart?.ordersChart?.[2]?.orderMonth

    const monthNames = [
        'styczeń',
        'luty',
        'marzec',
        'kwiecień',
        'maj',
        'czerwiec',
        ' lipiec',
        'sierpień',
        'wrzesień',
        'październik',
        'listopad',
        'grudzień ',
    ]

    const convertMonthNumberToName = (monthNumber) => {
        return monthNames[monthNumber - 1]
    }

    let formattedDataset = [
        {
            price: dataChart?.ordersChart?.[0]?.orderPrice,
            month: convertMonthNumberToName(firstMonth),
        },
        {
            price: dataChart?.ordersChart?.[1]?.orderPrice,
            month: convertMonthNumberToName(secondMonth),
        },
        {
            price: dataChart?.ordersChart?.[2]?.orderPrice,
            month: convertMonthNumberToName(thirdMonth),
        },
    ]

    const valueFormatter = (value) => `${value}zł`
    const chartSetting = {
        yAxis: [
            {
                label: '',
            },
        ],
        series: [
            {
                dataKey: 'price',
                label: 'Wartość Twoich zamówień w TZMOonline',
                valueFormatter,
            },
        ],
        height: 300,
        width: 500,
        height: 300,
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        },
    }

    return (
        <div>
            <BarChart
                dataset={formattedDataset}
                xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: 'month',
                    },
                ]}
                {...chartSetting}
            />

            <div>{dataChart?.lastOrderDay}</div>
        </div>
    )
}
