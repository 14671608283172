import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  email,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

export default (props) => {
  const { isLoading, permissions } = usePermissions();
  return (
    <Create {...props} actions={null} title={`Dodaj płatnika użytkownika`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div className="col-sm-5">
            
              
                
                    <TextInput
                      variant="outlined"
                      source="name"
                      label="Imię"
                      validate={requiredField}
                      fullWidth
                    />
                  
                
                    <TextInput
                      variant="outlined"
                      source="surname"
                      label="Nazwisko"
                      validate={requiredField}
                      fullWidth
                    />
                  

                
                    <TextInput
                      variant="outlined"
                      source="email"
                      label="E-mail"
                      validate={isEmail}
                      fullWidth
                    />
                  

                
                    <TextInput
                      variant="outlined"
                      source="phone"
                      label="Numer Telefonu"
                      fullWidth
                    />
                  
                
                    <ReferenceInput
                      source="partner"
                      reference="partners"
                      validate={requiredField}
                      fullWidth
                    >
                      <AutocompleteInput
                        variant="outlined"
                        optionText="name"
                        label="Partner"
                        filterToQuery={(searchText) => ({ name: searchText })}
                        />
                    </ReferenceInput>
                  
                
                    <BooleanInput
                      variant="outlined"
                      source="status"
                      label="Status"
                    />
                  
              
     
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
