import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Error, Loading, usePermissions, useGetOne } from "react-admin";
import PermissionHandler from "../../config/PermissionHandler";
import authProvider from "../../config/authProvider";

export default (props) => {
  const [data, setData] = React.useState(null);

  const { isLoading, permissions } = usePermissions();

  useEffect(() => {
    if (localStorage.getItem("hideBell") != "true") {
      localStorage.setItem("hideBell", "true");
    }

    authProvider.getNotifications().then((notiData) => {
      setData(notiData);
    });
  }, []);

  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser,
  } = useGetOne("pk_users", { id: `pk_users/me` });

  if (!data || loadingUser) return <Loading />;
  if (errorUser) return <Error />;

  const getNotificationClass = (level) => {
    switch (level) {
      case "info":
        return "alert alert-primary";
      case "warning":
        return "alert alert-warning";
      case "danger":
        return "alert alert-danger";
      case "success":
        return "alert alert-success";
      case "primary":
        return "alert alert-primary";
    }
  };

  const alerts = data?.map((notification) => (
    <div className={getNotificationClass(notification.level)} role="alert">
      {notification.message}
    </div>
  ));

  PermissionHandler(permissions, "Dashboard", "CanBuy") &&
    alerts?.push(
      <div className="alert alert-primary" role="alert">
        NOWOŚĆ! Duplikuj swoje stare zamówienia. Odwiedź historię zamówień i
        dodawaj wcześniej zamawiane produkty do koszyka.
      </div>
    );

  if (dataUser?.isBlocked)
    alerts?.push(
      <div className="alert alert-danger" role="alert">
        Uwaga, posiadasz zaległe płatności. Obecnie składanie zamówień nie jest
        możliwe. Ureguluj zaznaczone dokumenty lub skontaktuj się z
        przedstawicielem TZMO
      </div>
    );

  return (
    <Card>
      <CardContent>
        <div className="page-content m-2">
          {alerts && alerts?.length > 0 ? alerts : "Brak komunikatów"}
        </div>
      </CardContent>
    </Card>
  );
};
