import React from 'react'
import { useState } from 'react'
import {useParams} from 'react-router-dom';

const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-beetween',
    padding: '20px 20px',
    margin: '20px 20px',
    width: '100em',
}
const inputBox = {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-around',
    padding: '20px 20px',
    margin: '20px 20px',
    width: '40em',
}

function FormLayout(props) {
    const tutorRaports = (e) => {
        e.preventDefault()
        let input1 = e.target[0].value
        let input2 = e.target[1].value

        console.log(input1, input2)
        window.open(
            `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/tutorials/${input1}/${input2}`
        )
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let input1 = e.target[0].value
        let input2 = e.target[1].value

        console.log(input1, input2)
        window.open(
            `${process.env.REACT_APP_API_ENTRYPOINT}/extended_report/log_to_shop?startDate=${input1}&endDate=${input2}`
        )
    }
    const formType = useParams()

    return (
        <div>
            {(() => {
                switch (formType["*"]) {
                    case '1':
                        return (
                            <form onSubmit={tutorRaports}>
                                <div class="card text-center" style={boxStyle}>
                                    <p>Za jaki okres czasu</p>
                                    <div style={inputBox}>
                                        <div class="input-group">
                                            <span class="input-group-text">
                                                Początek i koniec:
                                            </span>
                                            <input
                                                id="st"
                                                type="date"
                                                aria-label="First name"
                                                class="form-control"
                                            ></input>
                                            <input
                                                id="en"
                                                type="date"
                                                aria-label="Last name"
                                                class="form-control"
                                            ></input>
                                            <input
                                                type="submit"
                                                className="btn btn-success"
                                                value="Zapisz"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    case '2':
                        return (
                            <form onSubmit={handleSubmit}>
                                <div class="card text-center" style={boxStyle}>
                                    <p>Za jaki okres czasu</p>
                                    <div style={inputBox}>
                                        <div class="input-group">
                                            <span class="input-group-text">
                                                Początek i koniec:
                                            </span>
                                            <input
                                                id="st"
                                                type="date"
                                                aria-label="Start date"
                                                class="form-control"
                                            ></input>
                                            <input
                                                id="en"
                                                type="date"
                                                aria-label="End date"
                                                class="form-control"
                                            ></input>
                                            <input
                                                type="submit"
                                                className="btn btn-success"
                                                value="Zapisz"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    default:
                        return <h2>Strona nie znaleziona 404</h2>
                }
            })()}
        </div>
    )
}
export default FormLayout
